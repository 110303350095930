import * as React from "react";

const ExportIcon = (props: { color: string; size?: number }): JSX.Element => {
    const iconSize = props.size ?? 24;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconSize}
            height={iconSize}
            viewBox="0 0 24 24"
            style={{ marginRight: "4px" }}
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.25C12.4142 4.25 12.75 4.58579 12.75 5V13.5L15.55 11.4C15.8814 11.1515 16.3515 11.2186 16.6 11.55C16.8485 11.8814 16.7814 12.3515 16.45 12.6L12.45 15.6C12.1833 15.8 11.8167 15.8 11.55 15.6L7.55 12.6C7.21863 12.3515 7.15147 11.8814 7.4 11.55C7.64853 11.2186 8.11863 11.1515 8.45 11.4L11.25 13.5V5C11.25 4.58579 11.5858 4.25 12 4.25ZM4 14.25C4.41421 14.25 4.75 14.5858 4.75 15V18.25H19.25V15C19.25 14.5858 19.5858 14.25 20 14.25C20.4142 14.25 20.75 14.5858 20.75 15V19C20.75 19.4142 20.4142 19.75 20 19.75H4C3.58579 19.75 3.25 19.4142 3.25 19V15C3.25 14.5858 3.58579 14.25 4 14.25Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ExportIcon;
