import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import BmsEditConfigurationView from "./BmsEditConfigurationView";
import style from "components/licenses/license-configuration/license-configuration.scss";
import Modal from "components/modal/Modal";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ConfigureData } from "domain/licenses";
import { ConfigurationData, licenseService } from "services/licenses/LicenseService";
import { StoreState } from "store";
import {
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsLicenses,
    setHasBmsValidationLicenses,
    setHasLicenseConfiguration,
} from "store/license";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface Props {
    initialConfiguration: ConfigureData[];
    count: number;
}

interface TableState {
    configuration: ConfigurationData;
}

interface ActiveConfigurationViewState {
    uuid: string;
    created: string;
    modified: string;
}

const mapState = (state: StoreState) => ({
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
});
const connector = connect(mapState, {
    setHasBmsLicenses,
    setHasBmsValidationLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasLicenseConfiguration,
});

const ConfigureTable = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [count, setCount] = React.useState({ count: 0 });
    const [tenantEditConfigurationVisibility, setTenantEditConfigurationVisibility] = React.useState(false);
    const [activeTenantConfiguration, setActiveTenantConfiguration] =
        React.useState<ActiveConfigurationViewState | null>(null);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const onConfigurationEdit = () => {
        return setCount((prevState) => ({ count: prevState.count + 1 }));
    };

    const showClientConfiguration = (uuid: string, created: string, modified: string) => {
        setTenantEditConfigurationVisibility(true);
        setActiveTenantConfiguration({
            uuid,
            created,
            modified,
        });
    };

    const hideTenantEditConfiguration = () => {
        setTenantEditConfigurationVisibility(false);
        setActiveTenantConfiguration(null);
    };

    const editTenantConfiguration = (
        <Modal
            isOpen={tenantEditConfigurationVisibility}
            hideModal={hideTenantEditConfiguration}
            modalTitle={t("Configuration.editConfigureDialog.title")}
        >
            <BmsEditConfigurationView
                uuid={activeTenantConfiguration ? activeTenantConfiguration.uuid : ""}
                created={activeTenantConfiguration ? activeTenantConfiguration.created : ""}
                modified={activeTenantConfiguration ? activeTenantConfiguration.modified : ""}
                onClose={hideTenantEditConfiguration}
                onEdit={onConfigurationEdit}
                edit={true}
            />
        </Modal>
    );

    const columns: Array<Column<ConfigureData>> = [
        {
            Header: () => <TextWithTooltip text={t("Configuration.configureTable.uuid")} key="1" />,
            accessor: "uuid",
            Cell: (row) => (
                <>
                    <a
                        className={style.customerConfigurationUuidCell}
                        onClick={() =>
                            showClientConfiguration(
                                row.row.original.uuid,
                                row.row.original.created,
                                row.row.original.modified
                            )
                        }
                    >
                        <TextWithTooltip text={row.row.original.uuid} />
                    </a>
                </>
            ),
            width: deriveColumnWidth(35, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Configuration.configureTable.product")} key="2" />,
            accessor: "product",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={value ? value : t("Configuration.configureTable.bms")} />
            ),
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.creationDate")} key="3" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Configuration.configureTable.editedDate")} key="4" />,
            accessor: "modified",
            Cell: ({ cell: { value } }) =>
                value ? <DateCell tooltip={true} value={value} /> : <TextWithTooltip text={t("Common.notAvailable")} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
    ];
    const [configurationState, setConfigurationStateData] = React.useState<TableState>({
        configuration: { configuration: props.initialConfiguration },
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchConfigurationData(abortController.signal)
            .then((configuration) => {
                setConfigurationStateData({ configuration });
                props.setHasBmsLicenses(true);
                props.setHasLicenseConfiguration(configuration.configuration.length > 0);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("Configuration.configureTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };
    React.useEffect(() => {
        if (props.initialConfiguration.length > 0) {
            return;
        }
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, count]);

    let dataCount = null;
    if (configurationState.configuration.configuration.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", {
            dataCount: configurationState.configuration.configuration.length,
        });
    }

    return (
        <>
            <div className={style.paddingBottom}>{dataCount}</div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.CONFIGURATION_TABLE}
                    data={configurationState.configuration.configuration}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("Configuration.configureTable.emptyStateMessage")}
                    testId={testIds.workArea.license.bms.table.itself}
                />
            </div>
            {editTenantConfiguration}
        </>
    );
};

ConfigureTable.defaultProps = {
    initialConfiguration: [],
    requestFailureMessage: "",
};

export default connector(ConfigureTable);
