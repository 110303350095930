import { DEFAULT_LOGIN_METHOD, DefaultViews, UserLoginMethod, UserType } from "domain/user";
import {
    CheckEmailAvailability,
    SsoConfiguration,
    toUserByUuid,
    toUserData,
    UserByUuid,
    UserByUuidDto,
    UsersData,
    UsersResponseDto,
} from "domain/users";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { ParameterQuery, trimToNull } from "utils/commonFunctions";

interface EmailCheckDto {
    email_is_available: boolean;
    email_is_undeliverable?: boolean;
    domain_name?: string;
    error_message?: string;
}

interface SsoLoginDto {
    login_url: string;
    protocol: string;
    metadata_url: string;
    provider_name: string;
    client_id: string;
    client_secret: string;
    oidc_issuer: string;
    sso_url: string;
    entity_id: string;
}

interface UserByUuidResponse {
    user: UserByUuidDto;
}

function ToEmailCheck(dto: EmailCheckDto): CheckEmailAvailability {
    return {
        emailIsAvailable: dto.email_is_available,
        emailIsUndeliverable: dto.email_is_undeliverable,
        domainName: dto.domain_name,
        errorMessage: dto.error_message,
    };
}

function toSsoLogin(dto: SsoLoginDto): SsoConfiguration {
    return {
        loginUrl: dto.login_url,
        providerName: dto.provider_name,
        metadataUrl: dto.metadata_url,
        protocol: dto.protocol,
        clientId: dto.client_id,
        clientSecret: dto.client_secret,
        oidcIssuer: dto.oidc_issuer,
        ssoUrl: dto.sso_url,
        entityId: dto.entity_id,
    };
}

const PATH_API_USERS = "/api/users";
const PATH_API_USERS_V1 = "/api/v1/users";

class UserService {
    public async fetchAllUsers(abortController: AbortController, all: string): Promise<UsersData> {
        return this.fetchUsers(abortController, undefined, undefined, undefined, undefined, all);
    }

    public async fetchUsers(
        abortController: AbortController,
        search?: string,
        cursor?: string,
        group?: string,
        licensePool?: string,
        all?: string
    ): Promise<UsersData> {
        return await apiGatewayService
            .invokeApi(
                PATH_API_USERS +
                    new ParameterQuery()
                        .add("search", search)
                        .add("cursor", cursor)
                        .add("group", group)
                        .add("license_pool", licensePool)
                        .add("all", all || "false")
                        .createQueryString(),
                "GET",
                null,
                {
                    abortController: abortController,
                    apiType: ApiType.LAUREL,
                }
            )
            .then((dto: UsersResponseDto) => {
                return {
                    count: dto.count,
                    cursor: dto.cursor,
                    userTableData: dto.user_list.map(toUserData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch users.");
            });
    }

    public createUser(
        fullName: string,
        email: string,
        roleUuid: string,
        expirationDate: string,
        loginMethod: UserLoginMethod,
        licensePool: string,
        userType: UserType,
        abortController: AbortController
    ): Promise<void> {
        return apiGatewayService.invokeApi(
            "/api/users",
            "POST",
            {
                email,
                name: fullName,
                role: roleUuid,
                expiration_date: expirationDate,
                login_method: this.deduceLoginMethod(loginMethod),
                license_pool: trimToNull(licensePool),
                user_type: userType,
            },
            { abortController: abortController, apiType: ApiType.LAUREL }
        );
    }

    public editUser(
        uuid: string,
        fullName: string,
        roleUuid: string,
        licensePool: string,
        expirationDate: string,
        enabled: boolean,
        loginMethod: UserLoginMethod,
        userType: UserType,
        abortController: AbortController
    ): Promise<void> {
        const postData = {
            name: fullName,
            role: roleUuid,
            expiration_date: expirationDate,
            enabled: enabled,
            login_method: this.deduceLoginMethod(loginMethod),
            license_pool: trimToNull(licensePool),
            user_type: userType,
        };
        return apiGatewayService.invokeApi("/api/users/" + uuid, "POST", postData, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
        });
    }

    public editUserWorkflowStatus(
        uuid: string | undefined,
        updateWorkflow: boolean,
        abortController: AbortController
    ): Promise<void> {
        const postData = {
            update_workflow: updateWorkflow,
        };
        return apiGatewayService.invokeApi("/api/users/" + uuid, "POST", postData, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
        });
    }

    public fetchUser(uuid: string, abortController?: AbortController): Promise<UserByUuid> {
        return apiGatewayService
            .invokeApi("/api/users/" + uuid, "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((response: UserByUuidResponse) => toUserByUuid(response.user));
    }

    public editDefaultViewSettings(
        uuid: string,
        defaultViews: DefaultViews,
        abortController: AbortController
    ): Promise<void> {
        return apiGatewayService.invokeApi("/api/users/" + uuid + "/settings", "POST", defaultViews, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
        });
    }

    public deleteUser(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/users/" + uuid, "DELETE", null, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
        });
    }

    public checkEmailAvailability(email: string, abortController: AbortController): Promise<CheckEmailAvailability> {
        return apiGatewayService
            .invokeApi(`/check-email-availability?email=` + encodeURIComponent(email), "GET", null, { abortController })
            .then((dtos: EmailCheckDto) => ToEmailCheck(dtos));
    }

    public fetchSsoSettings(
        uuid: string,
        protocol?: string,
        abortController?: AbortController
    ): Promise<SsoConfiguration> {
        let api_url = "/api/v1/tenants/" + uuid + "/sso";
        if (protocol != undefined) {
            api_url += "?protocol=" + protocol;
        }
        return apiGatewayService
            .invokeApi(api_url, "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((dto: SsoLoginDto) => toSsoLogin(dto));
    }

    public deleteSsoSettings(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/v1/tenants/" + uuid + "/sso", "DELETE", null, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public createSsoConfiguration(
        uuid: string,
        protocol: string,
        metadataUrl?: string,
        clientId?: string,
        clientSecret?: string,
        oidcIssuer?: string,
        abortController?: AbortController
    ): Promise<void> {
        const postData =
            protocol == "SAML"
                ? {
                      metadata_url: metadataUrl,
                  }
                : {
                      client_id: clientId,
                      client_secret: clientSecret,
                      oidc_issuer: oidcIssuer,
                  };

        return apiGatewayService.invokeApi("/api/v1/tenants/" + uuid + "/sso?protocol=" + protocol, "POST", postData, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    private deduceLoginMethod(loginMethod: UserLoginMethod) {
        return loginMethod === DEFAULT_LOGIN_METHOD ? undefined : loginMethod;
    }

    public async deleteWorkflowUpdates(uuid: string, abortController?: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(`${PATH_API_USERS_V1}/${uuid}/workflow-updates`, "DELETE", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.ARTHUR,
            includeCredentials: true,
            emptyResponse: true,
        });
    }
}

export const userService = new UserService();
