import { FlagsProvider } from "flagged";
import React from "react";
import * as ReactDom from "react-dom";
import ReactModal from "react-modal";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

import BlanccoCommonCloud from "components/BlanccoCommonCloud";
import { featureFlagService } from "services/feature/FeatureFlagService";
import { store } from "store";

const APP_ELEMENT_ID = "blancco-common-cloud";
const queryClient = new QueryClient();

ReactDom.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <FlagsProvider features={featureFlagService.getFlags()}>
                <BlanccoCommonCloud />
            </FlagsProvider>
        </QueryClientProvider>
    </Provider>,
    document.getElementById(APP_ELEMENT_ID),
    () => {
        ReactModal.setAppElement(document.getElementById(APP_ELEMENT_ID) as HTMLElement);
    }
);
