import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./manage-role.scss";
import ManageRoleForm, { FormValues } from "./ManageRoleForm";
import Modal from "components/modal/Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { rolesService } from "services/roles/RolesService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttons from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
}

export interface Props {
    onRoleAdded: () => void;
}

const ManageRoleView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [addRoleFormVisible, setAddRoleFormVisible] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    });

    const addRoleSubmitEventHandler = async ({
        name,
        description,
        authorities,
        targets,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await rolesService.generateRoles(name, description, authorities, targets, abortController);
        } catch (e) {
            if (!signal.aborted) {
                setAddRoleFormVisible(false);
                showResult({
                    title: t("ManageRoleForm.failure.title"),
                    message: t("ManageRoleForm.failure.message"),
                });
                logger.error("Failed to add a role:", e);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setAddRoleFormVisible(false);
        showResult({
            title: t("ManageRoleForm.success.title"),
            message: t("ManageRoleForm.success.message", { name: name }),
        });
    };

    const showAddRoleForm = () => {
        usageStatisticsService.sendEvent({
            category: Category.ROLE,
            action: Action.ADD_ROLE,
        });
        setAddRoleFormVisible(true);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResult = () => {
        setResultVisible(false);
        props.onRoleAdded();
    };

    const addRoleModal = (
        <Modal
            isOpen={addRoleFormVisible}
            hideModal={() => setAddRoleFormVisible(false)}
            modalTitle={t("ManageRoleForm.createRoleTitle")}
        >
            <div className={style.fixedWidthModal}>
                <TextBlock>{t("ManageRoleForm.addUserRoleSummaryText")}</TextBlock>
                <ManageRoleForm
                    name={""}
                    authorities={[]}
                    submitEventHandler={addRoleSubmitEventHandler}
                    isManager={false}
                />
            </div>
        </Modal>
    );
    return (
        <div>
            <button
                className={buttons.primaryButtonWithoutIcon}
                onClick={showAddRoleForm}
                data-testid={testIds.workArea.roles.addRoleButton}
            >
                {t("ManageRoleForm.createRoleButton")}
            </button>
            {addRoleModal}
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={buttons.primaryOkButton}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ManageRoleView;
