import classNames from "classnames";
import { useFeature } from "flagged";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./erasure-reports-table.scss";
import { Format } from "./ErasureReportModal";
import Swap from "./Swap";
import Info from "components/icons/Info";
import dialogStyle from "components/reports/export/export-report-dialog.scss";
import Tooltip from "components/tooltip/Tooltip";
import { CustomReportViewFilterDto, MAX_COLUMN_COUNT } from "domain/reports";
import { FLAG_EXPORT_REPORTS_FILENAME } from "services/feature/FeatureFlagService";
import { createTranslatePath } from "services/pathTranslator";
import { reportViewService } from "services/report/ReportViewService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

export interface Values {
    reportUuid: string;
    language: string;
    format: string;
    templateUuid: string;
    columns?: string[];
    separator?: string;
    filenameColumns?: string[];
}

interface Props {
    reportUuid: string;
    columns?: string[];
    handle: (values: Values) => Promise<void>;
    isAllReportsView: boolean;
    filters?: CustomReportViewFilterDto[];
    language: string;
    formatType: string /*TODO BCC-2437 Change this to enum type */;
    templateUuid: string;
    onShowModal: (doShow: boolean) => void;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));
const SingleReportExportDialog = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const paths = reportViewService.getPaths();
    const translatePath = createTranslatePath(useTranslation);
    function handleHideModal() {
        props.onShowModal(false);
    }
    const DEFAULT_COLUMN_NAME = "defaultColumnName";
    const CUSTOM_COLUMN_NAME = "customColumnName";

    const [customColumnNames, setCustomColumnNames] = React.useState(DEFAULT_COLUMN_NAME);
    const separatorList = "@._;,#-";

    const handleColumnNameChange = (event: { target: { value: string } }) => {
        const value = event.target.value;
        setCustomColumnNames(value);
        setFileNameColumnCount(0);
    };
    const [separator, setSeparator] = React.useState("_");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [enabledExportButton, setEnabledExportButton] = React.useState<boolean>(false);
    const [fileNameColumnCount, setFileNameColumnCount] = React.useState<number>(0);

    function handleSeparatorChange(event: { target: { value: string } }) {
        setErrorMessage("");
        const value = event.target.value;
        if (value != "") {
            if (new RegExp("[" + separatorList + "]").test(value)) {
                setSeparator(value);
            } else {
                setErrorMessage(t("ErasureReport.fileName.errorMessage"));
            }
        } else {
            setErrorMessage(t("ErasureReport.fileName.errorMessage"));
            setSeparator("");
        }
    }
    const createSeparator = () => {
        return (
            <div
                className={classNames(
                    dialogStyle.separatorDiv,
                    dialogStyle.exportFilenameDiv,
                    dialogStyle.singleExportDivGaps
                )}
            >
                <label className={dialogStyle.singleExportFilenameLabal}>{t("ErasureReport.fileName.separator")}</label>
                <input
                    className={dialogStyle.separatorInput}
                    id="separator"
                    type="input"
                    value={separator}
                    name="separator"
                    onChange={handleSeparatorChange}
                    maxLength={1}
                    data-testid={testIds.workArea.report.erasure.exportReportDialog.filenameSeparatorInput}
                />
                <Tooltip
                    content={<>{t("ErasureReport.fileName.typeOfSeparator", { separatorList })}</>}
                    placement={"top"}
                >
                    <div className={classNames(form.infoIcon, dialogStyle.separatorInfoIcon)}>
                        <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                    </div>
                </Tooltip>
                <label className={dialogStyle.errorMessage}>{errorMessage}</label>
            </div>
        );
    };

    return (
        <>
            <div className={classNames(style.modal, style.exportReportInnerData)}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        reportUuid: props.reportUuid,
                        language: props.language,
                        format: props.formatType,
                        templateUuid: props.templateUuid,
                        columns: props.columns,
                        separator: separator,
                        filenameColumns: undefined,
                    }}
                    onSubmit={(values) => props.handle(values)}
                >
                    {({ setFieldValue }: FormikProps<Values>) => {
                        function updateColumns(paths: string[]) {
                            setFieldValue("columns", paths, true);
                        }
                        function updateFileNameColumns(paths: string[]) {
                            setFieldValue("filenameColumns", paths, true);
                            setFileNameColumnCount(paths.length);
                        }
                        function shouldExportButtonBeEnabled() {
                            if (customColumnNames === CUSTOM_COLUMN_NAME && fileNameColumnCount > 0) {
                                return true;
                            } else if (customColumnNames != CUSTOM_COLUMN_NAME) {
                                return true;
                            }
                            return false;
                        }

                        setEnabledExportButton(shouldExportButtonBeEnabled());
                        return (
                            <Form>
                                {useFeature(FLAG_EXPORT_REPORTS_FILENAME) && (
                                    <>
                                        <div
                                            className={classNames(
                                                dialogStyle.exportFilenameDiv,
                                                dialogStyle.singleExportDivGaps
                                            )}
                                        >
                                            <label className={dialogStyle.singleExportFilenameLabal}>
                                                {t("Common.reportUuid")}
                                            </label>
                                            <div>
                                                <label className={dialogStyle.exportRadioButton}>
                                                    {props.reportUuid}
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className={classNames(
                                                dialogStyle.exportFilenameDiv,
                                                dialogStyle.singleExportDivGaps
                                            )}
                                        >
                                            <label className={dialogStyle.singleExportFilenameLabal}>
                                                {t("ErasureReport.fileName.singleReportExportFileName")}
                                            </label>
                                            <div>
                                                <input
                                                    id="defaultName"
                                                    type="radio"
                                                    className={dialogStyle.exportRadioButton}
                                                    value={DEFAULT_COLUMN_NAME}
                                                    name="filenameSection"
                                                    data-testid={
                                                        testIds.workArea.report.erasure.exportReportDialog
                                                            .filenameRadioButton
                                                    }
                                                    defaultChecked={customColumnNames === DEFAULT_COLUMN_NAME}
                                                    onChange={handleColumnNameChange}
                                                />
                                                <label htmlFor="defaultName">
                                                    {t("ErasureReport.fileName.defaultColumns")}
                                                </label>
                                                <br />
                                                <input
                                                    id="customName"
                                                    type="radio"
                                                    className={dialogStyle.exportRadioButton}
                                                    value={CUSTOM_COLUMN_NAME}
                                                    name="filenameSection"
                                                    data-testid={
                                                        testIds.workArea.report.erasure.exportReportDialog
                                                            .filenameRadioButton
                                                    }
                                                    onChange={handleColumnNameChange}
                                                />
                                                <label htmlFor="customName">
                                                    {t("ErasureReport.fileName.customColumns")}
                                                </label>
                                            </div>
                                        </div>
                                        {customColumnNames === CUSTOM_COLUMN_NAME && (
                                            <>
                                                <div className={classNames(style.header)}>{createSeparator()}</div>
                                                <div
                                                    data-testid={
                                                        testIds.workArea.report.erasure.exportReportDialog
                                                            .filenameColumnContainer.itself
                                                    }
                                                >
                                                    <Swap
                                                        maximumSelectedCount={MAX_COLUMN_COUNT}
                                                        onChange={updateFileNameColumns}
                                                        paths={paths}
                                                        t={t}
                                                        create={props.isAllReportsView}
                                                        columns={undefined}
                                                        theme={props.theme}
                                                        translatePath={translatePath}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                {(props.formatType === Format.SUMMARY_CSV ||
                                    props.formatType === Format.SUMMARY_PDF) && (
                                    <>
                                        <div className={form.underline} />
                                        <div className={dialogStyle.exportFilenameDiv}>
                                            <label className={dialogStyle.exportFilenameLabal}>
                                                {t("CreateReportView.form.detailHeader")}
                                            </label>
                                        </div>
                                        <div
                                            data-testid={
                                                testIds.workArea.report.erasure.exportReportDialog.reportColumnContainer
                                                    .itself
                                            }
                                        >
                                            <Swap
                                                maximumSelectedCount={MAX_COLUMN_COUNT}
                                                onChange={updateColumns}
                                                paths={paths}
                                                t={t}
                                                create={props.isAllReportsView}
                                                columns={props.columns}
                                                theme={props.theme}
                                                translatePath={translatePath}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className={buttonStyle.buttonContainer}>
                                    <button
                                        className={buttonStyle.primaryButtonWithoutIcon}
                                        disabled={!enabledExportButton}
                                        type="submit"
                                        data-testid={testIds.workArea.report.erasure.exportReportDialog.exportButton}
                                    >
                                        {t("Common.export")}
                                    </button>
                                    <button
                                        className={buttonStyle.secondaryButtonWithoutIcon}
                                        type="button"
                                        onClick={handleHideModal}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default connector(SingleReportExportDialog);
