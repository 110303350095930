import moment from "moment/moment";

import { getMaxDate } from "utils/commonFunctions";

export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const FULL_ISO_DATE_OFFSET_CUTOFF = 19;
export const HOUR = 23;
export const MINUTES_SECONDS_MILLISECONDS = 59;

export function createDateLabel(date: string | Date): string {
    const dateParameter: Date = typeof date === "string" ? new Date(date) : date;
    return new Intl.DateTimeFormat("en-GB", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    }).format(dateParameter);
}

export function formatIsoDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("" + (date.getMonth() + 1)).padStart(2, "0");
    const day = ("" + date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

export function formatExpirationDate(dateString: string) {
    return formatDatePickerValue(dateString, false);
}

export function formatDatePickerValue(dateString: string, convertToLocal = true): string {
    if (!convertToLocal) {
        const splitDateString = dateString.split("T");
        if (splitDateString.length === 2) {
            dateString = toDateStringWithoutOffset(dateString);
        }
    }
    const date = moment(dateString);
    if (!date.isValid()) {
        return "";
    }
    return date.format("YYYY-MM-DD");
}

export function formatExpirationDateWithoutTime(dateString: string) {
    return formatDatePickerWithoutTime(dateString, false);
}

export function formatDatePickerWithoutTime(dateString: string, convertToLocal = true): string {
    if (!convertToLocal) {
        const splitDateString = dateString.split("T");
        if (splitDateString.length === 2) {
            dateString = toDateStringWithoutOffset(dateString);
        }
    }
    const date = moment(dateString);
    if (!date.isValid()) {
        return "";
    }
    return date.format("D/M/YYYY");
}

function toDateStringWithoutOffset(dateString: string): string {
    return dateString.substring(0, FULL_ISO_DATE_OFFSET_CUTOFF);
}

export function currency(value: number, currency: string): string {
    const PRICE = (value / 100).toString();
    switch (currency) {
        case "USD":
            return "$" + PRICE;
        case "EUR":
        case "E":
            return PRICE + "€";
        case "GBP":
            return PRICE + "£";
        default:
            return PRICE + currency;
    }
}

export function formatPrice(value: number): string {
    value = value / 100;
    const formatted = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 }).format(value);
    return formatted;
}

export function daysAfterToday(days: number): string {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return formatIsoDate(date);
}

export function formatDateWithoutTime(dateString: string): string {
    const date = new Date(dateString.replace("Z", ""));
    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
}

export function formatTimestamp(dateString: string, timeFirst = false, convertToLocal = true): string {
    // Creating a Date object from a UTC date string causes a conversion to local time. By removing
    // the timezone, the date is assumed to be local already.
    if (!convertToLocal) {
        dateString = dateString.replace("Z", "");
    }
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const timePart = `${hours}:${minutes}`;
    const datePart = `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
    return timeFirst ? `${timePart}, ${datePart}` : `${datePart}, ${timePart}`;
}

export function formatDate(dateValue: moment.Moment): string {
    const DATE_FORMAT = "yyyy-MM-DDTHH:mm:ss";
    return dateValue.format(DATE_FORMAT) + "Z";
}

export function toUtcDateString(date: Date) {
    return (
        new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        )
            .toISOString()
            .split(".")[0] + "Z"
    );
}

export function formatUtcDateString(dateString: string, hours: number, minutes: number, seconds: number) {
    const date = new Date(dateString);
    date.setHours(hours, minutes, seconds);
    return date.toISOString().replace(/.000/, "");
}

export function isExpired(dateString: string) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const expirationDate = new Date(dateString);
    return currentDate > expirationDate;
}

export function generateLastDate() {
    const maxDate = getMaxDate();
    return maxDate.getDate().toString() + " " + MONTHS[maxDate.getMonth()] + " " + maxDate.getFullYear();
}

// It's possible for last month and last thirty days to be the exact same date range. We can adjust one of the Date
// objects within said ranges temporarily to avoid a conflict when determining the selected range with no impact
// to the user.
export function toConflictAdjustedDate(date: Date): Date {
    const result = new Date(date);
    result.setSeconds(date.getSeconds() - 1);
    return result;
}
