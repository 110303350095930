import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormValues } from "./CustomReportView";
import { InputField } from "./InputFields";

interface MonitorTemplateProps {
    formikProps: FormikProps<FormValues>;
}

const MonitorTemplate = ({ formikProps }: MonitorTemplateProps): JSX.Element => {
    const { t } = useTranslation();
    const { values, setFieldValue } = formikProps;

    const inputFields1 = useMemo(
        () => [
            {
                id: "aspectRatio",
                label: t("ErasureReport.customReport.templateSpecifications.aspectRatio"),
                dataPath: "user_data.fields.usercreatedreport.aspect_ratio",
            },
            {
                id: "comments",
                label: t("ErasureReport.customReport.templateSpecifications.comments"),
                dataPath: "user_data.fields.usercreatedreport.comments",
            },
            {
                id: "make",
                label: t("ErasureReport.customReport.templateSpecifications.make"),
                dataPath: "user_data.fields.usercreatedreport.make",
            },
            {
                id: "model",
                label: t("ErasureReport.customReport.templateSpecifications.model"),
                dataPath: "user_data.fields.usercreatedreport.model",
            },
            {
                id: "resolution",
                label: t("ErasureReport.customReport.templateSpecifications.resolution"),
                dataPath: "blancco_hardware_report.display.resolution",
            },
            {
                id: "serialNumber",
                label: t("ErasureReport.customReport.templateSpecifications.serialNumber"),
                dataPath: "user_data.fields.usercreatedreport.serial_number",
            },
            {
                id: "size",
                label: t("ErasureReport.customReport.templateSpecifications.size"),
                dataPath: "user_data.fields.usercreatedreport.size",
            },
            {
                id: "type",
                label: t("ErasureReport.customReport.templateSpecifications.type"),
                dataPath: "user_data.fields.usercreatedreport.type",
            },
        ],
        [values, t]
    );

    return (
        <>
            {inputFields1.map((field) => (
                <InputField
                    key={field.id}
                    id={field.id}
                    setFieldValue={setFieldValue}
                    label={field.label}
                    dataPath={field.dataPath}
                />
            ))}
        </>
    );
};

export default MonitorTemplate;
