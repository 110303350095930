import * as UserSessionRepository from "./userSessionRepository";
import { DELIVERYHISTORY_LICENSES_ROUTE, SUPPORT_AND_HELP_ROUTE } from "components/router/Routes";
import {
    AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
    AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
    AUTH_LICENSE_ASSIGN,
    AUTH_LICENSE_VIEW,
} from "domain/authority";
import { details } from "domain/endpoint";
import { LicensingModel, LoginMethod, TenantType } from "domain/tenants";
import { CommonUserDetails, DefaultViews, Role, UserDetails } from "domain/user";
import { FeatureLicenseType, toFeatureLicenseType } from "domain/users";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import * as endpointRepository from "services/login/endpointRepository";
import { authenticationService } from "services/security/AuthenticationService";
import { removeTenantCookie } from "services/tenants/tenantCookieService";
import { TenantLicense } from "services/tenants/TenantService";
import { removeUser } from "store/user";
import { logger } from "utils/logging";

interface UserDetailsDto extends CommonUserDetails {
    tenant_name: string;
    tenant_type: TenantType;
    tenant_uuid: string;
    usage_statistics: boolean;
    erasure_client_endpoint: string;
    eula_accepted_date: string;
    feature_licenses: string[];
    region: string;
    role: Role;
    enabled: boolean;
    tenant_layer: string;
    update_workflow: boolean;
    user_expiration_date: string;
    tenant_expiration_date: string;
    tenant_status: boolean;
    tier: string;
    default_views: DefaultViews;
    tenant_licenses: TenantLicense[];
    tenant_login_method: LoginMethod;
    show_workflow_update_dialog: boolean;
    licensing_model: LicensingModel;
    workflow_updates: Record<string, string>;
}

function toUserDetails(dto: UserDetailsDto): UserDetails {
    const featureLicenses: FeatureLicenseType[] = dto.feature_licenses.map((l) => toFeatureLicenseType(l));
    return {
        created: dto.created,
        email: dto.email,
        modified: dto.modified,
        name: dto.name,
        username: dto.username,
        uuid: dto.uuid,
        tenantName: dto.tenant_name,
        tenantType: dto.tenant_type,
        tenantUuid: dto.tenant_uuid,
        usageStatistics: dto.usage_statistics,
        erasureClientEndpoint: dto.erasure_client_endpoint,
        eulaAcceptedDate: dto.eula_accepted_date,
        featureLicenses: featureLicenses,
        region: dto.region,
        role: dto.role,
        enabled: dto.enabled,
        tenantLayer: dto.tenant_layer,
        updateWorkflow: dto.update_workflow,
        userExpirationDate: dto.user_expiration_date,
        tenantExpirationDate: dto.tenant_expiration_date,
        tenantStatus: dto.tenant_status,
        tier: dto.tier,
        default_views: {
            default_view:
                dto.default_views && dto.default_views.default_view
                    ? dto.default_views.default_view
                    : SUPPORT_AND_HELP_ROUTE.path,
            tenant_access_default_view:
                dto.default_views && dto.default_views.tenant_access_default_view
                    ? dto.default_views.tenant_access_default_view
                    : userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW, AUTH_LICENSE_ASSIGN])
                    ? DELIVERYHISTORY_LICENSES_ROUTE.path
                    : SUPPORT_AND_HELP_ROUTE.path,
        },
        tenantLoginMethod: dto.tenant_login_method,
        licensingModel: dto.licensing_model,
        workflowUpdates: dto.workflow_updates,
    };
}

class UserSessionService {
    private static removeSession(): void {
        UserSessionRepository.clear();
        endpointRepository.clear();
    }

    private setEndpointUrls(response: details): void {
        endpointRepository.setUrl(
            response.message.stan,
            response.message.laurel,
            response.message.arthur,
            response.message.oliver,
            response.message.publicApi,
            response.message.publicApiDocumentation
        );
    }

    public async login(username: string, password: string): Promise<UserDetails> {
        try {
            await authenticationService
                .authenticate(username.toLowerCase(), password)
                .then((response) => {
                    this.setEndpointUrls(response);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });

            return await this.fetchUserDetails();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async ssoLogin(authorizationCode: string): Promise<UserDetails> {
        try {
            await authenticationService
                .ssoAuthenticate(authorizationCode)
                .then((response) => {
                    this.setEndpointUrls(response);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });

            return await this.fetchUserDetails();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public storeUser(user: UserDetails) {
        UserSessionRepository.setUser(user);
    }

    public async logout() {
        await authenticationService.logout();
        try {
            UserSessionService.removeSession();
        } catch (e) {
            logger.error("Unable to remove user session", e);
        }

        removeTenantCookie();
        removeUser();

        window.location.replace("/login");
        return Promise.resolve();
    }

    public currentUserDetails(): UserDetails | null {
        try {
            return UserSessionRepository.getUser();
        } catch (e) {
            logger.error("Unable to get the user details", e);
        }
        return null;
    }

    /**
     * Does the logged in user have a role and authorities within it?
     *
     * @return true when user has role or user isn't logged in. False otherwise.
     */
    public userHasRole(): boolean {
        const user = this.currentUserDetails();
        if (user == null) {
            return true;
        }
        return user.role != null && user.role.authorities != null;
    }

    /**
     * Check if currently logged in user has all authorities.
     *
     * @param authorities If this is empty, it's equivalent to checking that user has authorities in general.
     *
     * @return true if logged in user has provided authorities.
     */
    public userHasAllAuthorities(authorities: string[]): boolean {
        const user = this.currentUserDetails();
        if (user == null) {
            return false;
        }

        const usersAuthorities = new Set(user.role.authorities);

        for (const each of authorities) {
            if (!usersAuthorities.has(each)) {
                return false;
            }
        }

        return true;
    }

    public permittedToCreateCustomViews(featureLicenses?: FeatureLicenseType[]): boolean {
        if (featureLicenses === undefined || featureLicenses.length < 1) {
            return false;
        }
        return (
            featureLicenses.includes("FEATURE_CUSTOM_REPORT_VIEWS") &&
            userSessionService.userHasAnyAuthority([
                AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
                AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
            ])
        );
    }

    public userHasAnyAuthority(authorities: string[]): boolean {
        const user = this.currentUserDetails();
        if (user == null) {
            return false;
        }

        const usersAuthorities = new Set(user.role.authorities);

        for (const each of authorities) {
            if (usersAuthorities.has(each)) {
                return true;
            }
        }

        return false;
    }

    public fetchUserDetails(): Promise<UserDetails> {
        return apiGatewayService
            .invokeApi("/api/users/profile", "get", null, { apiType: ApiType.LAUREL })
            .then((dto: UserDetailsDto) => toUserDetails(dto))
            .then((user: UserDetails) => {
                return user;
            })
            .catch(() => {
                return Promise.reject("Unable to retrieve user data");
            });
    }

    public hasFeatureLicense(featureLicense: FeatureLicenseType): boolean {
        const currentUser = this.currentUserDetails();
        if (currentUser == null) {
            return false;
        }
        return currentUser.featureLicenses.includes(featureLicense);
    }
}

export const userSessionService = new UserSessionService();
