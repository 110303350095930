import React from "react";
import { useSelector } from "react-redux";

import style from "./feature-update-modal.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import Heading from "components/typography/heading/Heading";
import { DOT } from "domain/globalConstants";
import { getLanguage } from "services/language/languageRepository";
import { Profile, profileToNameMap, workflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { logger } from "utils/logging";

export interface Highlight {
    title: string;
    text: string;
}

export interface HighlightData {
    profile: Profile;
    highlights: Highlight[];
}

interface Props {
    open: boolean;
    hide: () => void;
    title: string;
    banner?: JSX.Element | undefined;
    actions?: JSX.Element;
    bmpHighlights?: HighlightData;
    testId: string;
}

const FeatureUpdateModal = (props: Props) => {
    const banner = props.banner ? props.banner : <></>;
    const [highlights, setHighlights] = React.useState<HighlightData[]>([]);
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const allHighlights: HighlightData[] = [];
        if (props.bmpHighlights) {
            allHighlights.push(props.bmpHighlights);
        }
        const updates = user?.workflowUpdates;
        if (!props.open || !updates) {
            setHighlights(allHighlights);
            return;
        }
        setLoading(true);
        const fetchTasks: Promise<boolean>[] = [];
        Object.entries(updates).forEach(([profile, version]) => {
            const task = new Promise((resolve: (result: boolean) => void) => {
                workflowService
                    .fetchWorkflowEditorHighlights(profile, version, getLanguage().code)
                    .then((highlights) => {
                        allHighlights.push({ profile: profile.toLowerCase() as Profile, highlights: highlights });
                        resolve(true);
                    })
                    .catch(() => {
                        logger.error("Failed to download highlights for profile: " + profile);
                        resolve(false);
                    });
            });
            fetchTasks.push(task);
        });
        Promise.allSettled(fetchTasks).then(() => {
            setHighlights(allHighlights);
            setLoading(false);
        });
    }, []);
    return (
        <Modal isOpen={props.open} hideModal={props.hide} modalTitle={props.title} maxHeight={800}>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <div data-testid={props.testId}>
                    <div className={style.banner}>{banner}</div>
                    {highlights.map((profileHighlights) => {
                        return (
                            <>
                                <Heading tag="h1" variant="SUBTITLE_1" className={style.heading}>
                                    {profileToNameMap.get(profileHighlights.profile)}
                                </Heading>
                                {profileHighlights.highlights.map((highlight, index) => {
                                    return (
                                        <div key={index} className={style.improvements}>
                                            <div>{DOT}</div>
                                            <div>
                                                <div>
                                                    <b>{highlight.title}</b>
                                                </div>
                                                <div>{highlight.text}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        );
                    })}
                    <ButtonContainer>{props.actions}</ButtonContainer>
                </div>
            )}
        </Modal>
    );
};

export default FeatureUpdateModal;
