// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._18SEg0pxobmlc2mGSJL66k{width:436px;display:grid;justify-items:center}._2dxUqV71nW3VDD9qI7vY3x{padding-top:15px;padding-bottom:15px;display:grid;justify-items:center}", ""]);
// Exports
exports.locals = {
	"contentContainer": "_18SEg0pxobmlc2mGSJL66k",
	"textContainer": "_2dxUqV71nW3VDD9qI7vY3x"
};
module.exports = exports;
