import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import { AddIcon } from "components/icons/AddIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import {
    ConditionAssessmentQuestions,
    NtfChannel,
    NtfConfiguration,
    NtfProvider,
} from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import ConditionAssessmentQuestionsForm from "components/licenses/license-configuration/bms/diagnostics/ConditionAssessmentQuestionsForm";
import Checkbox from "components/licenses/license-configuration/bms/form-components/BmsCheckboxComponent";
import BmsOptionsInputComponent from "components/licenses/license-configuration/bms/form-components/BmsOptionsInputComponent";
import InputComponent from "components/licenses/license-configuration/bms/form-components/BmsTextInputComponent";
import BoxConnector from "components/licenses/license-configuration/bms/form-components/BoxConnector";
import questionsData from "components/licenses/license-configuration/bms/UiConfiguration.json";
import tableStyle from "components/reports/erasure-reports-table.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";

export enum Program {
    HOME = "HOME",
    RETAIL = "RETAIL",
    DUAL = "DUAL",
}

interface FormValues {
    ntfConfiguration: NtfConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const NtfProviderForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const programTypes = [
        { value: "", label: t("Configuration.common.programmeTypeSelection") },
        { value: Program.HOME, label: t("Configuration.common.programmeOnlineType") },
        { value: Program.RETAIL, label: t("Configuration.common.programmeRetailType") },
        { value: Program.DUAL, label: t("Configuration.common.programmeDualType") },
    ];

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    function addProvider() {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;

            const numberOfProviders = providers.length;
            const providerId = "provider_" + numberOfProviders;

            const newProvider: NtfProvider = {
                id: providerId,
                name: "Provider " + numberOfProviders,
                region: providers[0].region,
                testSelectionEnabled: true,
                questions: questionsData.questions,
                channels: [
                    {
                        id: providerId + "_channel_0",
                        name: "Channel ",
                        type: Program.RETAIL,
                        punchOutUrl: "https://www.blancco.com",
                        storeLocatorUrl: "https://www.blancco.com",
                        phone: null,
                    },
                ],
            };

            providers.push(newProvider);

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: providers } };
        });
    }

    function removeProvider(providerIndex: number) {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;

            if (providerIndex >= 0 && providerIndex < providers.length) {
                providers.splice(providerIndex, 1);
            }

            return previousFormValues;
        });
    }

    const handleProviderFieldChange = (index: number, fieldName: string, fieldValue: string | boolean) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];
            updatedProviders[index] = { ...providers[index], [fieldName]: fieldValue };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    };

    function addChannelToProvider(providerIndex: number) {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];

            const numberOfChannels = updatedProviders[providerIndex].channels.length;
            const channelId = updatedProviders[providerIndex].id + "channel_" + numberOfChannels;

            const newChannel: NtfChannel = {
                id: channelId,
                name: "Channel " + numberOfChannels,
                type: Program.RETAIL,
                punchOutUrl: "https://www.blancco.com",
                storeLocatorUrl: "https://www.blancco.com",
                phone: null,
            };

            updatedProviders[providerIndex] = {
                ...updatedProviders[providerIndex],
                channels: [...updatedProviders[providerIndex].channels, newChannel],
            };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    }

    function removeChannelFromProvider(providerIndex: number, channelIndex: number) {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];

            if (
                providerIndex >= 0 &&
                providerIndex < updatedProviders.length &&
                channelIndex >= 0 &&
                channelIndex < updatedProviders[providerIndex].channels.length
            ) {
                updatedProviders[providerIndex] = {
                    ...updatedProviders[providerIndex],
                    channels: updatedProviders[providerIndex].channels.filter((_, index) => index !== channelIndex),
                };

                return {
                    ...previousFormValues,
                    ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders },
                };
            }

            return previousFormValues;
        });
    }

    const handleChannelInputChange = (
        providerIndex: number,
        channelIndex: number,
        fieldName: string,
        value: string
    ) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];
            const updatedChannels = [...updatedProviders[providerIndex].channels];

            updatedChannels[channelIndex] = { ...updatedChannels[channelIndex], [fieldName]: value };
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], channels: updatedChannels };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    };

    const handleProviderQuestionsChange = (providerIndex: number, questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], questions };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    };

    return (
        <>
            {props.formValues.ntfConfiguration.providers.map((provider, providerIndex) => (
                <div key={"ntf_" + providerIndex} className={classNames(style.boxedSpacing)}>
                    <div className={classNames(style.boxed)}>
                        <Label className={classNames(style.bmsProviderHeaderLabel)}>
                            {t("Configuration.bbtiConfigurationForm.headers.providerInfo")}
                            <b> ({provider.name}) </b>

                            <button className={classNames(tableStyle.linkText)} onClick={() => addProvider()}>
                                {providerIndex === 0 && (
                                    <AddIcon
                                        color={props.theme.iconFillColor}
                                        linecolor={props.theme.contentBackgroundColor}
                                    />
                                )}
                            </button>
                            {providerIndex > 0 && (
                                <button
                                    className={classNames(tableStyle.linkText)}
                                    onClick={() => removeProvider(providerIndex)}
                                >
                                    <DeleteIcon
                                        color={props.theme.iconFillColor}
                                        linecolor={props.theme.contentBackgroundColor}
                                        width={19}
                                        height={19}
                                    />
                                </button>
                            )}
                        </Label>
                        <InputComponent
                            labelKey={t("Configuration.common.providerName")}
                            toolTipContent={t(`Configuration.tooltips.common.providerName`)}
                            inputId={"name"}
                            value={provider.name}
                            onChange={(newValue) => handleProviderFieldChange(providerIndex, "name", newValue)}
                        />

                        <InputComponent
                            labelKey={t("Configuration.common.region")}
                            toolTipContent={t(`Configuration.tooltips.common.region`)}
                            inputId={"region"}
                            value={provider.region}
                            onChange={(newValue) => handleProviderFieldChange(providerIndex, "region", newValue)}
                        />

                        <Checkbox
                            labelKey={t("Configuration.ntfConfigurationForm.testSelectionEnabled")}
                            toolTipContent={t(`Configuration.tooltips.ntfConfigurationForm.testSelectionEnabled`)}
                            checkboxId={"testSelectionEnabled"}
                            checked={provider.testSelectionEnabled}
                            onChange={(newValue) =>
                                handleProviderFieldChange(providerIndex, "testSelectionEnabled", newValue)
                            }
                        />
                    </div>
                    {provider.channels.map((channel, channelIndex) => (
                        <div key={"ntf_channel_info_" + channelIndex}>
                            <BoxConnector className={channelIndex > 0 ? style.lineConnectorMediumHeight : ""} />
                            <div className={classNames(style.childDiv)}>
                                <div className={classNames(style.boxed)}>
                                    <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                        {t("Configuration.bbtiConfigurationForm.headers.channelInfo")}
                                        <b> ({channel.name}) </b>
                                        <button
                                            className={classNames(tableStyle.linkText)}
                                            onClick={() => addChannelToProvider(providerIndex)}
                                        >
                                            {channelIndex === 0 && (
                                                <AddIcon
                                                    color={props.theme.iconFillColor}
                                                    linecolor={props.theme.contentBackgroundColor}
                                                />
                                            )}
                                        </button>
                                        {channelIndex > 0 && (
                                            <button
                                                className={classNames(tableStyle.linkText)}
                                                onClick={() => removeChannelFromProvider(providerIndex, channelIndex)}
                                            >
                                                <DeleteIcon
                                                    color={props.theme.iconFillColor}
                                                    linecolor={props.theme.contentBackgroundColor}
                                                    width={19}
                                                    height={19}
                                                />
                                            </button>
                                        )}
                                    </Label>
                                    <InputComponent
                                        labelKey={t("Configuration.common.channelName")}
                                        toolTipContent={t(`Configuration.tooltips.common.channelName`)}
                                        inputId={"channel-name"}
                                        value={channel.name}
                                        onChange={(newValue) =>
                                            handleChannelInputChange(providerIndex, channelIndex, "name", newValue)
                                        }
                                    />

                                    <BmsOptionsInputComponent
                                        labelKey={t("Configuration.common.programmeType")}
                                        value={channel.type}
                                        options={programTypes}
                                        onChange={(value) =>
                                            handleChannelInputChange(providerIndex, channelIndex, "type", value)
                                        }
                                    />

                                    <span
                                        className={classNames({
                                            [style.hidden]: channel.type === Program.RETAIL,
                                        })}
                                    >
                                        <InputComponent
                                            labelKey={t("Configuration.common.punchOutUrl")}
                                            toolTipContent={t(`Configuration.tooltips.common.punchOutUrl`)}
                                            inputId={"punchOutUrl"}
                                            value={channel.punchOutUrl}
                                            onChange={(newValue) =>
                                                handleChannelInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "punchOutUrl",
                                                    newValue
                                                )
                                            }
                                        />
                                    </span>
                                    <span className={classNames({ [style.hidden]: channel.type === Program.HOME })}>
                                        <InputComponent
                                            labelKey={t("Configuration.common.storeLocatorUrl")}
                                            toolTipContent={t(`Configuration.tooltips.common.storeLocatorUrl`)}
                                            inputId={"storeLocatorUrl"}
                                            value={channel.storeLocatorUrl}
                                            onChange={(newValue) =>
                                                handleChannelInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "storeLocatorUrl",
                                                    newValue
                                                )
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <BoxConnector className={style.lineConnectorMediumHeight} />
                    <div className={classNames(style.childDiv)}>
                        <div className={classNames(style.boxed)}>
                            <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                {t("Configuration.common.headers.conditionAssessmentQuestions")}
                                <Tooltip
                                    content={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
                                    placement={"right-start"}
                                >
                                    <span className={localStyle.info} tabIndex={0}>
                                        <Info
                                            borderColor={props.theme.contentBackgroundColor}
                                            color={props.theme.iconFillColor}
                                        />
                                    </span>
                                </Tooltip>
                            </Label>
                            <ConditionAssessmentQuestionsForm
                                currentQuestions={provider.questions}
                                onQuestionsChange={(questions) =>
                                    handleProviderQuestionsChange(providerIndex, questions)
                                }
                            />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default connector(NtfProviderForm);
