import * as React from "react";

export const ReadyToUseIcon = (props: { linecolor: string }): JSX.Element => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.1327 6.30078L9.07031 8.36318M13.9608 9.12891L11.8984 11.1913M4.76943 15.4922L2.70703 17.5546M17.4999 2.76562L15.4375 4.82802M11.1914 16.1405L4.12034 9.06945M16.1445 11.1913L9.07346 4.12023M4.82782 9.77633L3.76716 10.837C2.79085 11.8133 2.79085 13.3962 3.76716 14.3725L5.88848 16.4938C6.86479 17.4702 8.44771 17.4702 9.42402 16.4938L10.4847 15.4332L4.82782 9.77633ZM9.78034 4.82772L10.841 3.76706C11.8173 2.79075 13.4002 2.79075 14.3765 3.76706L16.4979 5.88838C17.4742 6.86469 17.4742 8.4476 16.4979 9.42391L15.4372 10.4846L9.78034 4.82772Z"
                stroke={props.linecolor}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
