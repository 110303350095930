import classNames from "classnames";
import { ErrorMessage, Field, Formik, FormikConfig, Form as FormikForm, FormikProps } from "formik";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import style from "./entitlement.scss";
import Edit from "components/icons/Edit";
import kebabStyle from "components/kebab-menu/kebab-menu.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import { switchTheme } from "store/theme";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

const connector = connect(mapState, { switchTheme });

interface Entitlement {
    description: string;
}

interface Result {
    title: string;
    message: string;
}

const EntitlementMenuItems = (
    props: ConnectedProps<typeof connector> & {
        entitlementId: string;
        description: string;
        onEntitlementEdit: (isSync: boolean) => void;
    }
): JSX.Element => {
    const { t } = useTranslation();

    const [editEntitlementVisible, setEditEntitlementVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [resultVisible, setResultVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });

    const submitHandler: FormikConfig<Entitlement>["onSubmit"] = (values) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .editEntitlement(props.entitlementId, values.description, abortController)
            .then(() => {
                setEditEntitlementVisible(false);
                showResult({
                    title: t("Common.resultSuccessTitle"),
                    message: t("Common.resultSuccessMessage"),
                });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setEditEntitlementVisible(false);
                    showResult({
                        title: t("Common.resultErrorTitle"),
                        message: t("Common.resultErrorMessage"),
                    });
                }
            });
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResultDialog = () => {
        setResultVisible(false);
        props.onEntitlementEdit(false);
    };
    React.useEffect(() => {
        return () => abortControllers.filter((a) => !a.signal.aborted).forEach((a) => a.abort());
    }, []);
    return (
        <div className={style.entitlementIcon}>
            <Menu className={kebabStyle.kebabMenu}>
                <ul>
                    <li>
                        <TextWithTooltip text={t("Entitlements.editEntitlement.title")}>
                            <MenuItemButton
                                onClick={() => {
                                    setEditEntitlementVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE,
                                        action: Action.EDIT_ENTITLEMENT,
                                    });
                                }}
                            >
                                <Edit color={props.theme.iconFillColor} />
                            </MenuItemButton>
                        </TextWithTooltip>
                    </li>
                </ul>
            </Menu>

            <Modal
                isOpen={editEntitlementVisible}
                hideModal={() => setEditEntitlementVisible(false)}
                modalTitle={t("Entitlements.editEntitlement.title")}
            >
                <div>
                    <Formik
                        initialValues={{ description: props.description }}
                        onSubmit={submitHandler}
                        validationSchema={object().shape({
                            description: string()
                                .required(t("Entitlements.editEntitlement.emptyFieldMessage"))
                                .nullable()
                                .max(64),
                        })}
                    >
                        {({
                            values,
                            errors,
                            isSubmitting,
                            handleBlur,
                            handleChange,
                            isValid,
                            dirty,
                        }: FormikProps<Entitlement>) => {
                            if (isSubmitting) {
                                return <LoadingIndicator />;
                            }
                            return (
                                <FormikForm>
                                    <div className={style.description}>
                                        <div className={style.descriptionText}>
                                            <label htmlFor="name" className={form.label}>
                                                {t("Entitlements.entitlementTable.description")}
                                            </label>
                                            <Field
                                                id="description"
                                                name="description"
                                                className={classNames(form.input, form.fixedWidthInput, {
                                                    [form.inputError]: errors.description,
                                                })}
                                                rows={4}
                                                cols={30}
                                                data-testid={
                                                    testIds.workArea.license.entitlements.editEntitlementDialog
                                                        .description.itself
                                                }
                                                value={values.description}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <div
                                                className={form.error}
                                                data-testid={
                                                    testIds.workArea.license.entitlements.editEntitlementDialog
                                                        .description.errorLabel
                                                }
                                            >
                                                <ErrorMessage name="description" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={buttons.buttonContainer}>
                                        <button
                                            className={buttons.secondaryButtonWithoutIcon}
                                            type="reset"
                                            onClick={() => setEditEntitlementVisible(false)}
                                            data-testid={testIds.common.dialog.closeButton}
                                        >
                                            {t("Common.cancel")}
                                        </button>
                                        <button
                                            className={buttons.primaryButtonWithoutIcon}
                                            type="submit"
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                            disabled={!(isValid && dirty)}
                                        >
                                            {t("Common.save")}
                                        </button>
                                    </div>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideResultDialog} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={form.okButtonContainer}>
                    <button className={buttons.primaryOkButton} onClick={hideResultDialog}>
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default connector(EntitlementMenuItems);
