import * as React from "react";

const LocationPointer = (props: { borderColor: string; color: string }): JSX.Element => {
    return (
        <svg id="quick_checks_icons" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20">
            <g id="Group_4153" data-name="Group 4153" transform="translate(-4 -2)">
                <path
                    d="M19.5 10.3333C19.5 16.1666 12 21.1666 12 21.1666C12 21.1666 4.5 16.1666 4.5 10.3333C4.5 8.34413 5.29018 6.43647 6.6967 5.02995C8.10322 3.62343 10.0109 2.83325 12 2.83325C13.9891 2.83325 15.8968 3.62343 17.3033 5.02995C18.7098 6.43647 19.5 8.34413 19.5 10.3333Z"
                    stroke={props.borderColor}
                    fill={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 12.8333C13.3807 12.8333 14.5 11.714 14.5 10.3333C14.5 8.95254 13.3807 7.83325 12 7.83325C10.6193 7.83325 9.5 8.95254 9.5 10.3333C9.5 11.714 10.6193 12.8333 12 12.8333Z"
                    stroke={props.borderColor}
                    fill={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default LocationPointer;
