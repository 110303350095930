import classNames from "classnames";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";

import ManageWorkflowTemplateWrapper from "./manage-workflow-template-dialog/ManageWorkflowTemplateWrapper";
import header from "components/header/header.scss";
import ToggleablePanel from "components/header/ToggleablePanel";
import PlusIcon from "components/icons/PlusIcon";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import userMenu from "components/user-menu/user-menu.scss";
import style from "components/workflows/workflow-templates/workflow-templates.scss";
import { UserDetails } from "domain/user";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { workflowService } from "services/workflows/WorkflowService";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    user: UserDetails | null;
    onUpdate: () => void;
}

const ManageWorkflowTemplateView: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const [createWorkflowVisibility, setCreateWorkflowVisibility] = React.useState(false);
    const [isSubWorkflow, setIsSubWorkflow] = React.useState(false);

    const showCreateTemplate = () => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW_TEMPLATES,
            action: Action.ADD_WORKFLOW_TEMPLATE,
        });
        setIsSubWorkflow(false);
        setCreateWorkflowVisibility(true);
    };

    const showCreateSubWorkflow = () => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW_TEMPLATES,
            action: Action.ADD_SUB_WORKFLOW_TEMPLATE,
        });
        setIsSubWorkflow(true);
        setCreateWorkflowVisibility(true);
    };

    const menuItems: JSX.Element[] = [
        <MenuItemButton
            key="1"
            tabIndex={0}
            onClick={showCreateTemplate}
            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.createMenu.templateLink}
        >
            {t("workflowTemplatesManager.actions.createTemplate")}
        </MenuItemButton>,
        <MenuItemButton
            key="2"
            tabIndex={0}
            onClick={showCreateSubWorkflow}
            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.createMenu.subWorkflowLink}
        >
            {t("workflowTemplatesManager.actions.createSubWorkflow")}
        </MenuItemButton>,
    ];

    return (
        <>
            <div className={header.innerMenu}>
                <div className={style.createButton}>
                    <ToggleablePanel
                        title={""}
                        icon={
                            <button className={buttons.primaryButton}>
                                <PlusIcon /> {t("workflowTemplatesManager.actions.create.button")}
                            </button>
                        }
                        testId={testIds.workArea.workflowTemplatesManager.primaryView.createMenu.button}
                        menuType={"HEADER"}
                        buttonClass={header.headerMenuButton}
                        hideChevron={true}
                    >
                        <Menu
                            className={classNames(userMenu.menu, header.headerMenuButton)}
                            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.createMenu.itself}
                        >
                            <ul>
                                {menuItems.map((item, key) => (
                                    <li key={key} className={userMenu.item}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </Menu>
                    </ToggleablePanel>
                </div>
            </div>

            <ManageWorkflowTemplateWrapper
                modalTitle={
                    isSubWorkflow
                        ? "ManageWorkflowDialog.title.createSubWorkflow"
                        : "ManageWorkflowDialog.title.createTemplate"
                }
                modalVisibility={createWorkflowVisibility}
                onUpdate={props.onUpdate}
                workflowService={workflowService}
                onShowModal={() => setCreateWorkflowVisibility(false)}
                isCreate={true}
                entityType={isSubWorkflow ? "SUB_WORKFLOW" : "TEMPLATE"}
            />
        </>
    );
};

export default ManageWorkflowTemplateView;
