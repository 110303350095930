import * as React from "react";
import { useTranslation } from "react-i18next";

import Modal from "./Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    open: boolean;
    hide: () => void;
    goBack?: () => void;
    title: string;
    message: React.ReactNode | string;
}

export default function NotificationModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Modal isOpen={props.open} hideModal={props.hide} modalTitle={props.title}>
            <TextBlock>{props.message}</TextBlock>
            <div className={form.buttonContainer}>
                {props.goBack && (
                    <button
                        className={buttons.secondaryButtonWithoutIcon}
                        onClick={props.goBack}
                        data-testid={testIds.common.dialog.goBackButton}
                    >
                        {t("Common.goBack")}
                    </button>
                )}

                <button
                    className={buttons.okOptionalButtonPrimary}
                    onClick={props.hide}
                    data-testid={testIds.common.dialog.closeButton}
                >
                    {t("Common.ok")}
                </button>
            </div>
        </Modal>
    );
}
