import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ApiKey, ApiKeyCursor, ApiKeys } from "domain/apiKeys";
import { apiKeysService } from "services/api-keys/ApiKeysService";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    apiKeysData: ApiKey[];
    cursor: ApiKeyCursor | null;
    scrollPosition?: number;
}
export function AllApiKeysTable(props: {
    scrollPosition?: number;
    initialApiKeyData: ApiKeys;
    count?: number;
}): JSX.Element {
    const { t } = useTranslation();

    const columns: Array<Column<ApiKey>> = [
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.name")} key="1" />,
            accessor: "name",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Common.creationDate")} key="3" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.owner")} key="4" />,
            accessor: "owner",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
    ];

    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [tableState, setTableState] = React.useState<TableState>({
        apiKeysData: props.initialApiKeyData.api_keys,
        cursor: props.initialApiKeyData.cursor,
        scrollPosition: props.scrollPosition,
    });
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        apiKeysService
            .fetchApiKeys(false, abortController, initialLoading ? "" : tableState.cursor?.uuid, search)
            .then((data) => {
                data.apiKeys.api_keys.sort(
                    (a, b) => a.name.localeCompare(b.name) || a.created.localeCompare(b.created)
                );
                setTableState((prevState) => ({
                    ...prevState,
                    apiKeysData: prevState.apiKeysData.concat(data.apiKeys.api_keys),
                    scrollPosition: prevState.apiKeysData.length - 1,
                    cursor: data.apiKeys.cursor,
                }));
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ApiKeys.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setTableState({
            apiKeysData: [],
            cursor: { uuid: "" },
            scrollPosition: 0,
        });
        if (props.initialApiKeyData.api_keys.length > 0) {
            return;
        }
        const abortController = new AbortController();
        fetchData(true);
        return () => {
            abortController.abort();
        };
    }, [props.count, search]);

    let dataCount = null;
    if (tableState.apiKeysData.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: tableState.apiKeysData.length });
    }

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={loading} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.ALL_API_KEYS_TABLE}
                    data={tableState.apiKeysData}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("ApiKeys.emptyStateMessage")}
                />
            </div>
            {tableState.cursor != null &&
                tableState.apiKeysData.length >= 100 &&
                tableState.apiKeysData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={buttonStyle.loadMoreButtonWithoutIcon}
                        onClick={() => {
                            fetchData(false);
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
}
