import * as React from "react";
import { useTranslation } from "react-i18next";

import WorkflowTemplatesCardView from "./WorkflowTemplatesCardView";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import { workflowService } from "services/workflows/WorkflowService";

export default function WorkflowTemplatesView(): JSX.Element {
    const { t } = useTranslation();
    const [count, setCount] = React.useState<number>(0);

    return (
        <ApplicationLayout
            viewTitle={t("Routes.workflowTemplates")}
            view={
                <WorkflowTemplatesCardView
                    search=""
                    workflowService={workflowService}
                    count={count}
                    setCount={setCount}
                />
            }
            mainRoute={WORKFLOWS_ROUTE}
        />
    );
}
