import * as React from "react";
import { useTranslation } from "react-i18next";

import Form from "./Form";
import Modal from "components/modal/Modal";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttonsStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";

import testIds from "testIds.json";

interface State {
    assignModalVisible: boolean;
    successModalVisible: boolean;
}

function AssignKeysView(props: { updateTable: () => void }): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = React.useState<State>({
        assignModalVisible: false,
        successModalVisible: false,
    });

    function setVisibility(assignModalVisible: boolean, successModalVisible: boolean) {
        if (assignModalVisible) {
            usageStatisticsService.sendEvent({
                category: Category.LICENSE,
                action: Action.ASSIGN_LICENSE_KEY,
            });
        }
        setState({ assignModalVisible, successModalVisible });
    }

    function onSuccess() {
        setVisibility(false, true);
    }

    function onFetchLicensesFailure() {
        setVisibility(false, false);
    }

    const assignKeysText = t("Licenses.bms.assignKeys");

    return (
        <>
            <div>
                <button
                    className={buttonsStyle.primaryButtonWithoutIcon}
                    disabled={state.assignModalVisible}
                    onClick={() => setVisibility(true, false)}
                    data-testid={testIds.workArea.license.bms.assignKeysButton}
                >
                    {assignKeysText}
                </button>
            </div>
            <Modal
                isOpen={state.assignModalVisible}
                hideModal={() => setVisibility(false, false)}
                modalTitle={assignKeysText}
            >
                <div className={formStyle.fixedWidthModal}>
                    <Form onSuccess={onSuccess} onFetchLicensesFailure={onFetchLicensesFailure} />
                </div>
            </Modal>
            <Modal
                isOpen={state.successModalVisible}
                hideModal={() => {
                    setVisibility(false, false);
                    props.updateTable();
                }}
                modalTitle={t("Licenses.bms.successTitle")}
            >
                <div className={formStyle.fixedWidthModal}>
                    <div>{t("Licenses.bms.successMessage")}</div>
                </div>
            </Modal>
        </>
    );
}

export default AssignKeysView;
