import ProgressBar from "@ramonak/react-progress-bar";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";

import { createImportFailureReasonLocalizations } from "./failureReason";
import style from "./report-import-notification-details.scss";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import PassedGreenNotificationIcon from "components/icons/PassedGreenNotificationIcon";
import InprogressIndicator from "components/inprogress-indicator/InprogressIndicator";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { ALL_REPORTS_ROUTE } from "components/router/Routes";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import Tooltip from "components/tooltip/Tooltip";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import {
    ImportNotificationData,
    ReportImportJobReport,
    ReportImportJobStatus,
    reportImportService,
} from "services/report/ReportImportService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    reports: ReportImportJobReport[];
    cursor: string[];
    scrollPosition: number;
    totalFetchedReports: number;
}

interface OverallJobStatistics {
    total: number;
    succeeded: number;
    inProgress: number;
    failed: number;
    percentageDone: number;
    status: ReportImportJobStatus | undefined;
}

const ReportImportNotificationDetails = (props: {
    data: ImportNotificationData;
    refreshCount: number;
    setRefreshIcon: (show: boolean) => void;
    onClose: () => void;
}): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const history = useHistory();
    const [loading, setLoading] = React.useState<boolean>();
    const [loadingMore, setLoadingMore] = React.useState<boolean>();
    const [error, setError] = React.useState<string>("");
    const [tableSearchHint, setTableSearchHint] = React.useState<string>();
    const [overallJobStatistics, setOverallJobStatistics] = React.useState<OverallJobStatistics>({
        total: 0,
        succeeded: 0,
        failed: 0,
        inProgress: 0,
        percentageDone: 0,
        status: undefined,
    });
    const [tableState, setTableState] = React.useState<TableState>({
        cursor: [],
        reports: [],
        scrollPosition: 0,
        totalFetchedReports: 0,
    });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const subject = encodeURI(
        "Blancco Management Portal - Unexpected Error while report import, Job id: " + props.data.jobId
    );
    const body = encodeURI(
        "Hi there\n\n" +
            "While importing reports I've experienced issue with the job id: " +
            props.data.jobId +
            "\nWould you be able to assist?\n\n"
    );
    const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    const contactBlanccoSupport = overallJobStatistics.failed > 0 && (
        <span className={style.contactSupportLabel}>
            <a href={mailToLink}>{t("Notification.reportImportResultDialog.contactSupportMessage.contactSupport")} </a>
            {t("Notification.reportImportResultDialog.contactSupportMessage.toResolve")}
        </span>
    );
    const failureReasonLocalizations = createImportFailureReasonLocalizations(t);
    const getReportStatusIcon = (status: string, failureReason = "", uuid: string): JSX.Element => {
        if (status === "EXTRACTED") {
            return (
                <Tooltip key={uuid} content={t("ImportReportsDialog.importReports.importSuccessfulTooltip")}>
                    <span>
                        <PassedGreenNotificationIcon
                            backgroundColor={theme.successIconColor}
                            iconColor={theme.contentBackgroundColor}
                        />
                    </span>
                </Tooltip>
            );
        } else if (status === "VALIDATED" || status === "VERIFIED" || status === "INDEXED") {
            return (
                <Tooltip key={uuid} content={t("ImportReportsDialog.importReports.importInProgressTooltip")}>
                    <span>
                        <InprogressIndicator color={theme.textColor} key={uuid} />
                    </span>
                </Tooltip>
            );
        } else if (status === "FAILED") {
            return (
                <Tooltip key={uuid} content={failureReasonLocalizations.get(failureReason)}>
                    <span>
                        <FailedRedNotificationIcon
                            backgroundColor={theme.errorIconColor}
                            iconColor={theme.contentBackgroundColor}
                        />
                    </span>
                </Tooltip>
            );
        }

        return <></>;
    };

    const resetTable = () => {
        setTableState({
            reports: [],
            cursor: [],
            totalFetchedReports: 0,
            scrollPosition: 0,
        });
    };

    const fetchData = (initialLoading: boolean) => {
        if (!props.data.jobId) {
            return;
        }
        initialLoading ? setLoading(true) : setLoadingMore(true);
        props.setRefreshIcon(false);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportImportService
            .fetchReportImportJob(
                props.data.jobId,
                initialLoading ? [] : tableState.cursor,
                props.data.jobType,
                "NOTIFICATION",
                abortController
            )
            .then((response) => {
                const reports = response.reports;
                setTableState((prevState) => ({
                    ...prevState,
                    reports: prevState.reports.concat(reports),
                    cursor: response.cursor,
                    totalFetchedReports: prevState.totalFetchedReports + reports.length,
                    scrollPosition: prevState.reports.length - 1,
                }));
                setOverallJobStatistics({
                    total: response.totalCount,
                    succeeded: response.succeededCount,
                    inProgress: response.inProgressCount,
                    failed: response.failedCount,
                    percentageDone:
                        response.totalCount === 0
                            ? 0
                            : Math.floor(
                                  ((response.succeededCount + response.failedCount) * 100) / response.totalCount
                              ),
                    status: response.status,
                });
                setTableSearchHint(
                    t("Notification.reportImportResultDialog.searchHint", {
                        dataCount: initialLoading ? reports.length : tableState.totalFetchedReports + reports.length,
                    })
                );
                props.setRefreshIcon(true);
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    if (error.response && error.response.data.toLowerCase().includes("job not found")) {
                        setError(t("Notification.reportImportResultDialog.jobNotFound"));
                    } else {
                        setError(t("Notification.reportImportResultDialog.requestFailed"));
                    }
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setLoadingMore(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        resetTable();
        fetchData(true);
    }, [props.data.jobId, props.refreshCount]);

    const columns: Array<Column<ReportImportJobReport>> = [
        {
            Header: () => <TextWithTooltip text={t("Notification.reportImportResultTable.headers.file")} key="1" />,
            accessor: "filename",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(38, tableContainerRef),
        },
        {
            Header: () => (
                <TextWithTooltip text={t("Notification.reportImportResultTable.headers.reportUuid")} key="2" />
            ),
            accessor: "uuid",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(38, tableContainerRef),
        },
        {
            Header: () => (
                <TextWithTooltip text={t("Notification.reportImportResultTable.headers.importStatus")} key="3" />
            ),
            accessor: "status",
            Cell: (cellInfo) => (
                <div key={cellInfo.cell.row.original.status} className={style.reportStatusColumn}>
                    <span
                        data-testid={testIds.workArea.report.reportResultsDialog.statusIcon}
                        data-type={JSON.stringify({
                            status: cellInfo.cell.row.original.status,
                            error_reason: cellInfo.cell.row.original.failureReason,
                        })}
                    >
                        {getReportStatusIcon(
                            cellInfo.cell.row.original.status,
                            cellInfo.cell.row.original.failureReason,
                            cellInfo.cell.row.original.uuid
                        )}
                    </span>
                </div>
            ),
            width: deriveColumnWidth(18, tableContainerRef),
        },
    ];

    const deriveProgressBarColor = () => {
        switch (overallJobStatistics.status) {
            case "WAITING":
            case "VALIDATED":
            case "IN_PROGRESS":
                return theme.textColor;
            case "DONE":
                return theme.successBorderColor;
            case "DONE_WITH_FAILURES":
                return theme.secondaryWarningBorderColor;
            case "FAILED":
                return theme.errorWrapperBackgroundColor;
            default:
                return theme.textColor;
        }
    };

    const showTable =
        props.data.jobType === "NORMAL" || (props.data.jobType === "BULK" && tableState.reports.length > 0);

    const createProgressBarContent = () => {
        const progressBarLabel =
            overallJobStatistics.total === 0 ? (
                <div className={style.progressBarTopLabelContainer}>{t("Common.pleaseWait")}</div>
            ) : (
                <div className={style.progressBarTopLabelContainer}>
                    <span className={style.progressBarPercentageLabel}>{overallJobStatistics.percentageDone}%</span>{" "}
                    {t("Notification.reportImportResultDialog.progressBarDetails", {
                        processed: overallJobStatistics.succeeded + overallJobStatistics.failed,
                        total: overallJobStatistics.total,
                        failed: overallJobStatistics.failed,
                    })}
                    {contactBlanccoSupport}
                </div>
            );
        return (
            <div className={style.progressBarContainer}>
                {progressBarLabel}
                <ProgressBar
                    height="3px"
                    completed={overallJobStatistics.percentageDone}
                    width={"300px"}
                    isLabelVisible={false}
                    labelColor={deriveProgressBarColor()}
                    bgColor={deriveProgressBarColor()}
                    baseBgColor={theme.contentBorderColor}
                />
            </div>
        );
    };

    return loading ? (
        <LoadingIndicator />
    ) : error ? (
        <div>
            <div>{error}</div>

            <button
                className={classNames(buttonStyle.primaryButtonWithoutIcon, style.closeButton)}
                type="button"
                onClick={() => props.onClose()}
                data-testid={testIds.common.dialog.closeButton}
            >
                {t("Common.close")}
            </button>
        </div>
    ) : (
        <div className={style.container}>
            {createProgressBarContent()}
            <>
                {showTable && (
                    <>
                        <div>{tableSearchHint}</div>
                        <div className={style.tableContainer} ref={tableContainerRef}>
                            <Table
                                tableIdentity={RepositoryKey.REPORT_IMPORT_RESULTS_TABLE}
                                data={tableState ? tableState.reports : []}
                                columns={columns}
                                loading={false}
                                loaded={!initialLoading}
                                failureMessage={error}
                                tooltips={true}
                                emptyMessage={t("Notification.reportImportResultDialog.requestFailed")}
                                scrollTo={tableState.scrollPosition}
                                dialogHeight={350}
                            />
                        </div>
                    </>
                )}

                {tableState.cursor != null &&
                    tableState.reports.length >= 100 &&
                    (loadingMore ? (
                        <LoadingIndicator small={true} />
                    ) : (
                        <div className={style.loadMoreButtonContainer}>
                            <button
                                className={classNames(buttonStyle.loadMoreButtonWithoutIcon, style.loadMoreButton)}
                                onClick={() => {
                                    fetchData(false);
                                }}
                            >
                                {t("Common.loadMore")}
                            </button>
                        </div>
                    ))}
            </>
            <button
                className={classNames(buttonStyle.primaryButtonWithoutIcon, style.viewAllReportsButton)}
                type="button"
                onClick={() => {
                    history.location.pathname === ALL_REPORTS_ROUTE.path
                        ? (window.location.href = ALL_REPORTS_ROUTE.path)
                        : history.push(ALL_REPORTS_ROUTE.path);
                }}
                data-testid={testIds.workArea.report.reportResultsDialog.viewAllReportsButton}
            >
                {t("Notification.reportNotifications.viewAllReportsButton")}
            </button>
        </div>
    );
};

export default ReportImportNotificationDetails;
