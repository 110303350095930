import * as React from "react";
import { useTranslation } from "react-i18next";

import FlexibleModal from "components/modal/FlexibleModal";
import ManageWorkflowDialog from "components/workflows/manage-workflow-dialog/ManageWorkflowDialog";
import { UserDetails } from "domain/user";
import { WorkflowEntityType } from "domain/workflows";
import { EditorGeneration, WorkflowService } from "services/workflows/WorkflowService";

interface Props {
    editorGeneration?: EditorGeneration;
    entityType: WorkflowEntityType;
    modalTitle: string;
    modalVisibility: boolean;
    onShowModal: (doShow: boolean) => void;
    onUpdate: () => void;
    workflowService: WorkflowService;
    isCreate?: boolean;
    isEdit?: boolean;
    name?: string;
    parentWorkflowsCount?: number;
    product?: string;
    tryNewEditor?: boolean;
    updateWorkflowUuid?: string;
    user?: UserDetails | null;
    version?: string;
    workflowUuid?: string;
}

const ManageWorkflowWrapper: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [confirmCancelVisible, setConfirmCancelVisible] = React.useState(false);
    const [confirmCancel, setConfirmCancel] = React.useState(false);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [dynamicSize, setDynamicSize] = React.useState(true);
    const [fullScreen, setFullScreen] = React.useState<boolean>(false);
    const [editorGeneration, setEditorGeneration] = React.useState<string>("");

    const manageDynamicSize = (initial: boolean) => {
        setDynamicSize(initial);
    };

    const updateEditorGeneration = (generation: string) => {
        setEditorGeneration(generation);
        setFullScreen(generation == "V2");
    };

    const hideManageDialog = (doCancel: boolean) => {
        if (doCancel) {
            props.onShowModal(false);
        }
        setConfirmCancelVisible(false);
        setConfirmCancel(false);
    };
    const hideModal = confirmCancel
        ? () => setConfirmCancelVisible(true)
        : () => {
              hideManageDialog(true);
              props.onUpdate();
          };
    const onToggleFullscreen = () => {
        setRefreshCount(refreshCount + 1);
    };

    React.useEffect(() => {
        props.modalVisibility && setDynamicSize(true);
        setFullScreen(false);
        setEditorGeneration("");
    }, [props.modalVisibility]);

    return (
        <>
            <FlexibleModal
                key={1}
                isOpen={props.modalVisibility}
                hideModal={hideModal}
                title={t(props.modalTitle)}
                onToggleFullscreen={onToggleFullscreen}
                dynamicSize={dynamicSize}
                fullScreen={fullScreen || props.editorGeneration === "V2"}
                showHeader={props.isEdit ? props.editorGeneration !== "V2" : editorGeneration !== "V2"}
            >
                <ManageWorkflowDialog
                    confirmCancelVisible={confirmCancelVisible}
                    editorGeneration={props.editorGeneration || editorGeneration}
                    entityType={props.entityType}
                    isCreate={props.isCreate}
                    isEdit={props.isEdit}
                    name={props.name}
                    onCancel={hideManageDialog}
                    onUpdateParentView={props.onUpdate}
                    parentWorkflowsCount={props.parentWorkflowsCount}
                    product={props.product}
                    refreshCount={refreshCount}
                    setDynamicSize={manageDynamicSize}
                    setEditorGeneration={updateEditorGeneration}
                    setShowConfirmCancelDialog={setConfirmCancel}
                    tenantName={props.user?.tenantName}
                    tryNewEditor={props.tryNewEditor}
                    updateWorkflowUuid={props.updateWorkflowUuid}
                    version={props.version}
                    workflowService={props.workflowService}
                    workflowUuid={props.workflowUuid}
                />
            </FlexibleModal>
        </>
    );
};

export default ManageWorkflowWrapper;
