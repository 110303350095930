import classNames from "classnames";
import { TFunctionResult } from "i18next";
import React from "react";
import ReactTooltip from "react-tooltip";

import style from "./text-with-tooltip.scss";
import layoutStyle from "styles/layout.scss";

interface Props<T> {
    text: T;
    multiline?: boolean;
    content?: string;
    /**
     * E.g. {"testid": "some-id"} becomes data-testid="some-id".
     */
    dataItems?: Record<string, string>;
    highlight?: boolean;
    disabled?: boolean;
    focusable?: boolean;
    adjustWidth?: boolean;
}

const TextWithTooltip: React.FunctionComponent<
    Props<string | number | undefined | null | boolean | TFunctionResult>
> = (props): JSX.Element => {
    // Add "data-" prefix to all keys.
    const dataObject = Object.assign.apply(null, [
        {},
        ...Object.entries(props.dataItems ?? {}).map((each) => ({ ["data-" + each[0]]: each[1] })),
    ]);

    const focusProperties = {
        tabIndex: 0,
        onFocus: (event: KeyboardEvent) => ReactTooltip.show(event.target as HTMLElement),
        onBlur: (event: KeyboardEvent) => ReactTooltip.hide(event.target as HTMLElement),
    };

    return (
        <span
            data-html={true}
            data-tip={props.text}
            data-multiline={props.multiline ?? false}
            {...dataObject}
            className={classNames(props.highlight ? style.highlight : "", {
                [layoutStyle.disabledContainer]: props.disabled,
            })}
            {...(props.focusable && focusProperties)}
            onClick={() => {
                ReactTooltip.hide();
            }}
        >
            {props.content ? props.content : props.children ? props.children : props.text}
        </span>
    );
};

export default TextWithTooltip;
