import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import formStyle from "styles/form.scss";

import testIds from "testIds.json";

export interface DeleteDialogProperties {
    modalDisplayed: boolean;
    setModalDisplayed: (display: boolean) => void;
    introductionMessage: string | JSX.Element;
    confirmationMessage: string | JSX.Element;
    handleCommand: () => void;
    title: string;
    loading: boolean;
}

const DeleteConfirmationDialog = (props: DeleteDialogProperties): JSX.Element => {
    const { t } = useTranslation();
    const [disabled, setDisabled] = React.useState(true);
    function handleConfirmation(event: ChangeEvent<HTMLInputElement>) {
        setDisabled(event.target.value !== t("Common.delete").toUpperCase());
    }
    return (
        <Modal
            isOpen={props.modalDisplayed}
            hideModal={() => {
                props.setModalDisplayed(false);
                setDisabled(true);
            }}
            modalTitle={props.title}
        >
            {props.loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <div className={form.deleteContainer}>{props.introductionMessage}</div>
                    {props.confirmationMessage && (
                        <div className={form.deleteContainer}>{props.confirmationMessage}</div>
                    )}
                    <div className={form.deleteContainer}>
                        <input
                            id="confirmInput"
                            type="text"
                            data-testid={testIds.common.confirmationDialog.confirmInput}
                            placeholder={t("Common.delete").toUpperCase()}
                            onChange={(event) => handleConfirmation(event)}
                            className={formStyle.input}
                        />
                    </div>

                    <div className={classNames(buttons.okButtonContainer, form.buttonContainer)}>
                        <button
                            className={buttons.secondaryButtonWithoutIcon}
                            onClick={() => {
                                setDisabled(true);
                                props.setModalDisplayed(false);
                            }}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.cancel")}
                        </button>

                        <button
                            className={classNames(buttons.deleteButton, buttons.primaryButtonWithoutIcon)}
                            data-testid={testIds.common.confirmationDialog.confirmButton}
                            onClick={() => {
                                setDisabled(true);
                                props.handleCommand();
                            }}
                            disabled={disabled}
                        >
                            {t("Common.delete")}
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default DeleteConfirmationDialog;
