import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./screen-analysis-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { DiagnosticReportResponse, Images, MirrorTest } from "domain/reports";
import { formatTimestamp } from "utils/format";
import { logger } from "utils/logging";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";

export interface ScreenAnalysisProps {
    diagnosticReportResponse: DiagnosticReportResponse;
}

export function ScreenAnalysisView(props: ScreenAnalysisProps): JSX.Element {
    const { t } = useTranslation();
    const [diagnosticReportData, setDiagnosticReportData] = React.useState<DiagnosticReportResponse>();
    const [imageUrls, setImageUrls] = React.useState<string[][]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        try {
            setDiagnosticReportData(props.diagnosticReportResponse);
        } catch (e) {
            logger.error(e);
        }
    });

    React.useEffect(() => {
        if (diagnosticReportData?.screenAnalysis?.mirrorTest) {
            const fetchImages = async () => {
                try {
                    const fetchedImageUrls = await Promise.all(
                        diagnosticReportData.screenAnalysis.mirrorTest.map(async (each: MirrorTest) =>
                            Promise.all(
                                each.images.map(async (val: Images) => {
                                    const response = await fetch(val.preSignedUrl);
                                    const contentType = response.headers.get("Content-Type");

                                    if (contentType && contentType.startsWith("image/jpeg")) {
                                        return val.preSignedUrl;
                                    } else {
                                        const base64String = await response.text();
                                        return `data:image/jpeg;base64,${base64String}`;
                                    }
                                })
                            )
                        )
                    );
                    setImageUrls(fetchedImageUrls);
                } catch (error) {
                    logger.error("Error fetching images:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchImages();
        }
    }, [diagnosticReportData]);

    if (typeof diagnosticReportData === "undefined") {
        return <LoadingIndicator />;
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return diagnosticReportData.screenAnalysis.fraudRisk !== "NOT_RUN" &&
        diagnosticReportData.screenAnalysis.fraudRisk !== "" &&
        diagnosticReportData.screenAnalysis.mirrorTest !== undefined ? (
        <>
            <div className={style.mainContainer}>
                <div className={style.tableContainer}>
                    <StaticTable
                        headers={[
                            {
                                className: style.columnHeader,
                                value: t("DiagnosticReportsTable.screenAnalysis.attempt"),
                            },
                            {
                                className: style.columnHeader,
                                value: t("DiagnosticReportsTable.screenAnalysis.faultsFound"),
                            },
                            {
                                className: style.columnHeader,
                                value: t("DiagnosticReportsTable.screenAnalysis.imageUploadTime"),
                            },
                        ]}
                        cells={diagnosticReportData.screenAnalysis.mirrorTest?.map(
                            (value: MirrorTest, index: number) => {
                                const fraudRisk =
                                    typeof value.error !== "undefined"
                                        ? "Error"
                                        : String(
                                              value.results.filter(
                                                  (each) => each.result === "true" || each.result === true
                                              ).length
                                          );

                                return [String(index + 1), fraudRisk, formatTimestamp(value.timestamp)];
                            }
                        )}
                        tableClass={style.table}
                    />
                </div>
                {diagnosticReportData.screenAnalysis.fraudRisk !== "LOW" ? (
                    <div className={style.deviceFlaggedContainer}>
                        <div className={style.modalTitle}>
                            {t("DiagnosticReportsTable.screenAnalysis.deviceFlaggedView.deviceFlagged")}
                        </div>
                        <div className={style.deviceFlaggedDetails}>
                            <div className={style.deviceFlaggedLabel}>
                                {t("DiagnosticReportsTable.screenAnalysis.deviceFlaggedView.fraudRisk")}
                            </div>
                            <div
                                data-testid={testIds.workArea.tenant.viewTenantDialog.generic.uuidLabel}
                                className={style.info}
                            >
                                {diagnosticReportData.screenAnalysis.fraudRisk}
                            </div>

                            <div className={style.deviceFlaggedLabel}>
                                {t("DiagnosticReportsTable.screenAnalysis.deviceFlaggedView.testAttempts")}
                            </div>
                            <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.regionLabel}>
                                {diagnosticReportData.screenAnalysis.mirrorTest.length}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
            {diagnosticReportData.screenAnalysis.mirrorTest?.map((each: MirrorTest, index: number) => (
                <div key={index}>
                    <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                        {t("DiagnosticReportsTable.screenAnalysis.imageView.attempt")}
                        {index + 1}
                    </Heading>
                    <div className={style.screenAnalysisView}>
                        <div className={style.label}>
                            {t("DiagnosticReportsTable.screenAnalysis.imageView.timeDate")}
                        </div>
                        <div data-testid={testIds.workArea.report.diagnostic.reportDialog.screenAnalysis.dateLabel}>
                            {formatTimestamp(each.timestamp)}
                        </div>
                    </div>
                    <div className={style.screenAnalysisView}>
                        <div className={style.label}>{t("DiagnosticReportsTable.screenAnalysis.imageView.result")}</div>
                        <div data-testid={testIds.workArea.report.diagnostic.reportDialog.screenAnalysis.resultLabel}>
                            {typeof each.error !== "undefined" ? (
                                <div className={style.errorMessage}>Error ({each.error})</div>
                            ) : (
                                ""
                            )}
                            {each.results.map((result, index) =>
                                result.result === "false" || result.result === false ? (
                                    <div className={style.result} key={index}>
                                        <img className={style.resultImage} src={checkIcon} />
                                        <div> {t("DiagnosticReportsTable.diagnosticSummary.tests." + result.name)}</div>
                                    </div>
                                ) : "" + result.result === "true" || result.result === true ? (
                                    <div className={style.result} key={index}>
                                        <img className={style.resultImage} src={crossIcon} />
                                        <div> {t("DiagnosticReportsTable.diagnosticSummary.tests." + result.name)}</div>
                                    </div>
                                ) : (
                                    ""
                                )
                            )}
                        </div>
                    </div>
                    <div className={style.imageContainer}>
                        {each.images.map((val: Images, imgIndex: number) => (
                            <div className={style.imageItem} key={imgIndex}>
                                <img className={style.image} src={imageUrls[index]?.[imgIndex] || ""} alt={val.label} />
                                <div>{val.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    ) : (
        <></>
    );
}
