import { create } from "xmlbuilder2";
import { XMLBuilder } from "xmlbuilder2/lib/interfaces";

import {
    REPORT_DESCRIPTION_ENTRIES_NAME,
    REPORT_ENTRY_TYPE_STRING,
    REPORT_ENTRY_TYPE_UINT,
    REPORT_TAG_AUTHOR,
    REPORT_TAG_BLANCCO_DATA,
    REPORT_TAG_DATE,
    REPORT_TAG_DESCRIPTION,
    REPORT_TAG_DOCUMENT_ID,
    REPORT_TAG_DOCUMENT_LOG,
    REPORT_TAG_LOG_ENTRY,
    REPORT_TAG_PRODUCT_NAME,
    REPORT_TAG_PRODUCT_REVISION,
    REPORT_TAG_PRODUCT_VERSION,
    REPORT_TAG_REPORT,
    REPORT_TAG_ROOT,
    REPORT_TAG_USER_DATA,
    ReportEntryAttributeType,
} from "domain/reports";

interface BmpVersion {
    version: string;
    revision: string;
}

function extractBmpVersion(): BmpVersion {
    const value = process.env.BMP_VERSION;
    if (value == null) {
        throw new Error("No BMP version in process env.");
    }
    const pieces = value.split("-");
    return {
        version: pieces[0],
        revision: pieces[2],
    };
}

export type ReportTemplate = "HDD" | "PC" | "MONITOR" | "MOBILE_DEVICE" | "LAPTOP";

/**
 * Derive current date with offset on the current timezone. E.g. 2024-12-31T23:59:59-0700.
 */
function deriveDate(): string {
    function pad(num: number): string {
        return (num < 10 ? "0" : "") + num;
    }

    const date = new Date();
    const datePart = date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
    const timePart = pad(date.getHours()) + ":" + pad(date.getMinutes()) + ":" + pad(date.getSeconds());

    const offset = -date.getTimezoneOffset();
    const offsetPart = pad(Math.floor(Math.abs(offset) / 60)) + pad(Math.abs(offset) % 60);
    const sign = offset >= 0 ? "+" : "-";

    return `${datePart}T${timePart}${sign}${offsetPart}`;
}

export interface ReportValue {
    path: string;
    value: string | number;
}

const BLANCCO_DATA_ELEMENT_TAGS = new Set([
    "blancco_hardware_report",
    "blancco_software_report",
    "blancco_erasure_report",
    "blancco_issue_report",
]);
const ELEMENT_TAGS = new Set(["user_data", ...BLANCCO_DATA_ELEMENT_TAGS]);

const TAG_ENTRY = "entry";
const TAG_ENTRIES = "entries";
const ENTRY_TAGS: Set<string> = new Set([TAG_ENTRY, TAG_ENTRIES]);

const ATTRIBUTE_NAME = "name";

const PRODUCT_NAME = "Blancco Management Portal";
const PRODUCT_ID = "61";

export class ReportCreationService {
    private readonly uuidGenerator: () => string;
    private readonly dateSource: () => string;

    constructor(uuidGenerator: () => string, dateSource: () => string) {
        this.uuidGenerator = uuidGenerator;
        this.dateSource = dateSource;
    }

    createReport(templateName: ReportTemplate, values: ReportValue[]): string {
        const reportElement = create({ version: "1.0" }).ele(REPORT_TAG_ROOT).ele(REPORT_TAG_REPORT);
        values = values.filter((each) => typeof each.value !== "string" || each.value.trim() !== "");
        const logEntry = reportElement
            .ele(REPORT_TAG_BLANCCO_DATA)
            .ele(REPORT_TAG_DESCRIPTION)
            .ele(REPORT_TAG_DOCUMENT_ID)
            .txt(this.uuidGenerator())
            .up()
            .ele(REPORT_TAG_DOCUMENT_LOG)
            .ele(REPORT_TAG_LOG_ENTRY);
        const author = logEntry.ele(REPORT_TAG_AUTHOR);
        author.ele(REPORT_TAG_PRODUCT_NAME, { id: PRODUCT_ID, name: PRODUCT_NAME }).txt(PRODUCT_NAME);
        author.ele(REPORT_TAG_PRODUCT_VERSION).txt(extractBmpVersion().version);
        author.ele(REPORT_TAG_PRODUCT_REVISION).txt(extractBmpVersion().revision);
        logEntry.ele(REPORT_TAG_DATE).txt(this.dateSource());

        for (const each of values) {
            this.addValue(reportElement, each);
        }
        return this.findParentElement(reportElement, REPORT_TAG_USER_DATA + ".fields.usercreatedreport.")
            .ele(TAG_ENTRY, { name: "template", type: "string" })
            .txt(templateName)
            .end();
    }

    private addValue(reportElement: XMLBuilder, reportValue: ReportValue) {
        if (
            reportValue.path.startsWith(REPORT_TAG_BLANCCO_DATA + "." + REPORT_TAG_DESCRIPTION) ||
            reportValue.path.startsWith(REPORT_TAG_USER_DATA)
        ) {
            this.addValueWithFullPath(reportElement, reportValue);
            return;
        }
        if (BLANCCO_DATA_ELEMENT_TAGS.has(this.extractFirstPathElement(reportValue.path))) {
            const adjusted = REPORT_TAG_BLANCCO_DATA + "." + reportValue.path;
            this.addValueWithFullPath(reportElement, { ...reportValue, path: adjusted });
        }
    }

    private addValueWithFullPath(reportElement: XMLBuilder, reportValue: ReportValue) {
        const parent = this.findParentElement(reportElement, reportValue.path);
        parent
            .ele(TAG_ENTRY, {
                name: this.extractLastPathElement(reportValue.path),
                type: this.deriveEntryType(reportValue.value),
            })
            .txt(reportValue.value.toString().trim());
    }

    private deriveEntryType(value: string | number): ReportEntryAttributeType {
        return typeof value === "string" ? REPORT_ENTRY_TYPE_STRING : REPORT_ENTRY_TYPE_UINT;
    }

    /**
     * Find parent element of path and create any missing XML elements along the way.
     *
     * @param reportElement to search.
     * @param path Comma separated path. E.g. with "a.b.c" "a.b" is returned and both "a" and "b" are created if they didn't exist.
     */
    private findParentElement(reportElement: XMLBuilder, path: string): XMLBuilder {
        let current = reportElement;
        const pieces = this.splitPath(path);
        pieces.pop();
        for (const piece of pieces) {
            const candidate = this.findChildNode(current, piece);
            if (candidate != null) {
                current = candidate;
                continue;
            }
            if (ELEMENT_TAGS.has(piece)) {
                current = current.ele(piece);
                continue;
            }
            if (piece === REPORT_DESCRIPTION_ENTRIES_NAME) {
                current = current.ele(TAG_ENTRY, { name: piece });
            } else {
                current = current.ele(TAG_ENTRIES, { name: piece });
            }
        }
        return current;
    }

    /**
     * Find immediate child node of node.
     *
     * @param node Nothing to say. Stupid but linter won't shutup otherwise.
     * @param name The XML tag of the searched node or "name" attribute value if the element is an entry.
     * @return the found node if it was found.
     */
    private findChildNode(node: XMLBuilder, name: string): XMLBuilder | undefined {
        return node.find(
            (node: XMLBuilder) => {
                if (node.node.ELEMENT_NODE !== node.node.nodeType) {
                    return false;
                }
                const tag = node.node.nodeName;
                if (ENTRY_TAGS.has(tag)) {
                    const element: Element = node.node as unknown as Element;
                    const entryName = element.getAttribute(ATTRIBUTE_NAME);
                    if (entryName === name) {
                        return true;
                    }
                }
                return node.node.nodeName === name;
            },
            false,
            false
        );
    }

    private splitPath(path: string): string[] {
        return path.split(".");
    }

    private extractLastPathElement(path: string): string {
        const pieces = this.splitPath(path);
        return pieces[pieces.length - 1];
    }

    private extractFirstPathElement(path: string): string {
        return this.splitPath(path)[0];
    }
}

export const reportCreationService = new ReportCreationService(() => crypto.randomUUID(), deriveDate);
