import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./report-template.scss";
import Info from "components/icons/Info";
import { getHardwareSectionsKeys, getSectionKeys } from "components/reports/report-template/common";
import ReportTemplatePreviewModal from "components/reports/report-template/ReportTemplatePreviewModal";
import Tooltip from "components/tooltip/Tooltip";
import { AUTH_REPORT_TEMPLATE_EDIT } from "domain/authority";
import { BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID, ReportType, TemplateTableData } from "domain/reports";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    uuid: string;
    template: TemplateTableData | undefined;
    onCancel: () => void;
    onEdit: () => void;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ReportTemplate = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const sectionsKeys = getSectionKeys();
    const hardwareSectionKeys = getHardwareSectionsKeys();
    const hasDisclaimer = () => {
        return props.template?.disclaimer ?? false;
    };
    const fetchDisclaimerColor = () => {
        return hasDisclaimer() && props.template?.disclaimerColor
            ? props.template.disclaimerColor
            : props.theme.colorPickerBlack;
    };
    return (
        <div className={style.templateViewContainer}>
            <div className={style.template}>
                <div className={classNames(style.field, style.margin)}>
                    <label>{t("viewTemplate.reportType")}</label>
                    <div className={style.value}>
                        {props.template?.reportType && props.template.reportType === ReportType.STANDARD
                            ? t("ReportTemplate.form.standardReport")
                            : props.template?.reportType === ReportType.ADVANCED &&
                              t("ReportTemplate.form.advancedReport")}

                        {props.template?.reportType && (
                            <Tooltip
                                allowHTML={true}
                                interactive={true}
                                placement={"right"}
                                content={t("ReportTemplate.form.reportInfo")}
                            >
                                <div className={form.infoIcon} tabIndex={0}>
                                    <Info
                                        borderColor={props.theme.contentBackgroundColor}
                                        color={props.theme.iconFillColor}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className={style.templateIncludes}>{t("viewTemplate.templateIncludes")}:</div>
                <div className={style.templateIncludes}>
                    {props.template?.logoVisible && t("viewTemplate.companylogo")}
                </div>
                {hasDisclaimer() ? (
                    <>
                        <div className={classNames(style.field, style.templateIncludes)}>
                            <label>{t("viewTemplate.disclaimer")}</label>
                            <div className={style.value}>{props.template?.disclaimer}</div>
                        </div>
                        <div className={classNames(style.field, style.templateIncludes, style.disclaimerColorLabel)}>
                            <label>{t("ReportTemplate.form.disclaimer.colorPicker")}</label>
                            <div className={style.border}>
                                <div
                                    className={style.colorBox}
                                    style={{ backgroundColor: fetchDisclaimerColor() }}
                                ></div>
                            </div>
                        </div>
                    </>
                ) : null}

                {props.template?.sections.map((section) => {
                    return (
                        <div className={style.templateIncludes} key={section}>
                            {typeof sectionsKeys.get(section) !== "undefined"
                                ? sectionsKeys.get(section)
                                : hardwareSectionKeys.get(section)}
                        </div>
                    );
                })}
            </div>
            {typeof props.template !== "undefined" ? (
                <div>
                    <ReportTemplatePreviewModal
                        previewCounter={1}
                        templateDetails={{
                            uuid: props.template.uuid,
                            logoVisible: props.template.logoVisible,
                            sections: props.template.sections,
                            disclaimer: props.template.disclaimer,
                            reportType: props.template.reportType,
                            templateName: props.template.templateName,
                            disclaimerColor: props.template.disclaimerColor,
                        }}
                    />
                    <div className={form.buttonContainer}>
                        <button
                            type={"button"}
                            className={buttons.secondaryButtonWithoutIcon}
                            data-testid={testIds.workArea.report.erasure.reportTemplate.viewTemplateDialog.cancelButton}
                            onClick={props.onCancel}
                        >
                            {t("Common.cancel")}
                        </button>
                        {!hasTenantCookie() &&
                            props.uuid !== BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID &&
                            userSessionService.userHasAllAuthorities([AUTH_REPORT_TEMPLATE_EDIT]) && (
                                <button
                                    type={"button"}
                                    className={buttons.primaryButtonWithoutIcon}
                                    data-testid={
                                        testIds.workArea.report.erasure.reportTemplate.viewTemplateDialog.editButton
                                    }
                                    onClick={props.onEdit}
                                >
                                    {t("Common.edit")}
                                </button>
                            )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default connector(ReportTemplate);
