import { TFunction } from "i18next";

import { DiagnosticPath } from "./DiagnosticPath";
import { Format } from "components/reports/ErasureReportModal";
import {
    BuyBackAndTradeIn,
    ConditionalQuestions,
    CustomFieldsData,
    CustomFieldsDataDto,
    CustomReportViewFilter,
    CustomReportViewFilterDto,
    CustomReportViewFilterGroup,
    DeviceDetail,
    DiagnosticData,
    DiagnosticReportResponse,
    DiagnosticSummary,
    Estimate,
    FieldsData,
    Insurance,
    isFilterGroup,
    MirrorTest,
    Ntf,
    NtfHistory,
    NtfResult,
    Path,
    PATH_TYPE_ONLY_STRING,
    ScreenAnalysis,
    TestResult,
    toCustomFieldsData,
    toCustomReportViewFilterDto,
    toFieldsDataDto,
} from "domain/reports";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { getLanguage } from "services/language/languageRepository";
import { getOliverUrl } from "services/login/endpointRepository";
import { reportViewService } from "services/report/ReportViewService";
import { ParameterQuery } from "utils/commonFunctions";

interface RequestData {
    cursor?: string[];
    paths: string[];
    size: number;
    search?: string;
    owned: boolean;
    filters?: CustomReportViewFilterDto[];
    /**
     * When this isn't defined, reports are fetched from both indexes: ERASURE and DIAGNOSTIC.
     */
    type?: string;
    sort_column?: string;
    sort_column_type?: string;
    sort_ascending?: boolean;
    start_date?: string;
    end_date?: string;
    filter_logic?: string;
}

interface Entry {
    path: string;
    values: string[] | number[];
}

export type ReportType = "ERASURE" | "DIAGNOSTIC";

export interface Report {
    uuid: string;
    date: string;
    entries: Entry[];
    reportType: ReportType;
    checked?: boolean;
    disabled?: boolean;
}

export interface ReportResponse {
    count: bigint;
    total: bigint;
    cursor: string[];
    reports: Report[];
}

interface DeviceDetailDto {
    id?: number;
    make?: string;
    model?: string;
    modelName?: string;
    color?: string;
    imei?: string;
    imei2?: string;
    serialNumber?: string;
    carrier?: string;
    capacity?: string;
    os?: string;
    osVersion?: string;
    location?: string;
    timezone?: string;
    appVersion?: string;
    tag_code?: string;
    storeName?: string;
}

interface DiagnosticReportHistory {
    PASSED: string[];
    FAILED: string[];
    SKIPPED: string[];
    INDETERMINATE: string[];
    errors?: ReportErrors[];
    report_date: string;
    "Overall-Result": string;
}

interface DiagnosticReport {
    PASSED: string[];
    FAILED: string[];
    SKIPPED: string[];
    INDETERMINATE: string[];
    errors?: ReportErrors[];
    report_date: string;
    "Overall-Result": string;
    history?: DiagnosticReportHistory[];
}

interface ReportErrors {
    name: string;
    reason_code: string;
}

interface QuoteDto {
    date: string;
    trade_id: string;
    eligible: boolean;
    price: number;
    expiration: string;
    currency: string;
    channel: string;
}

interface BuyBackAndTradeInDto {
    estimate?: Estimate;
    quote?: QuoteDto;
}

interface ReportDetailsDto {
    fraud_risk: string;
    "mirror-test": MirrorTest[];
    ntf: NtfDto;
    questions: ConditionalQuestions[];
}

interface NtfResultDto {
    fault_code: string;
    history: NtfHistoryDto[];
}

interface NtfHistoryDto {
    description: string;
    fault_found: boolean;
    report_date: string;
    purchase_date: string;
    reference_code: string;
    reference_code_expiration: string;
}

interface OtherDetailsDto {
    fault_found: boolean;
    reference_code: string;
    reference_code_expiration: string;
}

interface NtfDto {
    results: NtfResultDto[];
    other_details: OtherDetailsDto;
}

interface PriceDto {
    date: string;
    price: number;
    currency: string;
    currencySymbol: string;
    priceType: string;
    expiration: string;
    product_name: string;
    product_code: string;
    punchout: string;
    coverage: string[];
    eligible: boolean;
}

interface InsuranceDto {
    price?: PriceDto;
}

interface DiagnosticsReportDto {
    uuid: string;
    blancco_asset_id: string;
    journey_id: string;
    tenant_uuid: string;
    license_key_identifier: string;
    asset_properties: DeviceDetailDto;
    report: DiagnosticReport;
    bbti: BuyBackAndTradeInDto;
    report_details: ReportDetailsDto;
    insurance: InsuranceDto;
}

const PRODUCTS: Map<string, string> = new Map([
    ["0", "Blancco Demo"],
    ["1", "Blancco Asset Manager"],
    ["2", "BMDE - Device Image Validation"],
    ["3", "Blancco PC Edition"],
    ["4", "Blancco Server Edition"],
    ["5", "Blancco HMG"],
    ["6", "Blancco Network Device Eraser"],
    ["7", "Blancco Stealth (Erasure)"],
    ["8", "Blancco Mobile"],
    ["9", "Blancco Data Centre Edition"],
    ["10", "Blancco Profiler"],
    ["11", "Blancco File Eraser"],
    ["12", "Blancco Removable Media Eraser"],
    ["13", "Blancco SPARC"],
    ["14", "Intelligent Business Routing - Mobile Erasure and Diagnostics Workflows"],
    ["15", "Intelligent Business Routing - Drive Eraser Workflows"],
    ["16", "BMDE - Carrier ID Check"],
    ["17", "BMDE - FMiP Check"],
    ["19", "Blancco PC Edition (per HDD)"],
    ["20", "Blancco Volume Edition (per HDD)"],
    ["21", "Blancco HMG (per HDD)"],
    ["22", "Blancco Mobile Diagnostics"],
    ["23", "Blancco Mobile Reporting Tool"],
    ["24", "Blancco Mobile Diagnostics and Erasure - Carrier Insights Extension"],
    ["25", "Blancco Data Centre Edition (per HDD)"],
    ["26", "Blancco LUN Eraser"],
    ["29", "Blancco SPARC (per HDD)"],
    ["30", "Blancco Mobile Diagnostics and Erasure"],
    ["31", "Blancco Mobile Diagnostics and Erasure - Asset Profiler"],
    ["32", "Blancco Mobile Diagnostics and Erasure - License Reuse"],
    ["33", "Blancco Mobile Diagnostics - Integration"],
    ["34", "Blancco Mobile Diagnostics and Erasure - Diagnostics"],
    ["35", "Blancco Mobile Diagnostics and Erasure - Activation Lock"],
    ["36", "Blancco Drive Eraser - Enterprise Volume Edition"],
    ["37", "Blancco Drive Verifier"],
    ["39", "Blancco Virtual Machine Eraser"],
    ["41", "Blancco Data Centre Edition (per GB)"],
    ["42", "Blancco Check For FeliCa"],
    ["43", "Blancco Mobile For FeliCa"],
    ["45", "Blancco SPARC (per GB)"],
    ["46", "Blancco Mobile Diagnostics and Erasure - Enterprise Edition"],
    ["48", "Degausser"],
    ["49", "Blancco PreInstall"],
    ["50", "Blancco Drive Eraser"],
    ["51", "Blancco Management Console"],
    ["60", "Blancco Management Console Generated Mobile Report"],
    ["61", "Blancco Management Console Generated Computer Report"],
    ["62", "Blancco Cloud Storage Eraser"],
    ["BMS", "Blancco Mobile Solutions"],
]);

const BMS_PRODUCTS: Map<string, string> = new Map([
    ["validation", "Blancco Mobile Solutions - Validation"],
    ["bbti", "Blancco Mobile Solutions - Buy-Back / Trade In"],
    ["insurance", "Blancco Mobile Solutions - Insurance"],
    ["ntf", "Blancco Mobile Solutions - My Device Health"],
    ["lease", "Blancco Mobile Solutions - Lease"],
    ["usdk", "Blancco Mobile Software Development Kit (SDK)"],
]);

const SORTABLE_COLUMNS_WITHOUT_REPORT_PATH = ["uuid", "product"];
const WORD_STARTS_WITH_OPERATOR = "WORD_STARTS_WITH";

const getTestName = (test: string, t: TFunction): string => {
    const testNametranslation = t("DiagnosticReportsTable.diagnosticSummary.tests." + test);
    return testNametranslation === "DiagnosticReportsTable.diagnosticSummary.tests." + test
        ? test
        : testNametranslation;
};

function toDiagnosticReport(dto: DiagnosticsReportDto, t: TFunction): DiagnosticReportResponse {
    const deviceDetail: DeviceDetail = {
        id: dto.blancco_asset_id,
        capacity: dto.asset_properties.capacity,
        carrier: dto.asset_properties.carrier,
        color: dto.asset_properties.color,
        imei: dto.asset_properties.imei,
        imei2: dto.asset_properties.imei2,
        serialNumber: dto.asset_properties.serialNumber,
        location: dto.asset_properties.location,
        make: dto.asset_properties.make,
        model: dto.asset_properties.model,
        modelName: dto.asset_properties.modelName,
        operatingSystem: dto.asset_properties.os + "(" + dto.asset_properties.osVersion + ")",
        softwareVersion: dto.asset_properties.appVersion,
        timezone: dto.asset_properties.timezone,
        tagCode: dto.asset_properties.tag_code,
        licenseKeyIdentifier: dto.license_key_identifier,
        storeName: dto.asset_properties.storeName,
    };

    const quoteData =
        typeof dto.bbti !== "undefined" && typeof dto.bbti.quote !== "undefined"
            ? {
                  eligible: dto.bbti.quote.eligible,
                  price: dto.bbti.quote.price,
                  expiration: dto.bbti.quote.expiration,
                  date: dto.bbti.quote.date,
                  tradeId: dto.bbti.quote.trade_id,
                  currency: dto.bbti.quote.currency,
                  channel: dto.bbti.quote.channel,
              }
            : undefined;

    const buybackTradein: BuyBackAndTradeIn = {
        estimate: typeof dto.bbti !== "undefined" ? dto.bbti.estimate : undefined,
        quote: quoteData,
    };

    const priceData =
        typeof dto.insurance !== "undefined" && typeof dto.insurance.price !== "undefined"
            ? {
                  date: dto.insurance.price.date,
                  price: dto.insurance.price.price,
                  currency: dto.insurance.price.currency,
                  currencySymbol: dto.insurance.price.currencySymbol,
                  priceType: dto.insurance.price.priceType,
                  expiration: dto.insurance.price.expiration,
                  productName: dto.insurance.price.product_name,
                  productCode: dto.insurance.price.product_code,
                  punchout: dto.insurance.price.punchout,
                  coverage: dto.insurance.price.coverage,
                  eligible: dto.insurance.price.eligible,
              }
            : undefined;
    const insurance: Insurance = {
        price: priceData,
    };

    const screenAnalysis: ScreenAnalysis = {
        fraudRisk: dto.report_details ? dto.report_details.fraud_risk : "",
        mirrorTest: dto.report_details ? dto.report_details["mirror-test"] : [],
    };

    const conditionalQuestions: ConditionalQuestions[] = dto.report_details ? dto.report_details.questions : [];

    const diagnosticSummary: DiagnosticSummary[] = [];
    const updateDiagnosticSummary = (key: string, test: string, reportDate: string, reasonCode?: string) => {
        let testAdded = false;
        for (let i = 0; i < diagnosticSummary.length; i++) {
            if (diagnosticSummary[i].test === getTestName(test, t)) {
                switch (key) {
                    case TestResult.PASSED:
                        diagnosticSummary[i].pass = diagnosticSummary[i].pass + 1;
                        diagnosticSummary[i].latestResult = key;
                        break;
                    case TestResult.FAILED:
                        diagnosticSummary[i].fail = diagnosticSummary[i].fail + 1;
                        diagnosticSummary[i].latestResult = key;
                        break;
                    case TestResult.SKIPPED:
                        diagnosticSummary[i].skip = diagnosticSummary[i].skip + 1;
                        diagnosticSummary[i].latestResult = key;
                        break;
                    case TestResult.INDETERMINATE:
                        diagnosticSummary[i].indeterminate = diagnosticSummary[i].indeterminate + 1;
                        diagnosticSummary[i].latestResult = key;
                        break;
                    case TestResult.ERRORS:
                        diagnosticSummary[i].reasonCode = reasonCode;
                        break;
                }

                diagnosticSummary[i].lastAttemptDate = reportDate;
                testAdded = true;
            }
        }
        if (!testAdded) {
            diagnosticSummary.push({
                test: getTestName(test, t),
                pass: key === TestResult.PASSED ? 1 : 0,
                fail: key === TestResult.FAILED ? 1 : 0,
                skip: key === TestResult.SKIPPED ? 1 : 0,
                indeterminate: key === TestResult.INDETERMINATE ? 1 : 0,
                latestResult: key,
                lastAttemptDate: reportDate,
            });
        }
    };
    dto.report.history?.map((history) => {
        history.PASSED.map((test) => {
            updateDiagnosticSummary(TestResult.PASSED, test, history.report_date);
        });

        history.FAILED.map((test) => {
            updateDiagnosticSummary(TestResult.FAILED, test, history.report_date);
        });

        history.SKIPPED.map((test) => {
            updateDiagnosticSummary(TestResult.SKIPPED, test, history.report_date);
        });

        history.INDETERMINATE.map((test) => {
            updateDiagnosticSummary(TestResult.INDETERMINATE, test, history.report_date);
        });
        if (history.errors) {
            history.errors.map((reportErrors) => {
                updateDiagnosticSummary(
                    TestResult.ERRORS,
                    reportErrors.name,
                    history.report_date,
                    reportErrors.reason_code
                );
            });
        }
    });

    dto.report.PASSED.map((test) => {
        updateDiagnosticSummary(TestResult.PASSED, test, dto.report.report_date);
    });
    dto.report.FAILED.map((test) => {
        updateDiagnosticSummary(TestResult.FAILED, test, dto.report.report_date);
    });
    dto.report.SKIPPED.map((test) => {
        updateDiagnosticSummary(TestResult.SKIPPED, test, dto.report.report_date);
    });
    dto.report.INDETERMINATE.map((test) => {
        updateDiagnosticSummary(TestResult.INDETERMINATE, test, dto.report.report_date);
    });
    if (dto.report.errors) {
        dto.report.errors.map((reportErrors) => {
            updateDiagnosticSummary(
                TestResult.ERRORS,
                reportErrors.name,
                dto.report.report_date,
                reportErrors.reason_code
            );
        });
    }

    diagnosticSummary.sort((a, b) => (a.test > b.test ? 1 : -1));

    const otherDetails = dto.report_details?.ntf?.other_details
        ? {
              faultFound: dto.report_details?.ntf?.other_details.fault_found,
              referenceCode: dto.report_details?.ntf?.other_details.reference_code,
              store: dto.license_key_identifier,
          }
        : {
              faultFound: false,
              referenceCode: "",
              store: "",
          };

    const results: NtfResult[] = [];
    dto.report_details?.ntf?.results?.forEach((result) => {
        const history: NtfHistory[] = result.history.map((history) => ({
            description: history.description,
            faultFound: history.fault_found,
            reportDate: history.report_date,
            referenceCode: history.reference_code,
        }));
        results.push({
            faultCode: result.fault_code,
            history: history,
        });
    });

    const ntf: Ntf = {
        results: results,
        otherDetails: otherDetails,
    };

    return {
        deviceDetail,
        diagnosticSummary,
        conditionalQuestions,
        buybackTradein,
        screenAnalysis,
        insurance,
        ntf,
    };
}

interface CommonRequest {
    startDate?: string;
    endDate?: string;
    search?: string;
    filters?: CustomReportViewFilterGroup;
}

export interface FetchReportsRequest extends CommonRequest {
    abortController?: AbortController;
    cursor?: string[];
    sortColumn?: string;
    sortAscending: boolean;
    paths: string[];
}

export interface BulkReportDeletionRequest {
    reportUuids?: string[];
}

export interface FetchAllReportsService {
    fetchAllReports(request: FetchReportsRequest): Promise<ReportResponse>;
}

export function deriveProductName(productId: string): string {
    // We don't check for empty productId. The way this function is now used, we wouldn't throw an exception anyway if,
    // for some reason, an empty productId would be passed. That probably wouldn't be enough to crash the entire UI.
    const defaultName = `Product ${productId}`;
    const name = PRODUCTS.get(productId);
    return name ?? defaultName;
}

export function replacePaths(paths: string[] | undefined, exportFormat?: string): string[] {
    // For whatever reason we started to use these simple strings as
    // column names here in the UI even thou there's different names in
    // the backend and it's been true for a long time. So now we need to
    // translate before sending them to the backend.
    if (typeof paths === "undefined") {
        return [];
    }
    const checkFormat =
        exportFormat == Format.CSV ||
        exportFormat == Format.SUMMARY_CSV ||
        exportFormat == Format.SUMMARY_PDF ||
        exportFormat == Format.VIEW_ALL;
    const replacedPaths = new Map<string, string>([
        ["product", checkFormat ? Path.PRODUCT_NAME : Path.PRODUCT_ID],
        ["uuid", Path.UUID],
        ["date", Path.REPORT_DATE],
    ]);
    return paths
        .map((each) => replacedPaths.get(each) || each)
        .map((each) => each.replace(/^asset\./, "asset_properties."));
}

// TODO BCC-580 Split into ErasureReportService and DiagnosticsReportService

function createDiagnosticReportFetchUrl(uuid: string, languageCode?: string, format?: string) {
    const parameterQuery = new ParameterQuery();
    parameterQuery.add("language", languageCode);
    parameterQuery.add("format", format);
    return getOliverUrl() + "/api/v1/reports/diagnostics/" + uuid + parameterQuery.createQueryString();
}

function extractFilename(disposition: string | null) {
    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            const contentDispositionFileName = matches[1].replace(/['"]/g, "");
            return contentDispositionFileName;
        }
    }
    return "File path not found.";
}

export interface PreparedFilterDto {
    filters: CustomReportViewFilter[];
    logic: string;
}

export function prepareFilters(filters?: CustomReportViewFilterGroup, index = 0): PreparedFilterDto | undefined {
    if (filters == null || filters.filters == null || filters.filters.length === 0) {
        return;
    }
    const modifiedFilters: CustomReportViewFilter[] = [];
    const logicPieces: string[] = [];
    for (const each of filters.filters) {
        if (logicPieces.length > 0) {
            if (each.logicOperator == null) {
                throw new Error(`Logic operator isn't defined. It shouldn't be. Filter: ${each}.`);
            }
            logicPieces.push(each.logicOperator);
        }
        if (isFilterGroup(each)) {
            const nested = prepareFilters(each, index);
            if (nested == null) {
                continue;
            }
            modifiedFilters.push(...nested.filters);
            logicPieces.push(...["(", nested.logic, ")"]);
            index += nested.filters.length;
            continue;
        }
        // In order to have BMS filter added to the product ID lists, the filter needs to be
        // altered. A filter with product_id = BMS will return nothing which is why the filter
        // needs to be changed as follows, so that the view will return all BMS products.
        if (each.name === Path.PRODUCT_ID) {
            if (each.value === "BMS") {
                modifiedFilters.push({
                    name: "journey_type",
                    value: "true",
                    operator: "EXIST",
                    pathType: "KEYWORD",
                });
            } else if (each.operator === WORD_STARTS_WITH_OPERATOR) {
                modifiedFilters.push({
                    name: each.name,
                    value: each.value,
                    operator: "MATCH",
                    pathType: each.pathType,
                });
            } else {
                modifiedFilters.push(each);
            }
        } else {
            modifiedFilters.push(each);
        }
        logicPieces.push(String(index++));
    }
    return {
        filters: modifiedFilters,
        logic: logicPieces.join(" "),
    };
}

export class ReportService implements FetchAllReportsService {
    private static extractErasureProductName(report: Report): string {
        const names: string[] = this.extractPathValues(Path.PRODUCT_NAME, report.entries);
        return this.toCommaSeparatedString(names);
    }

    public static extractProductName(report: Report): string {
        if (report.reportType === "ERASURE") {
            return this.extractErasureProductName(report);
        }
        if (report.reportType === "DIAGNOSTIC") {
            return this.extractDiagnosticProductName(report);
        }
        return "";
    }

    private static extractPathValues(path: string, entries: Entry[]): string[] {
        return entries
            .filter((entry) => entry.path === path)
            .map((entry) => entry.values)
            .flat()
            .map((each) => each.toString());
    }

    private static extractDiagnosticProductName(report: Report): string {
        const names: string[] = this.extractPathValues(DiagnosticPath.JOURNEY_TYPE, report.entries);
        return this.extractDiagnosticFeatureFromMap(
            new Map<DiagnosticPath, string[]>([[DiagnosticPath.JOURNEY_TYPE, names]])
        );
    }

    public static extractDiagnosticFeatureFromMap(pathToEntries: Map<DiagnosticPath, string[]>): string {
        return this.toCommaSeparatedString(pathToEntries.get(DiagnosticPath.JOURNEY_TYPE), (value) => {
            const feature = BMS_PRODUCTS.get(value);
            if (feature == null) {
                return "";
            }
            return feature;
        });
    }

    public static toDiagnosticData(report: Report): DiagnosticData {
        // Here any given entry's numeric handling doesn't appear to be
        // relevant so they're all converted to string. Also, even if null is
        // possible we don't want to deal with it any further so an empty
        // string will do.
        const pathToEntryValues: Map<DiagnosticPath, string[]> = report.entries.reduce(
            (map, entry) =>
                map.set(
                    entry.path,
                    entry.values.map((each) => (each != null ? each.toString() : ""))
                ),
            new Map()
        );
        return {
            journeyType: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.JOURNEY_TYPE)),
            feature: this.extractDiagnosticFeatureFromMap(pathToEntryValues),
            audioEarpiece: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_EARPIECE)),
            audioHeadphone: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_HEADPHONE)),
            audioHeadsetMicrophone: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_HEADSET_MICROPHONE)
            ),
            audioMicrophone: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_MICROPHONE)),
            audioMicrophoneRecording: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_MICROPHONE_RECORDING)
            ),
            audioNoiseCancellation: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_NOISE_CANCELLATION)
            ),
            audioSpeaker: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_SPEAKER)),
            audioSpeakerMicrophone: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_SPEAKER_MICROPHONE)
            ),
            batteryCharge: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_CHARGE)),
            batteryChargeHold: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_CHARGE_HOLD)),
            batteryDrain: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_DRAIN)),
            batteryHealth: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_HEALTH)),
            batteryTemperature: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_TEMPERATURE)),
            buttonBack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_BACK)),
            buttonHome: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_HOME)),
            buttonMute: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_MUTE)),
            buttonPower: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_POWER)),
            buttonRecentApp: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_RECENT_APP)),
            buttonSide: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_SIDE)),
            buttonVolumeDown: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_VOLUME_DOWN)),
            buttonVolumeUp: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_VOLUME_UP)),
            cameraBack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK)),
            cameraBackAuto: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK_AUTO)),
            cameraBackFlash: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK_FLASH)),
            cameraBackVideo: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK_VIDEO)),
            cameraFront: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT)),
            cameraFrontAuto: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT_AUTO)),
            cameraFrontFlash: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT_FLASH)),
            cameraFrontVideo: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT_VIDEO)),
            cameraMultiCheck: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_MULTI_CHECK)),
            carrierSignalCheckOne: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.CARRIER_SIGNAL_CHECK_ONE)
            ),
            carrierSignalCheckTwo: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.CARRIER_SIGNAL_CHECK_TWO)
            ),
            detectBluetooth: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DETECT_BLUETOOTH)),
            detectNfc: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DETECT_NFC)),
            detectSim: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DETECT_SIM)),
            deviceAutoRotate: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_AUTO_ROTATE)),
            deviceFmRadio: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_FM_RADIO)),
            deviceLcdBacklight: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_LCD_BACKLIGHT)),
            deviceOtg: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_OTG)),
            devicePerformance: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_PERFORMANCE)),
            deviceSdCard: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_SD_CARD)),
            deviceUsb: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_USB)),
            deviceVibration: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_VIBRATION)),
            displayBlackInkSpots: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.DISPLAY_BLACK_INK_SPOTS)
            ),
            displayColouredVerticalLines: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.DISPLAY_COLOURED_VERTICAL_LINES)
            ),
            displayCrack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_CRACK)),
            displayDragGesture: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_DRAG_GESTURE)),
            displayGhostImages: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_GHOST_IMAGES)),
            displayLcdColor: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_LCD_COLOR)),
            displayLcdPink: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_LCD_PINK)),
            displayLcdStarring: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_LCD_STARRING)),
            displayMultiTouch: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_MULTI_TOUCH)),
            displayPixel: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_PIXEL)),
            displayPressure: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_PRESSURE)),
            displayPurpleInkStain: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.DISPLAY_PURPLE_INK_STAIN)
            ),
            displayTouch: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_TOUCH)),
            enclosureBack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.ENCLOSURE_BACK)),
            faceId: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.FACE_ID)),
            journeyId: parseInt(this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.JOURNEY_ID))),
            liveCall: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.LIVE_CALL)),
            longAssisstedQuickCheck: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.LONG_ASSISSTED_QUICK_CHECK)
            ),
            longGroupTestQuickCheck: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.LONG_GROUP_TEST_QUICK_CHECK)
            ),
            longStress: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.LONG_STRESS)),
            make: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.MAKE)),
            model: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.MODEL)),
            modelName: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.MODEL_NAME)),
            networkCellular: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.NETWORK_CELLULAR)),
            networkWifi: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.NETWORK_WIFI)),
            passcodeStatus: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.PASSCODE_STATUS)),
            sensorAccelerometer: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.SENSOR_ACCELEROMETER)
            ),
            sensorAltimeter: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_ALTIMETER)),
            sensorBarometer: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_BAROMETER)),
            sensorCompass: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_COMPASS)),
            sensorFingerprint: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_FINGERPRINT)),
            sensorGyroscope: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_GYROSCOPE)),
            sensorHall: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_HALL)),
            sensorHeartRate: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_HEART_RATE)),
            sensorInfrared: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_INFRARED)),
            sensorIris: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_IRIS)),
            sensorLight: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_LIGHT)),
            sensorMagnetometer: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_MAGNETOMETER)),
            sensorProximity: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_PROXIMITY)),
            sensorThreeDimenTouch: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.SENSOR_THREE_DIMEN_TOUCH)
            ),
            signalAgps: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SIGNAL_AGPS)),
            signalGps: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SIGNAL_GPS)),
            testPassRate: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.TEST_PASS_RATE)),
            uuid: report.uuid,
            reportDate: report.date,
        };
    }

    private static toCommaSeparatedString(
        values: string[] = [],
        callback: (value: string) => string = (value) => value
    ): string {
        return values.map((value) => callback(value)).join(", ");
    }

    public fetchAllReports(request: FetchReportsRequest): Promise<ReportResponse> {
        let sort_column_type: string | undefined;
        if (request.sortColumn != null) {
            sort_column_type = reportViewService.getPaths().find((each) => each.path === request.sortColumn)?.pathType;
            if (sort_column_type === "LONG") {
                sort_column_type = "UINT";
            } else if (
                sort_column_type === undefined &&
                !SORTABLE_COLUMNS_WITHOUT_REPORT_PATH.includes(request.sortColumn)
            ) {
                request.sortColumn = undefined;
            }
        }
        if (sort_column_type === PATH_TYPE_ONLY_STRING) {
            throw Error(`Can't sort with path ${request.sortColumn} because it's type is ${sort_column_type}`);
        }

        const requestData: RequestData = {
            size: 100,
            paths: Array.from(
                new Set([
                    DiagnosticPath.JOURNEY_TYPE,
                    DiagnosticPath.AUDIO_EARPIECE,
                    DiagnosticPath.CAMERA_FRONT,
                    DiagnosticPath.JOURNEY_ID,
                    DiagnosticPath.MAKE,
                    DiagnosticPath.MODEL_NAME,
                    DiagnosticPath.MODEL,
                    DiagnosticPath.TEST_PASS_RATE,
                    ...replacePaths(request.paths, Format.VIEW_ALL),
                ])
            ),
            cursor: request.cursor,
            owned: false,
            sort_column: request.sortColumn,
            sort_ascending: request.sortAscending,
            sort_column_type,
            start_date: request.startDate,
            end_date: request.endDate,
        };
        const search = request.search != null ? request.search.trim() : "";
        if (search !== "") {
            requestData.search = search;
        }

        const preparedFilterDto = prepareFilters(request.filters);
        if (preparedFilterDto != null && preparedFilterDto.filters.length > 0) {
            requestData.filters = preparedFilterDto.filters
                .map(toCustomReportViewFilterDto)
                .map((each) => ({ ...each, logic_operator: undefined }));
            requestData.filter_logic = preparedFilterDto.logic;
        }
        const url = getOliverUrl() + "/api/v1/reports/view";

        const init: RequestInit = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
            signal: request.abortController !== undefined ? request.abortController.signal : undefined,
        };

        return apiGatewayService
            .fetch(url, init)
            .then((response) => {
                if (200 <= response.status && response.status < 300) {
                    return response;
                }
                if ([401, 403].includes(response.status)) {
                    return apiGatewayService
                        .invokeApi("/authentication/refresh", "get", null, {
                            abortController: request.abortController,
                            refreshSession: false,
                        })
                        .then(() => {
                            return apiGatewayService.fetch(url, init);
                        })
                        .catch(() => {
                            window.location.replace("/logout");
                        });
                }
                return Promise.reject();
            })
            .then((response) => {
                if (response != null) {
                    return response.json() as Promise<ReportResponse>;
                }
                return Promise.reject();
            });
    }

    public fetchReportCustomFields(uuid: string, abortController?: AbortController): Promise<CustomFieldsData> {
        return apiGatewayService
            .invokeApi("/api/v1/reports/" + uuid + "/custom_fields", "GET", null, {
                abortController: abortController,
                apiType: ApiType.OLIVER,
            })
            .then((response: CustomFieldsDataDto) => toCustomFieldsData(response));
    }

    public editReportByUuid(uuid: string, postData: FieldsData, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/v1/reports/" + uuid + "/edit", "POST", toFieldsDataDto(postData), {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public diagnosticReports(
        uuid: string,
        t: TFunction,
        abortController?: AbortController,
        refreshSession?: boolean
    ): Promise<DiagnosticReportResponse> {
        refreshSession = refreshSession ?? true;
        const url = createDiagnosticReportFetchUrl(uuid);
        const init: RequestInit = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            signal: abortController !== undefined ? abortController.signal : undefined,
        };
        return apiGatewayService
            .fetch(url, init)
            .then((response) => {
                if (refreshSession && [401, 403].includes(response.status)) {
                    return apiGatewayService
                        .invokeApi("/authentication/refresh", "get", null, {
                            abortController,
                            refreshSession: false,
                        })
                        .then(() => {
                            return apiGatewayService.fetch(url, init);
                        })
                        .catch(() => {
                            window.location.replace("/logout");
                        });
                }

                return response;
            })
            .then((response: Response) => response.json())
            .then((dto: DiagnosticsReportDto) => toDiagnosticReport(dto, t));
    }

    public exportSingleDiagnosticReport(
        reportUuid: string,
        filenameColumns?: string[],
        separator?: string
    ): Promise<void | Response> {
        const languageCode = getLanguage().code;
        const abortController = new AbortController();
        const url = createDiagnosticReportFetchUrl(reportUuid, languageCode, "csv");
        let postData = {};
        if (filenameColumns?.length != 0 && filenameColumns != undefined) {
            const fileNameData = {
                filename_columns: replacePaths(filenameColumns),
                filename_separator: separator,
            };
            postData = { ...postData, ...fileNameData };
        }
        const init: RequestInit = {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            signal: abortController.signal,
            body: JSON.stringify(postData),
        };
        return apiGatewayService.fetch(url, init).then((response) => {
            if ([401, 403].includes(response.status)) {
                return apiGatewayService
                    .invokeApi("/authentication/refresh", "get", null, {
                        abortController,
                        refreshSession: false,
                    })
                    .then(fetch)
                    .catch(() => {
                        window.location.replace("/logout");
                    });
            }
            const disposition = response.headers.get("Content-Disposition");
            const downloadFilename = extractFilename(disposition);
            response.blob().then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", downloadFilename);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            });
        });
    }

    public sendErasureReportEmail(
        uuid: string,
        emailAddress: string,
        format: Format | undefined,
        templateUuid: string,
        language: string,
        subject: string,
        emailBody: string,
        abortController: AbortController
    ): Promise<void> {
        const postData = {
            email_address: emailAddress,
            format,
            template_uuid: templateUuid,
            language,
            subject,
            email_body: emailBody,
        };
        return apiGatewayService.invokeApi("/api/reports/" + uuid + "/email", "POST", postData, {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            refreshSession: true,
            emptyResponse: true,
        });
    }
}

export const reportService = new ReportService();
