import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import tableStyle from "./manage-user-groups.scss";
import { Groups } from "./ManageUserGroupsForm";
import SelectedUsersList from "./SelectedUsersList";
import UserList from "./UserList";
import LeftArrow from "components/icons/LeftArrow";
import RightArrow from "components/icons/RightArrow";
import { TableData } from "domain/table";
import { Group, UserTableData } from "domain/users";
import { userService } from "services/user/users/UserService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";

import testIds from "testIds.json";

export const USER_GROUP_COUNT_LIMIT = 10;
export const SELECTED_USERS_MAX_COUNT = 100;

interface TableState {
    usersData: UserTableData[];
    cursor: string;
    scrollPosition?: number;
}

export interface FetchedUserTableData extends TableData {
    uuid: string;
    name: string;
    enabled: boolean;
    createdDate: string;
    expirationDate: string;
    groups: Group[];
}

export interface Props {
    count: number;
    groups: Groups[] | undefined;
    setFieldValue: (field: string, value: string[], shouldValidate?: boolean | undefined) => void;
    groupUsers?: FetchedUserTableData[];
    isFormSubmitting: boolean;
}

const ManageUserTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        usersData: [],
        cursor: "",
        scrollPosition: 0,
    });
    const abortController = new AbortController();
    const [selectedUsers, setSelectedUsers] = React.useState<FetchedUserTableData[]>([]);
    const [removeUsersUuids, setRemoveUsersUuids] = React.useState<string[]>([]);
    const [selectedUsersSearch, setSelectedUsersSearch] = React.useState<string>("");
    const [finalRightList, setFinalRightList] = React.useState<FetchedUserTableData[]>(props.groupUsers || []);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [disableAdd, setDisableAdd] = React.useState<boolean>(selectedUsers.length <= 0);
    const [disableRemove, setDisableRemove] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [initialUsersTableLoading, setInitialUsersTableLoading] = React.useState<boolean>(true);
    const [loadMore, setLoadMore] = React.useState<boolean>(false);
    const [searchText, setSearchText] = React.useState("");
    const [searchGroup, setSearchGroup] = React.useState("");
    const [selectedUserListLoading, setSelectedUserListLoading] = React.useState<boolean>(false);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [restrictedUserUuids, setRestrictedUserUuids] = React.useState<Set<string>>(new Set());

    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const setUser = (checked: boolean, uuid: string) => {
        if (uuid != "all") {
            if (checked) {
                setSelectedUsers((prev) => [...prev, ...tableState.usersData.filter((user) => user.uuid == uuid)]);
            } else {
                setSelectedUsers((prev) => prev.filter((each) => each.uuid != uuid));
            }
        } else {
            if (checked) {
                const userUuids: FetchedUserTableData[] = [];
                tableState.usersData.forEach((each) => {
                    if (each.groups.length >= USER_GROUP_COUNT_LIMIT) {
                        return;
                    }
                    const element = document.getElementById("left_" + each.uuid) as HTMLInputElement;
                    if (each.name.includes(selectedUsersSearch) && element && !element.checked) {
                        userUuids.push(each);
                    }
                });
                setSelectedUsers((prev) => [...prev, ...userUuids]);
            } else {
                setSelectedUsers([]);
            }
        }
    };

    const removeDuplicateUsers = (
        previousUserData: UserTableData[],
        currentUserData: UserTableData[]
    ): UserTableData[] => {
        const previousEmailIds = new Set(previousUserData.map(({ email }) => email));
        return [...previousUserData, ...currentUserData.filter(({ email }) => !previousEmailIds.has(email))];
    };

    const fetchData = (abortController: AbortController, initialLoading: boolean, cursor: boolean) => {
        setLoading(true);
        setInitialUsersTableLoading(initialLoading);
        userService
            .fetchUsers(abortController, searchText, cursor ? tableState.cursor : "", searchGroup)
            .then((data) => {
                const restricted = data.userTableData
                    .filter((user) => user.groups.length >= USER_GROUP_COUNT_LIMIT)
                    .map((user) => user.uuid);
                setRestrictedUserUuids((prevState) => {
                    const newRestrictedUserUuids = new Set(prevState);
                    restricted.forEach((uuid) => {
                        newRestrictedUserUuids.add(uuid);
                    });
                    return newRestrictedUserUuids;
                });
                setTableState((previousState) => ({
                    scrollPosition: previousState.usersData.length - 1,
                    usersData: removeDuplicateUsers(previousState.usersData, data.userTableData),
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
                setLoadMore(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("UsersTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialUsersTableLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setFinalRightList(props.groupUsers || []);
    }, [props.groupUsers]);

    React.useEffect(() => {
        setDisableAdd(true);
        setDisableRemove(true);
    }, [props.isFormSubmitting]);

    React.useEffect(() => {
        setTableState({ usersData: [], cursor: "", scrollPosition: 0 });
        fetchData(abortController, true, false);
        return () => {
            abortController.abort();
        };
    }, [props.count, searchText, searchGroup]);

    React.useEffect(() => {
        if (loadMore) {
            fetchData(abortController, true, true);
        }
        return () => {
            abortController.abort();
        };
    }, [loadMore]);

    React.useEffect(() => {
        setDisableAdd(selectedUsers.length == 0 || selectedUsers.length > SELECTED_USERS_MAX_COUNT);
        return () => {
            abortController.abort();
        };
    }, [selectedUsers]);

    React.useEffect(() => {
        setDisableRemove(removeUsersUuids.length == 0);
    }, [removeUsersUuids]);

    const updateData = () => {
        setSelectedUserListLoading(true);
        setFinalRightList((prev) => [...prev, ...selectedUsers]);
        setSelectedUsers([]);
    };

    const removeUserFromSelectedUserList = (checked: boolean, uuid: string) => {
        if (uuid != "all") {
            if (checked) {
                setRemoveUsersUuids((prev) => [...prev, uuid]);
            } else {
                setRemoveUsersUuids((prev) => prev.filter((each) => each != uuid));
            }
        } else {
            if (checked) {
                setRemoveUsersUuids(finalRightList.map((user) => user.uuid));
            } else {
                setRemoveUsersUuids([]);
                setSelectedUsers([]);
            }
        }
    };

    const removeSelectedUsers = () => {
        setSelectedUserListLoading(true);
        setFinalRightList((prev) => prev.filter((each) => !removeUsersUuids.includes(each.uuid)));
        removeUsersUuids.forEach((uuid) => {
            const element = document.getElementById("left_" + uuid) as HTMLInputElement;
            if (element) {
                element.checked = false;
                element.disabled = false;
            }
        });
        setRemoveUsersUuids([]);
        setSelectedUserListLoading(false);
    };

    React.useEffect(() => {
        props.setFieldValue(
            "usersList",
            finalRightList.map((user) => user.uuid)
        );
    }, [finalRightList]);

    return (
        <>
            <div className={tableStyle.wrapper}>
                <UserList
                    groups={props.groups}
                    count={props.count}
                    setSelectedUserList={setUser}
                    selectedUsers={selectedUsers}
                    loading={loading}
                    tableState={tableState}
                    setSearchGroup={setSearchGroup}
                    setSearchText={setSearchText}
                    initialUsersTableLoading={initialUsersTableLoading}
                    finalRightList={finalRightList}
                    requestFailureMessage={requestFailureMessage}
                    disabled={!disableRemove}
                    setLoadMore={setLoadMore}
                    restrictedUserUuids={restrictedUserUuids}
                />
                <div className={tableStyle.selectButtonContainer}>
                    <button
                        className={classNames(buttonStyle.primaryButtonWithoutIcon, tableStyle.selectButton)}
                        disabled={disableAdd}
                        onClick={(e) => {
                            e.preventDefault();
                            updateData();
                        }}
                        data-testid={testIds.common.primaryView.table.selectAllCheckbox}
                    >
                        <div className={tableStyle.okButtonContainer}>
                            {t("UserGroups.manageUserGroupsView.selectedUsers.add")}
                            <div className={tableStyle.buttonArrow}>
                                <RightArrow color={disableAdd ? theme.textColor : theme.primaryButtonForegroundColor} />
                            </div>
                        </div>
                    </button>
                    <button
                        className={classNames(buttonStyle.primaryButtonWithoutIcon, tableStyle.selectButton)}
                        disabled={disableRemove}
                        onClick={(e) => {
                            e.preventDefault();
                            removeSelectedUsers();
                        }}
                        data-testid={testIds.common.primaryView.table.selectRowCheckbox}
                    >
                        <div className={tableStyle.okButtonContainer}>
                            <div className={tableStyle.buttonArrow}>
                                <LeftArrow
                                    color={disableRemove ? theme.textColor : theme.primaryButtonForegroundColor}
                                />
                            </div>
                            {t("UserGroups.manageUserGroupsView.selectedUsers.remove")}
                        </div>
                    </button>
                </div>

                <SelectedUsersList
                    setSelectedUserListLoading={setSelectedUserListLoading}
                    loading={selectedUserListLoading}
                    finalRightList={finalRightList}
                    removeUserFromSelectedUserList={removeUserFromSelectedUserList}
                    setSelectedUsersSearch={(value: string) => setSelectedUsersSearch(value)}
                    selectedUsersSearch={selectedUsersSearch}
                    inputRef={inputRef}
                    removeUsersUuids={removeUsersUuids}
                    disabled={!disableAdd}
                />
            </div>
        </>
    );
};
export default ManageUserTable;
