import classNames from "classnames";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import workflowStyle from "./workflow-templates.scss";
import style from "./workflow-templates.scss";
import header from "components/header/header.scss";
import ToggleablePanel from "components/header/ToggleablePanel";
import Import from "components/icons/Import";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import userMenu from "components/user-menu/user-menu.scss";
import ImportWorkflowsDialog from "components/workflows/ImportWorkflowsDialog";
import { UserDetails } from "domain/user";
import { templateService } from "services/workflows/TemplateService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    user: UserDetails | null;
    onUpdate: () => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ImportWorkflowTemplateView: React.FunctionComponent<Props> = (
    props: Props & ConnectedProps<typeof connector>
) => {
    const { t } = useTranslation();

    const colourTheme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [isSubWorkflow, setIsSubWorkflow] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState<string>(
        t("workflowTemplatesManager.ImportWorkflowTemplateDialog.title")
    );
    const [modalTitleIcon, setModalTitleIcon] = React.useState<JSX.Element | undefined>(undefined);

    const showUploadTemplate = () => {
        setModalVisible(true);
        setIsSubWorkflow(false);
    };

    const showUploadSubWorkflow = () => {
        setIsSubWorkflow(true);
        setModalTitle(t("workflowTemplatesManager.ImportSubWorkflowTemplateDialog.title"));
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
        setModalTitleIcon(undefined);
        setModalTitle(t("workflowTemplatesManager.ImportWorkflowTemplateDialog.title"));
        props.onUpdate();
    };

    const menuItems: JSX.Element[] = [
        <MenuItemButton
            key="1"
            tabIndex={0}
            onClick={showUploadTemplate}
            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.uploadMenu.uploadTemplateLink}
        >
            {t("workflowTemplatesManager.actions.uploadTemplate")}
        </MenuItemButton>,
        <MenuItemButton
            key="2"
            tabIndex={0}
            onClick={showUploadSubWorkflow}
            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.uploadMenu.uploadSubWorkflowLink}
        >
            {t("workflowTemplatesManager.actions.uploadSubWorkflow")}
        </MenuItemButton>,
    ];

    return (
        <>
            <div className={header.innerMenu}>
                <div className={style.createButton}>
                    <ToggleablePanel
                        title={""}
                        icon={
                            <TextWithTooltip
                                text={t("workflowTemplatesManager.actions.upload.tooltip", {
                                    customerName: props.user?.tenantName,
                                })}
                            >
                                <button className={buttons.secondaryButton}>
                                    <Import color={colourTheme.linkTextColor} />
                                    <span className={workflowStyle.buttonText}>
                                        {t("workflowTemplatesManager.actions.upload.button")}
                                    </span>
                                </button>
                            </TextWithTooltip>
                        }
                        testId={testIds.workArea.workflowTemplatesManager.primaryView.uploadMenu.button}
                        menuType={"HEADER"}
                        buttonClass={header.headerMenuButton}
                        hideChevron={true}
                    >
                        <Menu
                            className={classNames(userMenu.menu, header.headerMenuButton)}
                            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.uploadMenu.itself}
                        >
                            <ul>
                                {menuItems.map((item, key) => (
                                    <li key={key} className={userMenu.item}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </Menu>
                    </ToggleablePanel>
                </div>
            </div>
            <Modal
                key={1}
                isOpen={modalVisible}
                hideModal={hideModal}
                modalTitle={modalTitle}
                titleIcon={modalTitleIcon}
            >
                <ImportWorkflowsDialog
                    onClose={hideModal}
                    entityType={isSubWorkflow ? "SUB_WORKFLOW" : "TEMPLATE"}
                    service={templateService}
                    setModalTitle={setModalTitle}
                    setModalTitleIcon={setModalTitleIcon}
                />
            </Modal>
        </>
    );
};

export default ImportWorkflowTemplateView;
