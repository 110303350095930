export enum CorporateBundle {
    ESSENTIALS = "ESSENTIALS",
    POWER = "POWER",
    ENTERPRISE = "ENTERPRISE",
}
export const CORPORATE_BUNDLES = Object.keys(CorporateBundle);

export enum SmbBundle {
    SELECT_50 = "SELECT_50",
    SELECT_100 = "SELECT_100",
    SELECT_PLUS_250 = "SELECT_PLUS_250",
    SELECT_PLUS_500 = "SELECT_PLUS_500",
}
export const SMB_BUNDLE = Object.keys(SmbBundle);

export enum ProcessorBundle {
    CORE = "CORE",
    PRO = "PRO",
    PRO_SCALE = "PRO_SCALE",
}
export const PROCESSOR_BUNDLES = Object.keys(ProcessorBundle);

export enum ChannelBundle {
    PRO_SCALE = "PRO_SCALE",
}

export const CHANNEL_BUNDLES = Object.keys(ChannelBundle);

export enum ESSENTIALS_TIER {
    PRODUCT_ID_1 = "1",
    PRODUCT_ID_12 = "12",
    PRODUCT_ID_20 = "20",
    PRODUCT_ID_30 = "30",
    PRODUCT_ID_31 = "31",
    // Optional add-ons
    PRODUCT_ID_5 = "5",
    PRODUCT_ID_10 = "10",
    PRODUCT_ID_24 = "24",
    PRODUCT_ID_32 = "32",
    // BMP features
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    REPORTING = "feature-reporting",
    SSO = "feature-sso",
}

export enum SMB_SELECT_PLUS {
    PRODUCT_ID_20 = "20",
    // BMP features
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    REPORTING = "feature-reporting",
    SSO = "feature-sso",
}

export enum POWER_TIER {
    PRODUCT_ID_1 = "1",
    PRODUCT_ID_12 = "12",
    PRODUCT_ID_28 = "28",
    PRODUCT_ID_30 = "30",
    PRODUCT_ID_31 = "31",
    PRODUCT_ID_34 = "34",
    PRODUCT_ID_36 = "36",
    PRODUCT_ID_38 = "38",
    PRODUCT_ID_40 = "40",
    PRODUCT_ID_44 = "44",
    // Optional add-ons
    PRODUCT_ID_5 = "5",
    PRODUCT_ID_10 = "10",
    PRODUCT_ID_16 = "16",
    PRODUCT_ID_17 = "17",
    PRODUCT_ID_24 = "24",
    PRODUCT_ID_32 = "32",
    PRODUCT_ID_51 = "51",
    TENANT_MANAGEMENT = "feature-tenant-management",
    // BMP features
    BDE_WORKFLOW_FEATURE = "feature-workflow-bde",
    BMDE_WORKFLOW_FEATURE = "feature-workflow-bmde",
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    PUBLIC_APIS = "feature-public-apis",
    REPORTING = "feature-reporting",
    SSO = "feature-sso",
    SUSTAINABILITY_DASHBOARD = "feature-sustainability-dashboard",
    USER_GROUPS = "feature-user-groups",
}

export enum ENTERPRISE_TIER {
    PRODUCT_ID_1 = "1",
    PRODUCT_ID_12 = "12",
    PRODUCT_ID_28 = "28",
    PRODUCT_ID_30 = "30",
    PRODUCT_ID_31 = "31",
    PRODUCT_ID_34 = "34",
    PRODUCT_ID_36 = "36",
    PRODUCT_ID_38 = "38",
    PRODUCT_ID_40 = "40",
    PRODUCT_ID_44 = "44",
    // Default included add-ons
    PRODUCT_ID_51 = "51",
    // Optional add-ons
    PRODUCT_ID_5 = "5",
    PRODUCT_ID_16 = "16",
    PRODUCT_ID_17 = "17",
    PRODUCT_ID_24 = "24",
    PRODUCT_ID_10 = "10",
    PRODUCT_ID_32 = "32",
    PRODUCT_ID_90 = "90",
    // BMP features
    BDE_WORKFLOW_FEATURE = "feature-workflow-bde",
    BMDE_WORKFLOW_FEATURE = "feature-workflow-bmde",
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    OVERVIEW_DASHBOARD = "feature-overview-dashboard",
    PUBLIC_APIS = "feature-public-apis",
    REPORTING = "feature-reporting",
    REPORT_CUSTOMIZATION = "feature-report-customization",
    SSO = "feature-sso",
    SUSTAINABILITY_DASHBOARD = "feature-sustainability-dashboard",
    TENANT_MANAGEMENT = "feature-tenant-management",
    USER_GROUPS = "feature-user-groups",
}

export enum CORE_TIER {
    PRODUCT_ID_1 = "1",
    PRODUCT_ID_12 = "12",
    PRODUCT_ID_20 = "20",
    PRODUCT_ID_30 = "30",
    PRODUCT_ID_31 = "31",
    PRODUCT_ID_38 = "38",
    // Optional add-ons
    PRODUCT_ID_5 = "5",
    PRODUCT_ID_32 = "32",
    // BMP features
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    REPORTING = "feature-reporting",
    SSO = "feature-sso",
}

export enum PRO_TIER {
    PRODUCT_ID_1 = "1",
    PRODUCT_ID_12 = "12",
    PRODUCT_ID_20 = "20",
    PRODUCT_ID_30 = "30",
    PRODUCT_ID_31 = "31",
    PRODUCT_ID_34 = "34",
    PRODUCT_ID_37 = "37",
    PRODUCT_ID_38 = "38",
    // Optional add-ons
    PRODUCT_ID_5 = "5",
    PRODUCT_ID_16 = "16",
    PRODUCT_ID_17 = "17",
    PRODUCT_ID_24 = "24",
    PRODUCT_ID_10 = "10",
    PRODUCT_ID_81 = "81",
    PRODUCT_ID_35 = "35",
    PRODUCT_ID_32 = "32",
    PRODUCT_ID_51 = "51",
    TENANT_MANAGEMENT = "feature-tenant-management",
    // BMP features
    BDE_WORKFLOW_FEATURE = "feature-workflow-bde",
    BMDE_WORKFLOW_FEATURE = "feature-workflow-bmde",
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    OVERVIEW_DASHBOARD = "feature-overview-dashboard",
    PUBLIC_APIS = "feature-public-apis",
    REPORTING = "feature-reporting",
    SSO = "feature-sso",
    SUSTAINABILITY_DASHBOARD = "feature-sustainability-dashboard",
    USER_GROUPS = "feature-user-groups",
}

export enum PRO_SCALE_TIER {
    PRODUCT_ID_1 = "1",
    PRODUCT_ID_12 = "12",
    PRODUCT_ID_20 = "20",
    PRODUCT_ID_30 = "30",
    PRODUCT_ID_31 = "31",
    PRODUCT_ID_34 = "34",
    PRODUCT_ID_37 = "37",
    PRODUCT_ID_38 = "38",
    // Default included add-ons
    PRODUCT_ID_51 = "51",
    // Optional add-ons
    PRODUCT_ID_5 = "5",
    PRODUCT_ID_32 = "32",
    PRODUCT_ID_90 = "90",
    PRODUCT_ID_16 = "16",
    PRODUCT_ID_17 = "17",
    PRODUCT_ID_24 = "24",
    PRODUCT_ID_10 = "10",
    PRODUCT_ID_81 = "81",
    PRODUCT_ID_35 = "35",
    // BMP features
    BDE_WORKFLOW_FEATURE = "feature-workflow-bde",
    BMDE_WORKFLOW_FEATURE = "feature-workflow-bmde",
    CUSTOM_REPORT_VIEWS = "feature-custom-report-views",
    CUSTOM_ROLES = "feature-custom-roles",
    LICENSE_ALERTS = "feature-license-alerts",
    LICENSE_CONSUMPTION = "feature-license-consumption",
    LICENSE_POOLS = "feature-license-pools",
    OVERVIEW_DASHBOARD = "feature-overview-dashboard",
    PUBLIC_APIS = "feature-public-apis",
    REPORTING = "feature-reporting",
    REPORT_CUSTOMIZATION = "feature-report-customization",
    SSO = "feature-sso",
    SUSTAINABILITY_DASHBOARD = "feature-sustainability-dashboard",
    TENANT_MANAGEMENT = "feature-tenant-management",
    USER_GROUPS = "feature-user-groups",
}

export const BUNDLES_ADD_ONS = ["5", "32", "51", "90", "10", "24", "16", "17", "81", "35", "feature-tenant-management"];
export const RESTRICTED_ADD_ONS = BUNDLES_ADD_ONS.filter((each) => each !== "feature-tenant-management");
export const MANAGEMENT_CONSOLE_LICENSE = "51";
export enum BundlesTenantTypes {
    CORPORATE = "CORPORATE",
    PROCESSOR = "PROCESSOR",
    SMB = "SMB",
    CHANNEL = "CHANNEL",
}
