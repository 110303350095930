import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";

import MobileNavigation from "./MobileNavigation";
import style from "./side-navigation.scss";
import DarkModeSwitch from "components/dark-mode-switch/DarkModeSwitch";
import headerStyle from "components/header/header.scss";
import { useOutsideClick } from "components/header/MenuPanel";
import ToggleablePanel from "components/header/ToggleablePanel";
import Bell from "components/icons/Bell";
import CollapsibleSideMenuIcon from "components/icons/CollapsibleSideMenuIcon";
import GroupIcon from "components/icons/GroupIcon";
import RefreshButtonIcon from "components/icons/RefreshButtonIcon";
import Modal from "components/modal/Modal";
import NotificationMenu from "components/notifications/NotificationMenu";
import ReportDeletionNotificationDetails from "components/reports/deletion/ReportDeletionNotificationDetails";
import ReportImportNotificationDetails from "components/reports/import/ReportImportNotificationDetails";
import NotificationDetails from "components/reports/notification-details/NotificationDetails";
import Tooltip from "components/tooltip/Tooltip";
import { UserMenu } from "components/user-menu/UserMenu";
import { Breakpoint } from "domain/breakpoints";
import { Notification } from "domain/notification";
import { notificationService } from "services/notification/NotificationService";
import { ReportDeletionNotificationData } from "services/report/ReportDeletionService";
import { ImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getTenantName, hasTenantCookie } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import { getInitials } from "utils/commonFunctions";
import { logger } from "utils/logging";
import { getObject, RepositoryKey, setObject } from "utils/repository";

import logo from "assets/images/logo/blanccoManagementPortalLogoWhiteSmall.svg";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    theme: state.themeReducer.theme,
}));

const updateTenantDeletionStatus = (updateTenantUuid: string, abortControllers: AbortController[]) => {
    const abortController = new AbortController();
    abortControllers.push(abortController);
    tenantService.updateTenantDeletionStatus(updateTenantUuid, abortController).catch(() => {
        logger.error("Failed to update tenant deletion status.");
    });
};

const SideNavigationNew = (props: ConnectedProps<typeof connector>): JSX.Element => {
    let username;
    let usernameFormattedForInitials;
    if (getTenantName() != "") {
        username = getTenantName();
    } else {
        username = props.user ? props.user.username.split("@")[0] : "";
        usernameFormattedForInitials = username.replace(".", " ");
    }
    const { t } = useTranslation();
    const RETRY_FETCH_NOTIFICATIONS = 15_000;
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [notificationVisibility, setNotificationVisibility] = React.useState(false);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);
    const [showBadge, setShowBadge] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const fetchNotifications = () => {
        const abortController: AbortController = new AbortController();
        abortControllers.push(abortController);
        notificationService.fetchNotifications(abortController).then((data) => {
            setNotifications(data.sort((a, b) => b.creationDate.localeCompare(a.creationDate)));
        });
    };
    const [reportImportDialogRefreshCount, setReportImportDialogRefreshCount] = React.useState<number>(0);
    const [importNotificationData, setImportNotificationData] = React.useState<ImportNotificationData>({
        jobId: null,
        jobType: "NORMAL",
    });
    const [reportDeletionNotificationData, setReportDeleteNotificationData] =
        React.useState<ReportDeletionNotificationData>({
            jobId: null,
            totalReports: "",
        });

    const closeReportDeletionResultsDialog = () =>
        setReportDeleteNotificationData((prevState: ReportDeletionNotificationData) => ({
            ...prevState,
            jobId: null,
        }));

    const closeReportImportResultsDialog = () =>
        setImportNotificationData((prevState: ImportNotificationData) => ({
            ...prevState,
            jobId: null,
            totalReports: "",
        }));
    const [reportExportDialogRefreshCount, setReportExportDialogRefreshCount] = React.useState<number>(0);
    const [reportDialogRefreshIconVisibility, setReportDialogRefreshIconVisibility] = React.useState<boolean>(false);
    const [reportExportJobId, setReportExportJobId] = React.useState<string>("");

    const createNotificationBadge = (): JSX.Element | null => {
        const seenUuids = notificationService.getSeenNotifications() ?? [];
        let count = 0;
        for (const index in notifications) {
            const uuid = notifications[index].uuid;
            if (!seenUuids.includes(uuid)) {
                count++;
            }
        }
        if (count == 0) {
            return null;
        }

        return (
            <div
                className={classNames(
                    headerStyle.circle,
                    { [headerStyle.oneDigitSize]: count < 10 },
                    { [headerStyle.twoDigitsSize]: count > 9 && count < 100 },
                    { [headerStyle.threeDigitsSize]: count > 99 }
                )}
            >
                <div className={headerStyle.count}>{count < 100 ? count : t("Notification.count")}</div>
            </div>
        );
    };

    const handleFetch = () => {
        fetchNotifications();
    };

    const handleClickOutside = () => {
        setNotificationVisibility(false);
    };

    const ref = useOutsideClick(handleClickOutside);

    const getTitle = (notificationType: string) => {
        if (notificationType === "REPORT_EXPORT_STARTED") {
            return t("Notification.reportNotifications.inProgressTitle");
        } else if (notificationType === "REPORT_EXPORT_FAILED") {
            return t("Notification.reportNotifications.failureTitle");
        } else if (notificationType === "REPORT_EXPORT_SUCCEEDED") {
            return t("Notification.reportNotifications.successTitle");
        } else if (notificationType === "REPORT_EXPORT_WARNING") {
            return t("Notification.reportNotifications.successWithFailTitle");
        }
        return "";
    };

    React.useEffect(() => {
        if (initialLoading) {
            handleFetch();
            setInitialLoading(false);
            setShowBadge(true);
        }
        const notificationTimer = setTimeout(() => {
            handleFetch();
            setShowBadge(true);
        }, RETRY_FETCH_NOTIFICATIONS);

        notifications.forEach((notification) => {
            if (notification.type === "TENANT_DELETION_FAILED") {
                updateTenantDeletionStatus(JSON.parse(notification.data).tenant_uuid, abortControllers);
            }
        });

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
            clearTimeout(notificationTimer);
        };
    }, [notifications]);

    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
    });

    const [showSideNavigation, setShowSideNavigation] = React.useState(
        getObject(RepositoryKey.SIDE_NAVIGATION) === true
    );
    const [collapsibleSideMenuIconColor, setCollapsibleSideMenuIconColor] = React.useState<string>(
        props.theme.primaryButtonBackgroundColor
    );

    const toggleNavigation = () => {
        const toggle = !showSideNavigation;
        setObject(RepositoryKey.SIDE_NAVIGATION, toggle);
        setShowSideNavigation(toggle);
    };

    React.useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, [showSideNavigation]);

    React.useEffect(() => {
        window.onresize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };
        if (getObject(RepositoryKey.SIDE_NAVIGATION) != null) {
            if (getObject(RepositoryKey.SIDE_NAVIGATION) == true) {
                setShowSideNavigation(true);
            }
            if (getObject(RepositoryKey.SIDE_NAVIGATION) == false) {
                setShowSideNavigation(false);
            }
        } else {
            if (windowSize.width <= Breakpoint.XL) {
                setShowSideNavigation(false);
            } else {
                if (windowSize.width > Breakpoint.XL) {
                    setShowSideNavigation(true);
                }
            }
        }
    }, [windowSize.width]);

    const setRefreshIconVisibility = (show: boolean) => setReportDialogRefreshIconVisibility(show);
    const notificationData = notifications.filter((each) => each.data.includes(reportExportJobId))[0];

    const reportImportResult = React.useMemo(
        () => (
            <Modal
                isOpen={importNotificationData.jobId !== null}
                hideModal={() =>
                    setImportNotificationData((prevState: ImportNotificationData) => ({ ...prevState, jobId: null }))
                }
                modalTitle={t("Notification.reportImportResultDialog.title")}
                action={
                    reportDialogRefreshIconVisibility && (
                        <Tooltip content={t("Notification.reportNotifications.refreshTableIconTooltip")}>
                            <span
                                className={headerStyle.reportImportResultRefreshIcon}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_IMPORT,
                                        action: Action.REFRESH,
                                    });
                                    setReportImportDialogRefreshCount((prev) => prev + 1);
                                }}
                            >
                                <span data-testid={testIds.workArea.report.reportResultsDialog.refreshButton}>
                                    <RefreshButtonIcon color={props.theme.iconFillColor} />
                                </span>
                            </span>
                        </Tooltip>
                    )
                }
            >
                <ReportImportNotificationDetails
                    data={importNotificationData}
                    refreshCount={reportImportDialogRefreshCount}
                    setRefreshIcon={setReportDialogRefreshIconVisibility}
                    onClose={closeReportImportResultsDialog}
                />
            </Modal>
        ),
        [importNotificationData.jobId, reportDialogRefreshIconVisibility, reportImportDialogRefreshCount]
    );

    const reportDeletionResult = React.useMemo(
        () => (
            <Modal
                isOpen={reportDeletionNotificationData.jobId !== null}
                hideModal={() =>
                    setReportDeleteNotificationData((prevState: ReportDeletionNotificationData) => ({
                        ...prevState,
                        jobId: null,
                    }))
                }
            >
                <ReportDeletionNotificationDetails
                    data={reportDeletionNotificationData}
                    onClose={closeReportDeletionResultsDialog}
                />
            </Modal>
        ),
        [reportDeletionNotificationData.jobId]
    );

    return (
        <>
            <div
                className={classNames(
                    style.mainNavigation,
                    style.sideNavigation,
                    showSideNavigation ? style.navContainer : style.navHiddenContainer
                )}
                style={{
                    height: "100vh",
                    width: showSideNavigation ? 260 : 80,
                    transition: "width 250ms",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 25px",
                        alignItems: "center",
                        background:
                            "linear-gradient(90deg, rgb(17, 22, 43) 0%, rgb(39, 61, 112) 50%, rgb(59, 93, 171) 100%)",
                        padding: 20,
                        overflowX: "hidden",
                    }}
                >
                    <Link to="/">
                        <img src={logo} alt="Management Portal" style={{ maxWidth: 130 }} />
                    </Link>

                    <DarkModeSwitch />
                </div>

                <div className={style.headerActions}>
                    <div className={style.content}>
                        <ToggleablePanel
                            title={username}
                            testId={testIds.header.userMenu.button}
                            menuType={"HEADER"}
                            icon={
                                <GroupIcon
                                    backgroundColor={props.theme.textColor}
                                    textColor={props.theme.contentBackgroundColor}
                                    groupInitials={getInitials(
                                        usernameFormattedForInitials ? usernameFormattedForInitials : username
                                    )}
                                />
                            }
                            buttonClass={style.userNameWithIcon}
                        >
                            <UserMenu className={style.userMenu} />
                        </ToggleablePanel>

                        <div>
                            {!hasTenantCookie() ? (
                                <div
                                    ref={ref}
                                    onBlur={(event) => {
                                        if (event.relatedTarget == null) {
                                            return;
                                        }
                                        if (!event.currentTarget.contains(event.relatedTarget)) {
                                            setNotificationVisibility(false);
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        if (notificationVisibility && event.key === "Escape") {
                                            setNotificationVisibility(false);
                                        }
                                    }}
                                >
                                    <Tooltip content={t("AltText.notification")}>
                                        <button
                                            data-testid={testIds.header.notificationMenu.button}
                                            onClick={() => {
                                                if (notificationVisibility) {
                                                    setNotificationVisibility(false);
                                                } else {
                                                    setNotificationVisibility(true);
                                                    notificationService.setSeenNotifications(notifications);
                                                }
                                            }}
                                            className={headerStyle.button}
                                        >
                                            {showBadge && notifications.length > 0 ? createNotificationBadge() : null}
                                            <Bell fillColor={props.theme.textColor} />
                                        </button>
                                    </Tooltip>
                                    {notificationVisibility ? (
                                        <NotificationMenu
                                            onDelete={setNotifications}
                                            notifications={notifications}
                                            showImportReportDialog={setImportNotificationData}
                                            showExportReport={setReportExportJobId}
                                            showDeleteReportDialog={setReportDeleteNotificationData}
                                            className={style.notificationMenu}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>

                <MobileNavigation />

                <button
                    className={classNames(style.toggleButton, style.tabIndex)}
                    onClick={toggleNavigation}
                    onMouseEnter={() => setCollapsibleSideMenuIconColor(props.theme.primaryButtonBackgroundHoverColor)}
                    onMouseLeave={() => setCollapsibleSideMenuIconColor(props.theme.primaryButtonBackgroundColor)}
                    style={{ left: showSideNavigation ? 210 : 77, transition: "left 250ms", zIndex: 10 }}
                >
                    <CollapsibleSideMenuIcon
                        showSideNav={showSideNavigation}
                        color={
                            collapsibleSideMenuIconColor === props.theme.primaryButtonBackgroundColor ||
                            collapsibleSideMenuIconColor === props.theme.primaryButtonBackgroundHoverColor
                                ? collapsibleSideMenuIconColor
                                : props.theme.primaryButtonBackgroundColor
                        }
                    />
                </button>
            </div>
            {reportImportResult}
            {reportDeletionResult}
            <Modal
                isOpen={reportExportJobId !== ""}
                hideModal={() => setReportExportJobId("")}
                modalTitle={getTitle(notificationData?.type)}
                action={
                    reportDialogRefreshIconVisibility && (
                        <Tooltip content={t("Notification.reportNotifications.refreshTableIconTooltip")}>
                            <span
                                className={headerStyle.reportExportResultRefreshIcon}
                                onClick={() => {
                                    setReportExportDialogRefreshCount((prev) => prev + 1);
                                }}
                            >
                                <RefreshButtonIcon color={props.theme.iconFillColor} />
                            </span>
                        </Tooltip>
                    )
                }
            >
                <div className={headerStyle.fixedWidthModal}>
                    {reportExportJobId && (
                        <NotificationDetails
                            refreshCount={reportExportDialogRefreshCount}
                            jobId={reportExportJobId}
                            setRefreshIcon={setRefreshIconVisibility}
                            jobDetail={notificationData}
                        />
                    )}
                </div>
            </Modal>
        </>
    );
};

const connectedSideNavigation = connector(SideNavigationNew);
export { connectedSideNavigation as SideNavigationNew };
