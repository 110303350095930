import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { fetchFeaturesWithAvailableLicense } from "./bmsUtils";
import EditConfigForm from "components/licenses/license-configuration/bms/BmsEditConfigForm";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { Configuration } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

const mapState = (state: StoreState) => ({
    hasLicenseConfiguration: state.licensesReducer.hasLicenseConfiguration,
});

const connector = connect(mapState);

const BMSEditConfigurationView = (
    props: { onConfigurationAdded: () => void } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [addConfigurationFormVisible, setAddConfigurationFormVisible] = React.useState(false);
    const [featuresWithAvailableLicense, setFeaturesWithAvailableLicense] = useState<string[]>([]);
    const [message, setMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);

    const configurationSubmitHandler = async (configuration: Configuration): Promise<void> => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        if (configuration) {
            await licenseService
                .addConfiguration(configuration, abortController)
                .then(() => {
                    setMessage(t("AddConfigurationView.successMessage"));
                })
                .catch((e) => {
                    if (!signal.aborted) {
                        const error = JSON.parse(e.message);
                        const errorMessage = error.message;
                        if (errorMessage === "Configuration already exists") {
                            setMessage(t("AddConfigurationView.configPresent"));
                        } else {
                            setMessage(t("AddConfigurationView.failureMessage"));
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const [configuration, setConfiguration] = React.useState<Configuration>();

    const fetchDefaultConfigurationAndLicenses = async (): Promise<void> => {
        setMessage("");
        setLoading(true);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        const fetchDefaultConfigurationPromise = licenseService
            .fetchDefaultConfigurationData(abortController)
            .then((defaultConfigResponse) => {
                setConfiguration(defaultConfigResponse);
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    logger.error("Failed to load default configuration:", error);
                    setMessage(t("Configuration.addConfigureDialog.requestFailed"));
                }
            });

        const fetchLicensesPromise = licenseService
            .fetchAllLicenses({ abortController, own: false })
            .then((licenseList) => {
                const features = fetchFeaturesWithAvailableLicense(licenseList);
                setFeaturesWithAvailableLicense(features);
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    logger.error("Failed to load licenses:", error);
                    setMessage(t("Licenses.requestFailed"));
                }
            });

        Promise.all([fetchDefaultConfigurationPromise, fetchLicensesPromise]).finally(() => {
            if (!abortController.signal.aborted) {
                setLoading(false);
            }
        });
    };

    const showAddConfig = () => {
        setMessage("");
        setAddConfigurationFormVisible(true);
        usageStatisticsService.sendEvent({
            category: Category.LICENSE,
            action: Action.ADD_LICENSE_CONFIGURATION,
        });
        fetchDefaultConfigurationAndLicenses();
    };

    const hideResult = () => {
        setAddConfigurationFormVisible(false);
        props.onConfigurationAdded();
    };
    return (
        <div>
            {!props.hasLicenseConfiguration ? (
                <button className={buttonsStyle.primaryButtonWithoutIcon} onClick={showAddConfig}>
                    {t("Configuration.configureButtonText")}
                </button>
            ) : null}

            <Modal
                isOpen={addConfigurationFormVisible}
                hideModal={hideResult}
                modalTitle={t("Configuration.configureButtonText")}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : configuration !== undefined && message === "" ? (
                    <div>
                        <EditConfigForm
                            onSuccess={configurationSubmitHandler}
                            configuration={configuration}
                            featuresWithAvailableLicenses={featuresWithAvailableLicense}
                        />
                    </div>
                ) : (
                    <>
                        <TextBlock>{message}</TextBlock>
                        <div className={form.okButtonContainer}>
                            <button className={buttonsStyle.primaryOkButton} onClick={hideResult}>
                                {t("Common.ok")}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default connector(BMSEditConfigurationView);
