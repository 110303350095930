/* Some fields are not in camelCase as they are mapped to an existing configuration used by BMS app  */
export interface Brand {
    bms_server?: string;
    brandId?: string;
    appName?: string;
    image?: string;
    tag_line?: string;
    primaryColor?: string;
    secondaryColor?: string;
    tertiaryColor?: string;
    checkContrast?: boolean;
}

export interface Component {
    name: string;
    enabled: boolean;
}

export interface ComponentFeature {
    enabled: boolean;
    components: Component[];
}

export interface ComponentFeatures {
    [key: string]: ComponentFeature;
}

export interface Pricing {
    apiUsername: string | null;
    apiPassword: string | null;
    estimateApiUrl: string | null;
    quoteApiUrl: string | null;
    apiDealerId: string | null;
    apiChannelId: string | null;
    apiConfiguration: ApiConfiguration;
}

export interface FaultCodeTest {
    name: string;
}

export interface FaultCodeRecommendation {
    text: string;
    key: string;
}

export interface FaultCode {
    key: string;
    text: string;
    recommendations: FaultCodeRecommendation[];
    tests: FaultCodeTest[];
}

export interface Threshold {
    name: string;
    value: string;
}

export interface InsurancePricing {
    apiUsername: string;
    apiPassword: string;
    apiUrl: string;
}

export interface Channel {
    name: string;
    type: string;
    punchOutUrl: string;
    storeLocatorUrl: string;
    tradeIdLength: number;
    useTac: boolean;
    phone: string | null;
    pricing: Pricing;
}

export interface NtfChannel {
    id: string;
    name: string;
    type: string;
    punchOutUrl: string;
    storeLocatorUrl: string;
    phone: string | null;
}

export interface NtfProvider {
    id: string;
    name: string;
    region: string;
    testSelectionEnabled: boolean;
    channels: NtfChannel[];
    questions: ConditionAssessmentQuestions;
}

export interface Provider {
    name: string;
    region: string;
    displayTradeId: boolean;
    allowTradeForLocks: boolean;
    quoteExpiryInMinutes: number;
    channels: Channel[];
    questions: ConditionAssessmentQuestions;
    colourRequiredIos: boolean;
    colourRequiredAndroid: boolean;
}

export interface BBTIConfiguration {
    imeiMandatory: boolean;
    serialMandatory: boolean;
    defaultProvider: string;
    customer_punch_out: string;
    providers: Provider[];
    tests: Test[];
}

export interface ValidationConfiguration {
    imeiMandatory: boolean;
    serialMandatory: boolean;
    punchOutUrl: string;
    customer_punch_out: string;
    tests: Test[];
    apiConfig: ApiConfiguration;
    provider: LeaseProvider;
}

export interface NtfConfiguration {
    imeiMandatory: boolean;
    serialMandatory: boolean;
    providers: NtfProvider[];
    thresholds: Threshold[];
    fault_codes: FaultCodes;
    tests: Test[];
}

export interface FaultCodes {
    [key: string]: FaultCode[];
}

export interface InsuranceConfiguration {
    imeiMandatory: boolean;
    serialMandatory: boolean;
    customer_punch_out: string;
    tests: Test[];
    pricing: InsurancePricing;
    provider: InsuranceProvider;
}

export interface InsuranceProvider {
    questions: ConditionAssessmentQuestions;
}

export interface LeaseConfiguration {
    imeiMandatory: boolean;
    serialMandatory: boolean;
    punchOutUrl: string;
    customer_punch_out: string;
    tests: Test[];
    apiConfig: ApiConfiguration;
    provider: LeaseProvider;
}

export interface LeaseProvider {
    punchOutUrl: string;
    questions: ConditionAssessmentQuestions;
}

export interface DownloadServerConfiguration {
    appIcon: string;
    headerLogo: string;
    title: string;
    androidFirebaseLink: string;
    huaweiFirebase: string;
    iOSFirebaseLink: string;
    licenseFileType: string;
}

export interface IdentityVerificationConfiguration {
    endpoint: string;
    bodyFieldName: string;
    apiConfiguration: ApiConfiguration;
    oAuthConfiguration: OAuthConfiguration;
}

export interface AdditionalHeader {
    name: string;
    value: string;
}

export interface OAuthConfiguration {
    enabled: boolean;
    endpoint: string | null;
    client_id: string | null;
    client_secret: string | null;
    grant_type: GrantType | null;
    scope: string | null;
}

export enum GrantType {
    implicit = "implicit",
    authorizationCode = "authorization_code",
    refreshToken = "refresh_token",
    clientCredentials = "client_credentials",
}

export interface ApiConfiguration {
    enabled: boolean;
    endpoint: string | null;
    apiKeyHeaderName: string | null;
    apiKeyHeaderValue: string | null;
    additionalHeaders: AdditionalHeader[];
}

export interface ConditionAssessmentQuestions {
    [key: string]: Question[];
}

export interface Question {
    id: string;
    category: string;
    label: string;
    notes: string;
    answers: Answer[];
    isCustomQuestion?: boolean;
}

export interface Answer {
    id: string;
    label: string;
    value: string;
    positiveAnswer?: boolean;
}

export interface TestConfiguration {
    name: string;
    platforms: string[];
    mode: string[];
    category?: string;
    attributes?: {
        name: string;
        value: string;
    }[];
}

export interface Test {
    name: string;
    enabled: boolean;
    usdk: boolean;
    forDeviceType: string;
    mode: string;
    attributes?: {
        name: string;
        value: string;
    }[];
}
