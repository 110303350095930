import classNames from "classnames";
import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import style from "./general-setting-view.scss";
import { NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from "domain/globalConstants";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    name: string;
    description: string;
    onSuccess: (values: FormValues) => Promise<void>;
}

export interface FormValues {
    name: string;
    description: string;
}

export default function EditApiKeyForm(props: Props): JSX.Element {
    const { t } = useTranslation();

    const [descriptionCharactersLeft, setDescriptionCharactersLeft] = React.useState(
        NOTES_MAX_LENGTH - props.description.length
    );
    const descriptionChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescriptionCharactersLeft(event.target.maxLength - event.target.value.length);
    };
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = (values) => props.onSuccess(values);

    return (
        <Formik
            initialValues={{ name: props.name, description: props.description }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                name: string().required(t("EditApiKey.nameRequired")),
            })}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {({ values, handleChange, handleBlur, errors }: FormikProps<FormValues>) => {
                return (
                    <Form>
                        <div className={style.mainForm}>
                            <div className={form.formFields}>
                                <label htmlFor="name" className={form.label}>
                                    {t("Common.nameWithColon")}
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className={classNames(form.input, form.fixedWidthInput)}
                                    maxLength={NAME_MAX_LENGTH}
                                    autoFocus
                                    onBlur={handleBlur}
                                    defaultValue={values.name}
                                    onChange={handleChange}
                                    data-testid={testIds.workArea.support.apiKeys.manageApiKeyDialog.nameInput.itself}
                                />
                                <div className={form.error}>{errors.name}</div>
                            </div>

                            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                <div className={form.formFieldsAlignItemsTop}>
                                    <span className={form.optional}>{t("Common.optional")}</span>
                                    <label htmlFor="description" className={classNames(form.label)}>
                                        {t("GenerateApiKeyForm.description")}
                                    </label>
                                </div>
                                <div className={style.descriptionContainer}>
                                    <Field
                                        as="textarea"
                                        id="description"
                                        className={classNames(
                                            form.input,
                                            form.fixedWidthInput,
                                            style.descriptionHeight
                                        )}
                                        name="description"
                                        maxLength={NOTES_MAX_LENGTH}
                                        value={values.description}
                                        onBlur={handleBlur}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            handleChange(e);
                                            descriptionChangeHandler(e);
                                        }}
                                        data-testid={
                                            testIds.workArea.support.apiKeys.manageApiKeyDialog.descriptionInput.itself
                                        }
                                    />
                                    <span className={form.optional}>
                                        {t("GenerateApiKeyForm.charactersLeft", {
                                            remainingCharacters: descriptionCharactersLeft.toString(),
                                            descriptionMaxLength: NOTES_MAX_LENGTH.toString(),
                                        })}
                                    </span>
                                </div>
                            </div>

                            <div className={classNames(style.moveright, style.buttonContainer)}>
                                <button
                                    type="submit"
                                    className={classNames(buttons.primaryButtonWithoutIcon, style.saveButton)}
                                    data-testid={testIds.workArea.support.apiKeys.manageApiKeyDialog.saveButton}
                                >
                                    {t("Common.save")}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
