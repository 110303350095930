import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { FaultCodeRecommendation } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import BmsTextAreaComponent from "components/licenses/license-configuration/bms/form-components/BmsTextAreaComponent";
import InputComponent from "components/licenses/license-configuration/bms/form-components/BmsTextInputComponent";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    recommendation: FaultCodeRecommendation;
    onRecommendationChange: (onRecommendationChange: FaultCodeRecommendation) => void;
    editMode: boolean;
    recommendationKeys: string[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const EditRecommendationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [recommendation, setRecommendation] = useState<FaultCodeRecommendation>(props.recommendation);
    const [recommendationKeyError, setRecommendationKeyError] = useState<string>();

    const handleCustomRecommendationInputChange = (field: keyof FaultCodeRecommendation, newValue: string) => {
        if (field === "key" && !props.editMode) {
            setRecommendationKeyError(
                props.recommendationKeys.includes(newValue)
                    ? t("Configuration.ntfConfigurationForm.duplicateRecommendationKey")
                    : undefined
            );
        }

        setRecommendation((prevState) => ({
            ...prevState,
            [field]: newValue,
        }));
    };

    function handleSave() {
        props.onRecommendationChange(recommendation);
    }

    const isDisabled = !!recommendationKeyError || recommendation.key.length == 0;

    return (
        <>
            <div className={classNames(style.boxed)} style={{ minWidth: 600, minHeight: 0 }}>
                <Label className={classNames(style.bmsProviderHeaderLabel)}>
                    {t("Configuration.ntfConfigurationForm.recommendation")}
                </Label>
                <InputComponent
                    labelKey={t("Configuration.ntfConfigurationForm.recommendationKey")}
                    toolTipContent={t("Configuration.tooltips.ntfConfigurationForm.recommendationKey")}
                    inputId="key"
                    value={recommendation.key}
                    onChange={(newValue) => handleCustomRecommendationInputChange("key", newValue)}
                    disabled={props.editMode}
                    inputError={recommendationKeyError}
                />
                <BmsTextAreaComponent
                    labelKey={t("Configuration.ntfConfigurationForm.recommendationText")}
                    toolTipContent={t("Configuration.tooltips.ntfConfigurationForm.recommendationText")}
                    inputId="key"
                    value={recommendation.text}
                    onChange={(newValue) => handleCustomRecommendationInputChange("text", newValue)}
                />
            </div>
            <div className={form.buttonContainer}>
                <button
                    className={buttons.primaryButtonWithoutIcon}
                    data-testid={testIds.workArea.license.configure.manageConfigurationDialog.saveButton}
                    onClick={handleSave}
                    disabled={isDisabled}
                >
                    {t("Configuration.editConfigureDialog.saveButton")}
                </button>
            </div>
        </>
    );
};

export default connector(EditRecommendationForm);
