import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import LocalSearch from "./LocalSearch";
import tableStyle from "./manage-user-groups.scss";
import { Groups } from "./ManageUserGroupsForm";
import { FetchedUserTableData, SELECTED_USERS_MAX_COUNT, USER_GROUP_COUNT_LIMIT } from "./ManageUserTable";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import Tooltip from "components/tooltip/Tooltip";
import style from "components/users/users.scss";
import {
    AUTH_USER_GROUP_CREATE,
    AUTH_USER_GROUP_DELETE,
    AUTH_USER_GROUP_EDIT,
    AUTH_USER_GROUP_VIEW,
} from "domain/authority";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import { UserTableData } from "domain/users";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import warningIcon from "assets/images/icons/quickIndeterminate.svg";

import testIds from "testIds.json";

interface TableState {
    usersData: UserTableData[];
    cursor: string;
    scrollPosition?: number;
}

interface Props {
    count: number;
    groups: Groups[] | undefined;
    setSelectedUserList: (checked: boolean, uuid: string) => void;
    selectedUsers: FetchedUserTableData[];
    tableState: TableState;
    setSearchGroup: React.Dispatch<React.SetStateAction<string>>;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    initialUsersTableLoading: boolean;
    loading: boolean;
    finalRightList: FetchedUserTableData[];
    requestFailureMessage: string;
    disabled: boolean;
    setLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
    restrictedUserUuids: Set<string>;
}

const UserList = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    const [search, setSearch] = React.useState("");
    const [selectedUsersCount, setSelectedUsersCount] = React.useState<number>(0);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const onChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, value } = event.target;
        props.setSelectedUserList(checked, value);
    };

    const createCheckbox = (userUuid: string, dataTestId: string, restricted = false) => {
        let disabled = props.disabled;
        let selected = props.selectedUsers.some((each) => each.uuid == userUuid);
        if (props.finalRightList.some((each) => each.uuid == userUuid)) {
            selected = true;
            disabled = true;
        }
        const content = (
            <div className={style.userNameCell}>
                <label className={restricted ? form.containerRestricted : form.container} htmlFor={"left_" + userUuid}>
                    {restricted ? (
                        <img src={warningIcon} alt={t("AltText.warningIcon")} className={form.checkmark} />
                    ) : (
                        <>
                            <input
                                key={"left_" + userUuid}
                                name={"userList"}
                                id={"left_" + userUuid}
                                type="checkbox"
                                defaultChecked={selected}
                                data-testid={dataTestId}
                                className={form.input}
                                value={userUuid}
                                disabled={disabled}
                                onChange={onChangeEventHandler}
                            />
                            <span className={form.checkmark}></span>
                        </>
                    )}
                </label>
            </div>
        );
        return restricted ? (
            <>
                <Tooltip
                    delay={[300, 0]}
                    offset={[0, 10]}
                    content={t("UserGroups.manageUserGroupsView.selectedUsers.tooManyGroups", {
                        max: USER_GROUP_COUNT_LIMIT,
                    })}
                >
                    {content}
                </Tooltip>
            </>
        ) : (
            <>{content}</>
        );
    };
    const columns: Array<Column<FetchedUserTableData>> = [
        {
            Header: () => (
                <>{createCheckbox("all", testIds.workArea.userGroup.manageUserGroupDialog.selectTable.itself)}</>
            ),
            accessor: "uuid",
            Cell: (row) => (
                <>
                    {createCheckbox(
                        row.row.cells[0].value,
                        testIds.workArea.user.addUserDialog.statusCheckbox.itself,
                        row.row.original.groups.length >= USER_GROUP_COUNT_LIMIT
                    )}
                </>
            ),
            width: deriveColumnWidth(5, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.name")} key="name" />,
            accessor: "name",
            Cell: ({ row }) => (
                <>
                    <label className={style.userNameCell} htmlFor={"left_" + row.original.uuid}>
                        <TextWithTooltip text={row.original.name} />
                    </label>
                </>
            ),
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.userStatus.title")} key="status" />,
            accessor: "enabled",
            Cell: ({ cell: { value } }) => (
                <StatusBadge
                    values={[
                        value
                            ? {
                                  status: Status.SUCCESS,
                                  title: t("UsersTable.userStatus.confirmed"),
                              }
                            : {
                                  status: Status.ERROR,
                                  title: t("UsersTable.userStatus.forceChangePassword"),
                              },
                    ]}
                    tooltip={true}
                />
            ),
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.expirationDate")} key="expirationDate" />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) =>
                value ? <DateCell tooltip={true} value={value} withoutTime={true} /> : t("Common.never"),
            width: deriveColumnWidth(25, tableContainerRef),
        },
    ];

    React.useEffect(() => {
        props.finalRightList.forEach((each) => {
            const element = document.getElementById("left_" + each.uuid) as HTMLInputElement;
            if (element) {
                element.checked = true;
                element.disabled = true;
            }
        });
        const element = document.getElementById("left_all") as HTMLInputElement;
        if (props.finalRightList.length == 0) {
            if (element) {
                element.checked = false;
                element.disabled = false;
            }
        } else if (props.finalRightList.length === props.tableState.usersData.length) {
            if (element) {
                element.checked = true;
                element.disabled = true;
            }
        }
    }, [props.finalRightList]);

    React.useEffect(() => {
        const selectedUsersUuid = props.selectedUsers.map((each) => each.uuid);
        const finalSelectedUsersUuid = props.finalRightList.map((each) => each.uuid);
        props.tableState.usersData.map((user) => {
            const element = document.getElementById("left_" + user.uuid) as HTMLInputElement;

            if (element) {
                element.checked = selectedUsersUuid.includes(user.uuid) || finalSelectedUsersUuid.includes(user.uuid);
            }
        });

        setSelectedUsersCount(props.selectedUsers.length);
        let finalItemsChecked = 0;
        props.finalRightList.map((each) => {
            const element = document.getElementById("left_" + each.uuid) as HTMLInputElement;
            if (element && element.checked && element.disabled) {
                finalItemsChecked = finalItemsChecked + 1;
            }
        });

        let selectedUsersCount = 0;
        props.selectedUsers.map((each) => {
            const element = document.getElementById("left_" + each.uuid) as HTMLInputElement;
            if (element && element.checked) {
                selectedUsersCount = selectedUsersCount + 1;
            }
        });

        const remainingUsersToSelect = props.tableState.usersData.length - finalItemsChecked - selectedUsersCount;
        const checkAll = document.getElementById("left_all") as HTMLInputElement;
        if (!remainingUsersToSelect) {
            if (checkAll) {
                checkAll.checked = true;
                if (finalItemsChecked == props.tableState.usersData.length) {
                    checkAll.disabled = true;
                }
            }
        } else {
            if (checkAll) {
                checkAll.checked = false;
                checkAll.disabled = false;
            }
        }
    }, [props.selectedUsers]);

    /**
     * UseEffect to disable and enable the selection of users based on already selected users and search.
     */
    React.useEffect(() => {
        const usersList = props.finalRightList.map((each) => each.uuid);
        let checkedUserCount = 0;
        props.tableState.usersData.map((each) => {
            const element = document.getElementById("left_" + each.uuid) as HTMLInputElement;
            if (element && !props.restrictedUserUuids.has(each.uuid) && !usersList.includes(each.uuid)) {
                element.disabled = props.disabled;
            } else {
                checkedUserCount = checkedUserCount + 1;
            }
        });

        const checkAll = document.getElementById("left_all") as HTMLInputElement;
        if (checkAll) {
            if (!props.disabled) {
                if (checkedUserCount == props.tableState.usersData.length) {
                    checkAll.disabled = true;
                } else {
                    checkAll.disabled = false;
                    checkAll.checked = false;
                }
            } else {
                checkAll.disabled = true;
            }
        }
    }, [props.disabled]);

    React.useEffect(() => {
        if (!props.initialUsersTableLoading) {
            let checkedUserCount = 0;
            props.tableState.usersData.map((user) => {
                const element = document.getElementById("left_" + user.uuid) as HTMLInputElement;
                if (element && element.checked && element.disabled) {
                    checkedUserCount = checkedUserCount + 1;
                }
            });
            const element = document.getElementById("left_all") as HTMLInputElement;
            if (props.tableState.usersData.length > 0) {
                if (checkedUserCount == props.tableState.usersData.length) {
                    if (element) {
                        element.checked = true;
                        element.disabled = true;
                    }
                }
            } else {
                if (element) {
                    element.disabled = true;
                }
            }
        }
    }, [props.initialUsersTableLoading]);

    const data = props.initialUsersTableLoading ? (
        <div className={tableStyle.loading}>
            <LoadingIndicator />
        </div>
    ) : (
        <Table
            key={"left"}
            tableIdentity={RepositoryKey.ALL_GROUP_USERS_TABLE}
            data={props.tableState.usersData}
            columns={columns}
            loaded={!props.initialUsersTableLoading}
            failureMessage={props.requestFailureMessage}
            tooltips={true}
            scrollTo={props.tableState.scrollPosition}
            emptyMessage={t("UsersTable.emptyStateMessage")}
            testId={testIds.workArea.user.table}
        />
    );

    return (
        <div className={tableStyle.search}>
            <div className={tableStyle.tableTop}>
                <div className={form.search}>
                    <div className={style.filter}>
                        {userSessionService.hasFeatureLicense("FEATURE_USER_GROUPS") &&
                            userSessionService.userHasAnyAuthority([
                                AUTH_USER_GROUP_CREATE,
                                AUTH_USER_GROUP_DELETE,
                                AUTH_USER_GROUP_EDIT,
                                AUTH_USER_GROUP_VIEW,
                            ]) && (
                                <div className={form.search}>
                                    <span className={form.label}>
                                        {t("UserGroups.manageUserGroupsView.filterByGroup.title")}
                                    </span>
                                    <span>
                                        <select
                                            className={formStyle.select}
                                            onChange={(e) => {
                                                props.setSearchGroup(e.target.value);
                                            }}
                                        >
                                            <option value="">
                                                {t("UserGroups.manageUserGroupsView.filterByGroup.all")}
                                            </option>
                                            {props.groups?.map((group) => {
                                                return (
                                                    <option key={group.uuid} value={group.uuid}>
                                                        {group.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </span>
                                </div>
                            )}
                        <LocalSearch
                            setSearch={setSearch}
                            search={search}
                            searchInProgress={false}
                            keypressEvent={true}
                            setSearchOnKeyPress={props.setSearchText}
                            inputKey={"left"}
                        />
                    </div>
                </div>
            </div>
            <div className={tableStyle.selectedUsersCountContainer}>
                <div className={classNames(tableStyle.selectButton, tableStyle.selectedUsersCount)}>
                    {selectedUsersCount != 0
                        ? t("UserGroups.manageUserGroupsView.selectedUsers.count", {
                              usersCount: selectedUsersCount,
                          })
                        : t("UserGroups.manageUserGroupsView.selectedUsers.notSelected")}
                </div>
                <div className={tableStyle.selectedUsersError}>
                    {selectedUsersCount > SELECTED_USERS_MAX_COUNT &&
                        t("UserGroups.manageUserGroupsView.selectedUsers.tooManySelected", {
                            maxUsers: SELECTED_USERS_MAX_COUNT,
                        })}
                </div>
            </div>
            <div className={tableStyle.border}>
                <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                    {data}
                </div>
                {props.tableState.cursor != null &&
                    props.tableState.usersData.length >= TABLE_PAGE_LIMIT &&
                    props.tableState.usersData.length != 0 &&
                    !props.loading && (
                        <button
                            className={buttonStyle.loadMoreButtonWithoutIcon}
                            onClick={(e) => {
                                e.preventDefault();
                                props.setLoadMore(true);
                                usageStatisticsService.sendEvent({
                                    action: Action.LOAD_MORE,
                                    category: Category.USER,
                                });
                            }}
                            data-testid={testIds.common.primaryView.table.loadMoreButton}
                        >
                            {t("Common.loadMore")}
                        </button>
                    )}
            </div>
        </div>
    );
};

export default UserList;
