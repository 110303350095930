import React from "react";
import { useTranslation } from "react-i18next";

import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    showForm: (value: boolean) => void;
    licenseFetchFailed?: (value: boolean) => void;
    loading: (value: boolean) => void;
}

const AddLicenseAlerts = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const showCreateView = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.showForm(true);
    };
    return (
        <div>
            <button
                className={buttons.primaryButtonWithoutIcon}
                onClick={showCreateView}
                data-testid={testIds.workArea.license.licenseAlerts.addButton}
            >
                {t("LicenseAlerts.create")}
            </button>
        </div>
    );
};
export default AddLicenseAlerts;
