import classNames from "classnames";
import React, { useCallback } from "react";

import { CUSTOM_REPORT_FIELD_MAX_LENGTH } from "domain/globalConstants";
import form from "styles/form.scss";

import testIds from "testIds.json";

export const InputField = React.memo(
    ({
        id,
        setFieldValue,
        label,
        dataPath,
    }: {
        id: string;
        setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
        label: string;
        dataPath: string;
    }) => {
        const handleFieldChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const trimmedValue = event.target.value.trim();
                setFieldValue(dataPath, trimmedValue);
            },
            [setFieldValue]
        );

        return (
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor={id} className={form.label}>
                    {label}
                </label>
                <input
                    id={id}
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    onChange={handleFieldChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path={dataPath}
                />
            </div>
        );
    }
);
