import classNames from "classnames";
import { TFunction } from "i18next";
import React from "react";
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from "react-select-search";
import ReactTooltip from "react-tooltip";

import style from "./advanced-search-view.scss";
import { State } from "./AdvancedSearchView";
import groupConditionStyle from "./group-condition/group-condition.scss";
import { GroupCondition } from "./group-condition/GroupCondition";
import { validateFilter } from "./validation";
import { ButtonContainer, ButtonContainerDivider } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import { DropdownMenu } from "components/dropdown-menu/DropdownMenu";
import ToggleablePanel from "components/header/ToggleablePanel";
import iconButtonStyle from "components/icon-button/icon-button.scss";
import IconButton from "components/icon-button/IconButton";
import AddNewIcon from "components/icons/AddNewIcon";
import Chevron from "components/icons/Chevron";
import Delete from "components/icons/Delete";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import { MAXIMUM_FILTER_COUNT } from "components/reports/ManageReportViewForm";
import {
    CustomReportViewFilter,
    CustomReportViewFilterGroup,
    isFilterGroup,
    Path,
    PathType,
    toPathType,
} from "domain/reports";
import { UsersData, UserStatus, UserTableData } from "domain/users";
import { deriveProductName } from "services/report/erasure/ReportService";
import {
    Product,
    ReportPath,
    ReportViewFilterOperator,
    reportViewService,
    toFilterOperator,
} from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userService } from "services/user/users/UserService";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export interface Props {
    filter: CustomReportViewFilterGroup;
    first: boolean;
    paths: ReportPath[];
    preventAdditionalFilters: boolean;
    t: TFunction;
    theme: typeof defaultColor | typeof tenantColor;
    translatePath: (path: string) => string;
    update: (filters?: CustomReportViewFilterGroup) => void;
    visibleValidationErrors: boolean;
    state: State;
    setStateUsersList: (usersData: UsersData) => void;
    groupDepth: number;
    validFilterCheck: (setAllFiltersValid: boolean) => void;
    validateName: boolean;
    resetTouchedFilters: () => void;
}

function createFilterPathsFunction(options: SelectSearchOption[]): (searchInput: string) => SelectSearchOption[] {
    return (searchInput: string) => filterPaths(options, searchInput);
}

function createProductIdToPathMap(paths: ReportPath[]): Map<string, string[]> {
    return paths
        .map((each) => each.productIds.map((productId) => [productId.toString(), each.path]))
        .flat()
        .reduce<Map<string, string[]>>((accruing: Map<string, string[]>, [productId, path]: [string, string]) => {
            const paths =
                accruing.get(productId) ??
                (() => {
                    const productPaths: string[] = [];
                    accruing.set(productId, productPaths);
                    return productPaths;
                })();
            paths.push(path);
            return accruing;
        }, new Map<string, string[]>());
}

interface PathSelection {
    available: SelectSearchOption[];
}

function sortAvailable(available: SelectSearchOption[]): SelectSearchOption[] {
    return available.sort((first, second) => first.name.localeCompare(second.name));
}

/**
 * Within reportPaths, return those that can be used as filters.
 */
function filterWithSupportedPathTypes(reportPaths: ReportPath[]): ReportPath[] {
    // "Pretty stupid so fix this somehow. Maybe change definition of pathType in ReportPath." - Väinö
    // "Nah, this will do for now. The original author may feel free to return to this if he ever finds this again." - Mikko
    // "Challenge accepted." - Väinö
    const types: PathType[] = ["STRING", "ONLY_STRING", "KEYWORD"];
    const strings: Set<string> = new Set(types.map((each) => each.toString()));
    if (reportPaths.findIndex((ele) => ele.path == Path.REPORT_OWNER) == -1) {
        reportPaths.push({
            path: Path.REPORT_OWNER,
            pathType: "KEYWORD",
            productIds: [],
            addDate: "",
            origin: "",
        });
    }
    return reportPaths.filter((each) => strings.has(each.pathType));
}

/*
function createTooltip(visibleText: string): JSX.Element {
    // TODO BCC-3344: Add tooltip when there's report paths to be shown in the selector. 
    // TODO BCC-3344: Show tooltip only when text is truncated.
    // TODO BCC-3344: See if tooltip can be place so that it doesn't hide options behind it.
    return (
        <Tooltip maxWidth={350} delay={[300, 0]} placement={"auto"} content={visibleText}>
            <span>{visibleText}</span>
        </Tooltip>
    );
}
*/

export const PATH_TYPE_TO_OPERATORS: Map<PathType, ReportViewFilterOperator[]> = new Map([
    ["KEYWORD", ["EQUAL", "EXIST", "VALUE_STARTS_WITH"]],
    ["ONLY_STRING", ["EXIST", "MATCH", "WORD_STARTS_WITH"]],
    ["STRING", ["EQUAL", "EXIST", "MATCH", "VALUE_STARTS_WITH", "WORD_STARTS_WITH"]],
]);

const EXIST_OPERATOR = "EXIST";
const DEFAULT_OPERATOR = EXIST_OPERATOR;
const OPERATOR_TO_TRANSLATION_KEY_MAP: Record<ReportViewFilterOperator, string> = {
    EQUAL: "CreateReportView.form.operator.equal",
    EXIST: "CreateReportView.form.operator.exist",
    MATCH: "CreateReportView.form.operator.match",
    VALUE_STARTS_WITH: "CreateReportView.form.operator.valueStartsWith",
    WORD_STARTS_WITH: "CreateReportView.form.operator.wordStartsWith",
};

const PATH_TO_FILTERS = new Map([
    [
        Path.ERASURE_STATE,
        [
            {
                name: "",
                value: "Common.select",
            },
            {
                name: "Successful",
                value: "reportFilter.erasureState.successful",
            },
            {
                name: "failed",
                value: "reportFilter.erasureState.failed",
            },
            {
                name: "Completed with exceptions",
                value: "reportFilter.erasureState.completedWithExceptions",
            },
            {
                name: "Cancelled",
                value: "reportFilter.erasureState.cancelled",
            },
        ],
    ],
    [
        Path.PRODUCT_ID,
        [
            {
                name: "",
                value: "Common.select",
            },
        ],
    ],
    [
        Path.JOURNEY_TYPE,
        [
            {
                name: "",
                value: "Common.select",
            },
            {
                name: "insurance",
                value: "reportFilter.journeyType.insurance",
            },
            {
                name: "validation",
                value: "reportFilter.journeyType.validation",
            },
            {
                name: "bbti",
                value: "reportFilter.journeyType.buyBackTradeIn",
            },
            {
                name: "ntf",
                value: "reportFilter.journeyType.myDeviceHealth",
            },
            {
                name: "usdk",
                value: "reportFilter.journeyType.usdk",
            },
        ],
    ],
    [
        Path.REPORT_OWNER,
        [
            {
                name: "",
                value: "Common.select",
            },
        ],
    ],
]);

function deriveFilterValidationErrorMessage(filter: CustomReportViewFilter, t: TFunction): string | undefined {
    const valid = validateFilter(true, filter);
    if (valid == null) {
        return;
    }
    switch (valid) {
        case "EMPTY_NAME":
            return t("AdvancedSearch.emptyFieldMessage");
        case "EMPTY_VALUE":
            return t("CreateReportView.form.emptyFieldMessage");
        case "CONTAINS_WILDCARD":
            return t("CreateReportView.form.wildcardMessage");
        default:
            throw new Error(`Unknown error type received: ${valid}.`);
    }
}

const MAX_GROUP_DEPTH = 3;

function filterPaths(options: SelectSearchOption[], searchInput: string): SelectSearchOption[] {
    const lowered = searchInput.toLowerCase();
    return options.filter(
        (each) =>
            each.value.toString().toLowerCase().includes(lowered) || each.name.toLocaleLowerCase().includes(lowered)
    );
}

export default function FilterGroup(props: Props): JSX.Element {
    const [usersList, setUsersList] = React.useState<UsersData>({
        cursor: "",
        count: -1,
        userTableData: [],
    });
    const isAtMaxDepth = props.groupDepth === MAX_GROUP_DEPTH;
    const pathTypeName = filterWithSupportedPathTypes(props.paths);
    const pathSelection: PathSelection = {
        available: sortAvailable(
            pathTypeName
                .map((each) => each.path)
                // .filter((each) => !selectedPaths.has(each))
                .map((path) => ({
                    name: props.translatePath(path),
                    value: path,
                }))
        ),
    };
    const productIdToPaths = createProductIdToPathMap(props.paths);
    const commonPaths: SelectSearchOption[] = [
        // ...filters,
        { name: props.t("ErasureReportsTable.reportVerification"), value: Path.VERIFIED },
        { name: props.t("ErasureReportsTable.productName"), value: Path.PRODUCT_ID },
        { name: "Report owner", value: Path.REPORT_OWNER },
    ];

    const currentSelectedLogicalOperator =
        props.filter.filters && props.filter.filters[0] && props.filter.filters[0].logicOperator;

    const fetchAllUsers = () => {
        if (props.state.usersList.count == -1) {
            const abortController: AbortController = new AbortController();
            userService
                .fetchAllUsers(abortController, "true")
                .then((usersData) => {
                    props.setStateUsersList(usersData);
                    setUsersList(usersData);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        logger.error("signal to fetch users list aborted");
                    }
                });
        } else {
            setUsersList(props.state.usersList);
        }
    };

    const products = Array.from(productIdToPaths.keys()).map((id) => ({ id, name: deriveProductName(id) }));
    const conditionDropdown = (index: number): JSX.Element => {
        const filter = extractFilterWithIndex(index);
        if (filter.logicOperator == null) {
            return <span>{props.t("AdvancedSearch.where")}</span>;
        }
        return (
            <button
                type="button"
                className={groupConditionStyle.conditionButton}
                onClick={() => {
                    const updated: CustomReportViewFilterGroup = {
                        ...props.filter,
                        filters: (props.filter.filters ?? []).map((each) => {
                            if (isFilterGroup(each)) {
                                return each;
                            }
                            return {
                                ...each,
                                logicOperator: filter.logicOperator === "AND" ? "OR" : "AND",
                            };
                        }),
                    };

                    props.update(updated);
                }}
                data-tip
                data-for="conditionDropdown"
                value={filter.logicOperator}
            >
                {filter.logicOperator === "AND" ? props.t("Common.and") : props.t("Common.or")}
                <Chevron color={props.theme.iconFillColor} className={""} />
            </button>
        );
    };
    function extractFilterWithIndex(index: number): CustomReportViewFilter {
        if (props.filter.filters == null) {
            throw new Error("Should be impossible but filters is null: " + props.filter.filters);
        }
        const filter = props.filter.filters[index];
        if (isFilterGroup(filter)) {
            throw new Error("Should be impossible but filter is a filter group: " + filter);
        }
        return filter;
    }
    const extractValue = (index: number): string => {
        const filter = extractFilterWithIndex(index);
        const value = filter.value;
        if (filter.name === Path.REPORT_OWNER && usersList.count != -1 && value !== "") {
            const filteredUser = usersList.userTableData.find((user) => user.uuid === filter.value);
            if (filteredUser) {
                return value;
            }
            const userData = usersList.userTableData;
            userData.push({
                name: props.t("Common.unknownUser", { uuid: value }),
                uuid: value,
                groups: [],
                status: UserStatus.UNKNOWN,
                createdDate: "",
                email: props.t("Common.unknownUser", { uuid: value }),
                emailVerified: false,
                roleUuid: "",
                expirationDate: "",
                enabled: false,
                loginMethod: "",
                poolName: "",
                userType: "",
            });
            const usersData: UsersData = {
                cursor: "",
                count: userData.length,
                userTableData: userData,
            };
            props.setStateUsersList(usersData);
            setUsersList(usersData);
        }
        return value;
    };
    const createValueSelect = (index: number, errorMessage?: string) => {
        const onFilterValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const value = event.target.value;
            if (props.filter.filters == null) {
                throw new Error("It should be impossible that group's filters is null: " + props.filter);
            }
            const updated: CustomReportViewFilterGroup = {
                ...props.filter,
                filters: props.filter.filters.map((each, filterIndex) => {
                    if (isFilterGroup(each) || filterIndex !== index) {
                        return each;
                    }
                    return { ...each, value };
                }),
            };
            props.update(updated);
        };
        const filter = extractFilterWithIndex(index);
        const createSelect = (options: JSX.Element[]): JSX.Element => {
            return (
                <div className={style.fullWidth}>
                    <select
                        name={filter.name}
                        className={classNames(form.select, style.selectArrow, style.fullWidth)}
                        onChange={onFilterValueChange}
                        value={extractValue(index)}
                        data-testid={
                            testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.valueSelect.itself
                        }
                    >
                        {options}
                    </select>
                    <div className={form.error}>{errorMessage}</div>
                </div>
            );
        };

        if (filter.operator === EXIST_OPERATOR) {
            return createSelect([
                <option value="" key="">
                    {props.t("Common.select")}
                </option>,
                <option value="true" key="true">
                    {props.t("Common.true")}
                </option>,
                <option value="false" key="false">
                    {props.t("Common.false")}
                </option>,
            ]);
        }

        let selectFilters = PATH_TO_FILTERS.get(filter.name as Path);
        if (selectFilters != null) {
            if (filter.name === Path.REPORT_OWNER) {
                if (usersList.count == -1) {
                    fetchAllUsers();
                    return (
                        <div className={style.loaderContainer}>
                            <LoadingIndicator small={true} />
                        </div>
                    );
                } else {
                    selectFilters = selectFilters.concat(
                        usersList.userTableData.map((user: UserTableData) => ({
                            name: user.uuid,
                            value: user.name || user.email,
                        }))
                    );
                }
            }
            if (filter.name === Path.PRODUCT_ID) {
                selectFilters = selectFilters.concat(
                    products.map((product: Product) => ({ name: product.id, value: product.name }))
                );
            }
            return createSelect(
                selectFilters.map((filterValue) => (
                    <option value={filterValue.name} key={filterValue.name}>
                        {props.t(filterValue.value)}
                    </option>
                ))
            );
        }

        return (
            <div className={style.fullWidth}>
                <input
                    onChange={onFilterValueChange}
                    name={filter.name}
                    value={filter.value}
                    type={"text"}
                    className={classNames(form.input, style.filterInput, style.fullWidth)}
                    data-testid={
                        testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.valueInput.itself
                    }
                ></input>
                <div className={classNames(form.error, style.filterErrorLabel)}>{errorMessage}</div>
            </div>
        );
    };
    const createTableContent = (innerFilter: CustomReportViewFilterGroup): JSX.Element[][] => {
        if (!(innerFilter && innerFilter.filters != null && innerFilter.filters.length > 0)) {
            return [
                [
                    <div key={0}></div>,
                    <div className={style.emptyMessage} key={1}>
                        {props.t("CreateReportView.form.emptyStateMessage")}
                    </div>,
                    <div key={2}></div>,
                ],
            ];
        }
        const filterAvailableByProduct = (): SelectSearchOption[] => {
            const deriveAcceptablePaths = (): string[] => {
                const generateCommonPaths = () => commonPaths.map((each) => each.value.toString());
                return [...Array.from(productIdToPaths.values()).flat(), ...generateCommonPaths()];
            };
            const acceptablePaths = new Set(deriveAcceptablePaths());
            return pathSelection.available.filter((each) => acceptablePaths.has(each.value.toString()));
        };
        const createOperatorSelect = (index: number, pathType?: PathType): JSX.Element => {
            const filter = extractFilterWithIndex(index);
            if (pathType == null) {
                pathType = toPathType(reportViewService.getPaths().find((each) => each.path === filter.name)?.pathType);
                filter.pathType = pathType;
            }
            if (!PATH_TYPE_TO_OPERATORS.has(pathType)) {
                logger.error(`No operators found for path type ${pathType}.`);
            }
            let operators = PATH_TYPE_TO_OPERATORS.get(pathType) ?? [DEFAULT_OPERATOR];
            if (filter.name === Path.REPORT_OWNER) {
                operators = operators.filter((each) => each !== "VALUE_STARTS_WITH");
            }
            const options = operators.map((each) => {
                const key = OPERATOR_TO_TRANSLATION_KEY_MAP[each];
                return (
                    <option key={each} value={each}>
                        {props.t(key)}
                    </option>
                );
            });
            // Obviously operator should always be included but if it isn't, at least
            // the UI isn't bricked and the end user has a way to move forward and try
            // to fix the situation. Even if she's not immediately notified and
            // doesn't notice for some time.
            const selectedValue = operators.find((each) => each === filter.operator) ?? DEFAULT_OPERATOR;
            filter.operator = selectedValue;

            const onChangeWrapper = (event: React.ChangeEvent<HTMLSelectElement>) => {
                usageStatisticsService.sendEvent({
                    action: Action.CHANGE_FILTER_OPERATOR,
                    category: Category.ADVANCED_SEARCH,
                });
                if (event.currentTarget.value == null) {
                    throw new Error("Target value is null in event handler for filter operator.");
                }
                const operator = toFilterOperator(event.currentTarget.value);
                const updated: CustomReportViewFilterGroup = {
                    ...props.filter,
                    filters: (props.filter.filters ?? []).map((each, filterIndex) => {
                        if (index !== filterIndex) {
                            return each;
                        }
                        if (isFilterGroup(each)) {
                            return each;
                        }
                        return { ...each, operator };
                    }),
                };
                props.update(updated);
            };

            return (
                <select
                    className={form.select}
                    value={selectedValue}
                    onChange={onChangeWrapper}
                    data-testid={
                        testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.operatorSelect.itself
                    }
                >
                    {options}
                </select>
            );
        };
        const removeRow = (index: number) => {
            const updated: CustomReportViewFilterGroup = {
                ...props.filter,
                filters: (props.filter.filters || []).filter((_, filterIndex) => filterIndex != index),
            };

            props.update(updated);
        };
        const updateFilterPath = (index: number, path: SelectedOptionValue) => {
            const updated: CustomReportViewFilterGroup = {
                ...props.filter,
                filters: (props.filter.filters ?? []).map((each, filterIndex) => {
                    if (filterIndex != index || isFilterGroup(each)) {
                        return each;
                    }
                    return {
                        ...each,
                        name: path,
                    };
                }),
            };
            props.update(updated);
        };
        return innerFilter.filters.map((filterInput, index) => {
            if (isFilterGroup(filterInput)) {
                return [
                    <FilterGroup
                        key={"nestedFilterGroup"}
                        filter={filterInput}
                        first={index == 0}
                        paths={props.paths}
                        preventAdditionalFilters={props.preventAdditionalFilters}
                        t={props.t}
                        theme={props.theme}
                        translatePath={props.translatePath}
                        update={(filters?: CustomReportViewFilterGroup) => {
                            if (props.filter.filters == null) {
                                throw new Error("This is impossible! If there's no filters, who called this callback?");
                            }
                            if (filters == null) {
                                props.update({
                                    ...props.filter,
                                    filters: props.filter.filters.filter((_, filterIndex) => filterIndex != index),
                                });
                                return;
                            }
                            const updated = props.filter.filters.map((each, filterIndex) =>
                                isFilterGroup(each) && filterIndex === index ? filters : each
                            );
                            props.update({
                                ...props.filter,
                                filters: updated,
                            });
                        }}
                        visibleValidationErrors={props.visibleValidationErrors}
                        state={props.state}
                        setStateUsersList={props.setStateUsersList}
                        groupDepth={props.groupDepth + 1}
                        validFilterCheck={props.validFilterCheck}
                        validateName={props.validateName}
                        resetTouchedFilters={props.resetTouchedFilters}
                    />,
                ];
            }

            const errorMessage = props.visibleValidationErrors
                ? deriveFilterValidationErrorMessage(filterInput, props.t)
                : undefined;

            if (errorMessage != undefined || props.validateName) {
                props.validFilterCheck(true);
            } else {
                props.validFilterCheck(false);
            }
            return [
                <div key={0} className={groupConditionStyle.conditionContainer}>
                    {index == 0 ? (
                        <div className={groupConditionStyle.conditionSelector}>{props.t("AdvancedSearch.where")}</div>
                    ) : index === 1 ? (
                        <>
                            {/* Clicking condition dropdown on the second filters changes the condition for all filters 
                                in the same group/level. */}

                            {conditionDropdown(index)}

                            <ReactTooltip id="conditionDropdown" effect="solid" className={style.tooltip}>
                                {props.t("AdvancedSearch.conditionDropdownTooltip")}
                            </ReactTooltip>
                        </>
                    ) : (
                        <div className={groupConditionStyle.conditionSelector}>
                            {filterInput.logicOperator === "AND" ? props.t("Common.and") : props.t("Common.or")}
                        </div>
                    )}
                </div>,
                <div key={1} className={style.cellSpacing}>
                    <SelectSearch
                        closeOnSelect={true}
                        multiple={false}
                        onChange={(selected: SelectedOptionValue) => {
                            if (!selected) {
                                return;
                            }
                            usageStatisticsService.sendEvent({
                                action: Action.CHANGE_FILTER_PATH,
                                category: Category.ADVANCED_SEARCH,
                            });
                            updateFilterPath(index, selected);
                        }}
                        filterOptions={createFilterPathsFunction}
                        renderOption={(renderProps, option) => {
                            // Typing system claims that this is SelectedOption even thou it seems to be
                            // SelectedOptionValue. The latter contains the needed "name". So it seems we're once
                            // again forced to go around TypeScript's typing.
                            const { name } = option as unknown as { name: string };

                            return (
                                <button
                                    {...renderProps}
                                    // This smells big time, but the event handler types from SelectSearch's
                                    // DomProps are not compatible with the HTML button.
                                    tabIndex={Number(renderProps.tabIndex)}
                                    onMouseDown={(e) => renderProps.onMouseDown?.(e as unknown as MouseEvent)}
                                    onKeyDown={(e) => renderProps.onKeyDown?.(e as unknown as KeyboardEvent)}
                                    onKeyPress={(e) => renderProps.onKeyPress?.(e as unknown as KeyboardEvent)}
                                    onBlur={(e) => renderProps.onBlur?.(e as unknown as KeyboardEvent)}
                                    className={"src-components-reports-create-report-view-modal__select__option"}
                                >
                                    {name}
                                </button>
                            );
                        }}
                        options={filterAvailableByProduct()}
                        search={true}
                        placeholder={props.t("AdvancedSearch.selectOrTypeField")}
                        value={filterInput.name}
                        className={classNames(form.select, style.selectSearch, {
                            [style.optionSelected]: filterInput.value != "",
                        })}
                    />
                </div>,
                <div key={2} className={style.cellSpacing}>
                    {createOperatorSelect(index, filterInput.name == "" ? "STRING" : undefined)}
                </div>,
                <React.Fragment key={3}>
                    <div key={"3-1"} className={style.cellWithIcon}>
                        {createValueSelect(index, errorMessage)}
                        <IconButton
                            className={style.deleteButtonContainer}
                            key={4}
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.REPORT_VIEW,
                                    action: Action.REMOVE_FILTER,
                                });
                                removeRow(index);
                            }}
                            testId={
                                testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable
                                    .removeFilterButton
                            }
                        >
                            {<Delete noBackground={true} strokeColor={props.theme.errorIconColor} />}
                        </IconButton>
                    </div>
                </React.Fragment>,
            ];
        });
    };
    const newFilter: CustomReportViewFilter = {
        name: "",
        value: "",
        operator: "MATCH",
        pathType: "STRING",
        logicOperator: "AND",
    };
    const addRow = () => {
        props.resetTouchedFilters();
        const updated: CustomReportViewFilterGroup = {
            ...props.filter,
            filters: [...(props.filter.filters ?? []), newFilter],
        };
        props.update(updated);
    };

    return (
        <>
            {!props.first && (
                <div className={groupConditionStyle.groupConditionContainer}>
                    <button
                        className={groupConditionStyle.conditionButton}
                        onClick={() => {
                            const updated: CustomReportViewFilterGroup = {
                                ...props.filter,
                                logicOperator: props.filter.logicOperator === "AND" ? "OR" : "AND",
                            };
                            props.update(updated);
                        }}
                        type="button"
                    >
                        {props.filter.logicOperator === "AND" ? props.t("Common.and") : props.t("Common.or")}
                        <Chevron color={props.theme.iconFillColor} className={""} />
                    </button>
                </div>
            )}
            <GroupCondition
                tableCells={createTableContent(props.filter)}
                firstGroup={props.first}
                depth={props.groupDepth}
                logicalOperator={currentSelectedLogicalOperator ?? "AND"}
                headerButtons={
                    <>
                        {props.groupDepth > 0 && (
                            <>
                                <div
                                    {...((isAtMaxDepth || props.preventAdditionalFilters) && {
                                        "data-tip": true,
                                        "data-for": "maxDepthTooltip",
                                    })}
                                >
                                    <ToggleablePanel
                                        title={""}
                                        testId={""}
                                        menuType={"HEADER"}
                                        icon={
                                            <AddNewIcon
                                                className={style.addNewIcon}
                                                color={props.theme.iconFillColor}
                                            />
                                        }
                                        buttonClass={classNames(iconButtonStyle.iconButton, style.dropdownToggle)}
                                        hideChevron={true}
                                        disabled={isAtMaxDepth || props.preventAdditionalFilters}
                                    >
                                        <DropdownMenu
                                            menuItems={[
                                                <MenuItemButton
                                                    key="1"
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.ADVANCED_SEARCH,
                                                            action: Action.ADD_FILTER,
                                                        });
                                                        addRow();
                                                    }}
                                                    disabled={isAtMaxDepth || props.preventAdditionalFilters}
                                                >
                                                    {props.t("AdvancedSearch.addCondition")}
                                                </MenuItemButton>,
                                                <MenuItemButton
                                                    key="2"
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.ADVANCED_SEARCH,
                                                            action: Action.ADD_FILTER_GROUP,
                                                        });
                                                        const updated: CustomReportViewFilterGroup = {
                                                            ...props.filter,
                                                            filters: [
                                                                ...(props.filter.filters ?? []),
                                                                {
                                                                    filters: [newFilter, newFilter],
                                                                    logicOperator: "AND",
                                                                },
                                                            ],
                                                        };

                                                        props.update(updated);
                                                    }}
                                                    disabled={isAtMaxDepth || props.preventAdditionalFilters}
                                                >
                                                    {props.t("AdvancedSearch.addGroup")}
                                                </MenuItemButton>,
                                            ]}
                                        />
                                    </ToggleablePanel>
                                    {(isAtMaxDepth || props.preventAdditionalFilters) && (
                                        <ReactTooltip id="maxDepthTooltip" effect="solid" className={style.tooltip}>
                                            {props.preventAdditionalFilters
                                                ? props.t("AdvancedSearch.maximumFilterAmountReached", {
                                                      maxFilters: MAXIMUM_FILTER_COUNT,
                                                  })
                                                : props.t("AdvancedSearch.maximumGroupDepthReached", {
                                                      maxDepth: MAX_GROUP_DEPTH,
                                                  })}
                                        </ReactTooltip>
                                    )}
                                </div>
                                <IconButton
                                    key={4}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORT_VIEW,
                                            action: Action.REMOVE_FILTER_GROUP,
                                        });
                                        props.update();
                                    }}
                                    testId={
                                        testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable
                                            .removeFilterButton
                                    }
                                >
                                    {<Delete noBackground={true} strokeColor={props.theme.errorIconColor} />}
                                </IconButton>
                            </>
                        )}
                    </>
                }
                footerButtons={
                    <>
                        {props.groupDepth === 0 && (
                            <div className={style.conditionsButtonContainer}>
                                <ButtonContainer alignment="LEFT" noSpacingTop>
                                    <Button
                                        variant={"TERTIARY"}
                                        customIcon={
                                            <AddNewIcon
                                                className={style.addNewIcon}
                                                color={props.theme.iconFillColor}
                                            />
                                        }
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.ADVANCED_SEARCH,
                                                action: Action.ADD_FILTER,
                                            });
                                            addRow();
                                        }}
                                        type="button"
                                        disabled={props.preventAdditionalFilters}
                                        {...(props.preventAdditionalFilters && {
                                            "data-tip": true,
                                            "data-for": "maxDepthTooltip",
                                        })}
                                    >
                                        {props.t("AdvancedSearch.addCondition")}
                                    </Button>

                                    <ButtonContainerDivider />

                                    <Button
                                        variant={"TERTIARY"}
                                        customIcon={
                                            <AddNewIcon
                                                className={style.addNewIcon}
                                                color={props.theme.iconFillColor}
                                            />
                                        }
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.ADVANCED_SEARCH,
                                                action: Action.ADD_FILTER_GROUP,
                                            });
                                            const updated: CustomReportViewFilterGroup = {
                                                ...props.filter,
                                                filters: [
                                                    ...(props.filter.filters ?? []),
                                                    { filters: [newFilter, newFilter], logicOperator: "AND" },
                                                ],
                                            };
                                            props.resetTouchedFilters();
                                            props.update(updated);
                                        }}
                                        type="button"
                                        disabled={props.preventAdditionalFilters}
                                        {...(props.preventAdditionalFilters && {
                                            "data-tip": true,
                                            "data-for": "maxDepthTooltip",
                                        })}
                                    >
                                        {props.t("AdvancedSearch.addGroup")}
                                    </Button>
                                </ButtonContainer>
                            </div>
                        )}
                    </>
                }
            />
        </>
    );
}
