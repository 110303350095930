import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Column } from "react-table";

import style from "./import-job-upload-failed-files-view.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Warning from "components/icons/Warning";
import { FailedFile } from "components/reports/import/ImportReportsDialog";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface Props {
    totalUploads: number;
    onUploadAnotherClicked: () => void;
    onClose: () => void;
    failedUploads: FailedFile[];
}

const ImportJobUploadFailedFilesView = (props: Props) => {
    const { t } = useTranslation();
    const failedUploadedFilesTableRef = React.useRef<HTMLDivElement>(null);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const upoadAnotherFileButton = props.failedUploads.length != props.totalUploads && (
        <button
            className={classNames(buttons.primaryButtonWithoutIcon, style.button)}
            onClick={() => props.onUploadAnotherClicked()}
            data-testid={testIds.common.importFileDialog.uploadAnotherButton}
        >
            {t("Common.uploadAnotherFile")}
        </button>
    );
    const FailedUploadFilesColumns: Array<Column<FailedFile>> = [
        {
            Header: () => (
                <TextWithTooltip
                    text={t("ImportReportsDialog.importReports.uploadingFailedTableColumnTitle")}
                    key="filename"
                />
            ),
            accessor: "filename",
            Cell: (cellInfo) => <TextWithTooltip text={cellInfo.value} />,
            width: deriveColumnWidth(60, failedUploadedFilesTableRef),
        },
    ];
    const message =
        props.failedUploads.length == props.totalUploads
            ? t("ImportReportsDialog.importReports.uploadingFailedMessage", {
                  failed: props.failedUploads.length,
                  total: props.totalUploads,
              })
            : t("ImportReportsDialog.importReports.uploadingFailedWithSuccessMessage", {
                  failed: props.failedUploads.length,
                  total: props.totalUploads,
                  uploaded: props.totalUploads - props.failedUploads.length,
              });

    return (
        <div
            className={style.failedFilesViewWrapper}
            data-testid={testIds.workArea.report.reportImportDialog.uploadFailedView.itself}
        >
            <div className={style.introductionLabel}>
                <div>
                    <Warning color={theme.chartNegativeColor} width={20} height={18} />
                </div>
                <div data-testid={testIds.workArea.report.reportImportDialog.uploadFailedView.messageLabel}>
                    {message}
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={failedUploadedFilesTableRef}>
                <Table
                    tableIdentity={RepositoryKey.REPORT_IMPORT_FAILED_UPLOAD_TABLE}
                    data={props.failedUploads}
                    columns={FailedUploadFilesColumns}
                    loading={false}
                    loaded={true}
                    tooltips={true}
                    dialogHeight={350}
                    testId={testIds.workArea.report.reportImportDialog.uploadFailedView.resultTable}
                />
            </div>
            <ButtonContainer>
                {upoadAnotherFileButton}
                <button
                    className={buttons.primaryOkButton}
                    onClick={() => props.onClose()}
                    data-testid={
                        props.failedUploads.length == props.totalUploads
                            ? testIds.common.dialog.closeButton
                            : testIds.workArea.report.reportImportDialog.uploadFailedView.nextButton
                    }
                >
                    {props.failedUploads.length == props.totalUploads ? t("Common.ok") : t("Common.next")}
                </button>
            </ButtonContainer>
        </div>
    );
};

export default ImportJobUploadFailedFilesView;
