import { CustomReportViewFilterGroup, toCustomReportViewFilterDto } from "domain/reports";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { prepareFilters, replacePaths } from "services/report/erasure/ReportService";

const PATH_API_REPORTS = "/api/reports/";
const PATH_API_REPORT_EXPORT = PATH_API_REPORTS + "export-all";
const PATH_API_REPORT_PRINT = PATH_API_REPORTS + "print";

export function extractFilename(disposition: string | null) {
    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            const contentDispositionFileName = matches[1].replace(/['"]/g, "");
            return contentDispositionFileName;
        }
    }
    return "File path not found.";
}

class ReportExportService {
    public exportProcessingView(
        format: string,
        search: string,
        templateUuid: string | null,
        abortController: AbortController,
        combined: boolean,
        humanReadableNumbers: boolean,
        localizedColumns: boolean,
        startDate?: string,
        endDate?: string,
        columns?: string[],
        filters?: CustomReportViewFilterGroup,
        language?: string,
        reportUuids?: string[],
        filenameColumns?: string[],
        recordSeparator?: string,
        subRecordSeparator?: string,
        separator?: string
    ): Promise<void> {
        const preparedFilters = prepareFilters(filters);
        const body = {
            format,
            start_date: startDate,
            end_date: endDate,
            search,
            template_uuid: templateUuid,
            columns: replacePaths(columns, format),
            language: language,
            report_uuids: reportUuids,
            combined: combined,
            csv_separator: recordSeparator,
            csv_sub_separator: subRecordSeparator,
            human_readable_numbers: humanReadableNumbers,
            localized_columns: localizedColumns,
            ...(filenameColumns == null || filenameColumns.length === 0
                ? {}
                : {
                      filename_columns: replacePaths(filenameColumns, format),
                      filename_separator: separator,
                  }),
            ...(preparedFilters == null || preparedFilters.filters.length === 0
                ? {}
                : {
                      filters: preparedFilters.filters.map(toCustomReportViewFilterDto),
                      filter_logic: preparedFilters.logic,
                  }),
        };
        return apiGatewayService.invokeApi(PATH_API_REPORT_EXPORT, "POST", body, {
            abortController: abortController,
            refreshSession: false,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public printReports(
        reportUuids: string[],
        format: string,
        language: string,
        abortController: AbortController
    ): Promise<string> {
        return apiGatewayService
            .invokeApi(
                PATH_API_REPORT_PRINT,
                "POST",
                {
                    format,
                    language: language,
                    report_uuids: reportUuids,
                },
                {
                    abortController: abortController,
                    refreshSession: false,
                    apiType: ApiType.OLIVER,
                    jsonResponse: false,
                }
            )
            .then((res: Response) => {
                return res.text();
            });
    }

    public exportReportBySelectFormat(
        urlPath: string,
        abortController: AbortController,
        humanReadableNumbers: boolean,
        localizedColumns: boolean,
        filenameColumns?: string[],
        separator?: string,
        subRecordSeparator?: string,
        recordSeparator?: string
    ): Promise<void | Response> {
        let postData = {
            csv_separator: recordSeparator,
            csv_sub_separator: subRecordSeparator,
            human_readable_numbers: humanReadableNumbers,
            localized_columns: localizedColumns,
        };

        if (filenameColumns?.length != 0 && filenameColumns != undefined) {
            const fileNameData = {
                filename_columns: replacePaths(filenameColumns),
                filename_separator: separator,
            };
            postData = { ...postData, ...fileNameData };
        }
        return apiGatewayService
            .invokeApi(urlPath, "POST", postData, {
                abortController: abortController,
                refreshSession: false,
                apiType: ApiType.OLIVER,
                emptyResponse: false,
                jsonResponse: false,
            })
            .then((response: Response) => {
                const disposition = response.headers.get("Content-Disposition");
                const downloadFilename = extractFilename(disposition);
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", downloadFilename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                });
            });
    }
}

export const reportExportService = new ReportExportService();
