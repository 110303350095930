import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import AuthorityView from "./AuthorityView";
import style from "./manage-role.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { NOTES_MAX_LENGTH } from "domain/globalConstants";
import { Authority, Targets } from "domain/roles";
import { rolesService } from "services/roles/RolesService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    name: string;
    description?: string;
    authorities: Authority[];
    targets?: Targets;
    isManager: boolean;
    isEditing?: boolean;
    submitEventHandler: (values: FormValues) => Promise<void>;
}

export interface FormValues {
    name: string;
    description?: string;
    authorities: Authority[];
    targets?: Targets;
}

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 32;

export default function ManageRoleForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [resetCounter, setResetCounter] = React.useState<number>(0);
    const abortController = new AbortController();
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        if (values.name != null) {
            setLoading(true);
            if (values.name != props.name || !props.isEditing) {
                const matchingRoles = await rolesService.fetchRoles(abortController, values.name);
                if (matchingRoles.roles.length == 0) {
                    await props.submitEventHandler(values);
                    setSubmitting(true);
                } else {
                    setLoading(false);
                    setErrors({ name: t("ManageRoleForm.roleNameAlreadyExists") });
                }
            } else {
                await props.submitEventHandler(values);
                setSubmitting(true);
            }
        } else {
            setLoading(false);
            setErrors({ name: t("ManageRoleForm.roleNameNotAvailable") });
        }
    };
    const [descriptionCharactersLeft, setDescriptionCharactersLeft] = React.useState(NOTES_MAX_LENGTH);
    const descriptionChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescriptionCharactersLeft(event.target.maxLength - event.target.value.length);
    };
    const nameValidationMessage = t("ManageRoleForm.roleNameLength", {
        minLength: NAME_MIN_LENGTH,
        maxLength: NAME_MAX_LENGTH,
    });

    return (
        <Formik
            initialValues={{
                name: props.name,
                description: props.description,
                authorities: [...props.authorities],
                targets: props.targets,
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                name: string()
                    .min(NAME_MIN_LENGTH, nameValidationMessage)
                    .max(NAME_MAX_LENGTH, nameValidationMessage)
                    .required(t("ManageRoleForm.roleNameRequired")),
                authorities: string().required(t("ManageRoleForm.authoritiesRequired")),
            })}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                resetForm,
            }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }

                const loader = loading ? (
                    <div className={style.loaderContainer}>
                        <LoadingIndicator small={true} />
                    </div>
                ) : null;

                function createSubmitButtonContainer(): JSX.Element {
                    function wrapInContainer(...elements: JSX.Element[]): JSX.Element {
                        return <div className={form.buttonContainer}>{...elements}</div>;
                    }

                    const submitButton = (
                        <button
                            className={buttons.primaryButtonWithoutIcon}
                            type="submit"
                            disabled={isSubmitting}
                            data-testid={testIds.workArea.roles.manageRoleDialog.saveButton}
                        >
                            {props.isEditing ? t("Common.save") : t("ManageRoleForm.submitButton")}
                        </button>
                    );

                    if (props.isEditing) {
                        return wrapInContainer(
                            <button
                                className={buttons.primaryButtonWithoutIcon}
                                type="reset"
                                onClick={() => {
                                    resetForm();
                                    setResetCounter((prev) => ++prev);
                                }}
                            >
                                {t("ManageRoleForm.edit.resetButton")}
                            </button>,
                            submitButton
                        );
                    }
                    return wrapInContainer(submitButton);
                }

                return (
                    <Form>
                        <div className={form.formFields}>
                            <label htmlFor="name" className={form.label}>
                                {t("ManageRoleForm.roleName")}
                            </label>
                            <input
                                id="name"
                                className={classNames(form.input, form.fixedWidthInput, {
                                    [form.inputError]: errors.name,
                                })}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                data-testid={testIds.workArea.roles.manageRoleDialog.roleNameInput.itself}
                            />
                            {loader}
                            <div
                                className={form.error}
                                data-testid={testIds.workArea.roles.manageRoleDialog.roleNameInput.errorLabel}
                            >
                                <ErrorMessage name="name" />
                            </div>
                        </div>
                        <div className={classNames(form.formFields, form.formFieldsFlex)}>
                            <div className={form.formFieldsAlignItemsTop}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                <label htmlFor="description" className={classNames(form.label)}>
                                    {t("ManageRoleForm.description")}
                                </label>
                            </div>
                            <div className={style.descriptionContainer}>
                                <textarea
                                    id="description"
                                    className={classNames(form.input, form.fixedWidthInput, style.descriptionHeight)}
                                    maxLength={NOTES_MAX_LENGTH}
                                    onChange={(e) => {
                                        descriptionChangeHandler(e);
                                        handleChange(e);
                                    }}
                                    value={values.description}
                                    data-testid={testIds.workArea.roles.manageRoleDialog.descriptionInput.itself}
                                />
                                <span className={form.optional}>
                                    {t("Common.charactersLeft", {
                                        remainingCharacters: descriptionCharactersLeft.toString(),
                                        descriptionMaxLength: NOTES_MAX_LENGTH.toString(),
                                    })}
                                </span>
                            </div>
                        </div>

                        <AuthorityView
                            authorities={values.authorities}
                            targets={values.targets}
                            onView={false}
                            isManager={props.isManager}
                            setFieldValue={setFieldValue}
                            resetCounter={resetCounter}
                        />
                        <div
                            className={form.error}
                            data-testid={testIds.workArea.roles.manageRoleDialog.authorities.errorLabel}
                        >
                            <ErrorMessage name="authorities" />
                        </div>
                        {createSubmitButtonContainer()}
                    </Form>
                );
            }}
        </Formik>
    );
}
