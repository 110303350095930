import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { CompactPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import style from "./report-template-form.scss";
import { useOutsideClick } from "components/header/MenuPanel";
import Info from "components/icons/Info";
import {
    getHardwareSectionsKeys,
    getHardwareSectionsTestIds,
    getSectionKeys,
    getSectionKeysTestIds,
} from "components/reports/report-template/common";
import ReportTemplatePreviewModal from "components/reports/report-template/ReportTemplatePreviewModal";
import Tooltip from "components/tooltip/Tooltip";
import { ReportSection, reportSections, ReportTemplate, ReportType, TemplateTableData } from "domain/reports";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface State {
    templateName: string;
    reportType: ReportType;
    logoVisible: boolean;
    disclaimer: string;
    color: string;
    disclaimerVisible: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ReportTemplateForm = (
    props: {
        submitEventHandler: (values: ReportTemplate) => void;
        onShowModal: (doShow: boolean) => void;
        templates: TemplateTableData[];
        template?: ReportTemplate | undefined | null;
        hasDynamicSize: (initial: boolean) => void;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();
    const MAX_NAME_LENGTH = 128;
    const MAX_DISCLAIMER_LENGTH = 1000;
    const [templateState, setTemplateState] = React.useState<ReportTemplate>({
        disclaimer: "",
        logoVisible: false,
        templateName: "",
        reportType: ReportType.STANDARD,
        uuid: "",
        sections: [...(props.template ? props.template.sections : reportSections)],
    });
    const sectionsKeys: Map<string, string> = getSectionKeys();
    const sectionKeysTestIds = getSectionKeysTestIds();
    const [previewCounter, setPreviewCounter] = React.useState(0);
    const [disclaimerAdded, setDisclaimerAdded] = React.useState(false);
    const [colorPickerVisible, setColorPickerVisible] = React.useState(false);
    const DEFAULT_COLOR = props.theme.colorPickerBlack;
    const ref = useOutsideClick(() => setColorPickerVisible(false));
    const HARDWARE_SECTIONS: ReportSection[] = [
        "BATTERY_INFORMATION",
        "DEVICE_COMPONENTS",
        "HARDWARE_FUNCTIONAL_CHECKS",
    ];
    const hardwareSectionTranslationKeys = getHardwareSectionsKeys();
    const hardwareSectionTestIds = getHardwareSectionsTestIds();
    const [hardwareSectionsVisible, setHardwareSectionsVisible] = React.useState(false);
    const [selectedSections, setSelectedSections] = React.useState(templateState.sections);
    const submitHandler: FormikConfig<State>["onSubmit"] = (values: State) => {
        usageStatisticsService.sendEvent({
            category: Category.REPORT_TEMPLATE,
            action: Action.ADD_REPORT_TEMPLATE,
        });
        props.hasDynamicSize(true);
        props.submitEventHandler({
            uuid: props.template ? props.template.uuid : "",
            sections: templateState.sections,
            templateName: values.templateName.trim(),
            reportType: values.reportType,
            disclaimer: values.disclaimerVisible ? values.disclaimer : "",
            logoVisible: values.logoVisible,
            version: props.template ? props.template.version : undefined,
            disclaimerColor: values.disclaimerVisible ? values.color : "",
        });
    };

    const createLabel = (
        id: string,
        text: string | undefined,
        errorArgument?: { [p: string]: string | undefined }
    ): JSX.Element => {
        return (
            <label htmlFor={id} className={classNames(form.label, errorArgument)}>
                {text}
            </label>
        );
    };

    const createSectionCheck = (
        section: ReportSection,
        key: number,
        translationKeys: Map<string, string>,
        keyTestIds: Map<ReportSection, string>
    ): JSX.Element => {
        return (
            <div className={style.item} key={key}>
                <label className={form.container}>
                    <input
                        type={"checkbox"}
                        name={section}
                        className={form.input}
                        defaultChecked={props.template ? props.template.sections.includes(section) : true}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            if (checked) {
                                if (section === "HARDWARE_DETAILS" && selectedSections.includes(section)) {
                                    setTemplateState((prevState) => ({
                                        ...prevState,
                                        sections: selectedSections,
                                    }));
                                    setHardwareSectionsVisible(checked);
                                } else {
                                    setTemplateState((prevState) => ({
                                        ...prevState,
                                        sections: [...prevState.sections, section],
                                    }));
                                }
                            } else {
                                const hardwareDetailsList: ReportSection[] = [];
                                let tempState = templateState.sections;
                                if (section === "HARDWARE_DETAILS") {
                                    tempState.forEach((section) => {
                                        if (!HARDWARE_SECTIONS.includes(section) && section != "HARDWARE_DETAILS") {
                                            hardwareDetailsList.push(section);
                                        }
                                    });
                                    setSelectedSections(templateState.sections);
                                    setTemplateState((prevState) => ({
                                        ...prevState,
                                        sections: hardwareDetailsList,
                                    }));
                                    tempState = hardwareDetailsList;
                                    setHardwareSectionsVisible(checked);
                                } else {
                                    setTemplateState((prevState) => ({
                                        ...prevState,
                                        sections: tempState.filter((each) => each !== section),
                                    }));
                                }
                            }
                            updatePreview();
                        }}
                        data-testid={keyTestIds.get(section)}
                    />
                    <span className={form.checkmark} />
                </label>
                {createLabel(section, translationKeys.get(section))}
            </div>
        );
    };

    const createHardwareSectionCheck = (section: ReportSection, key: number): JSX.Element => {
        return (
            <div>
                {createSectionCheck(section, key, sectionsKeys, sectionKeysTestIds)}
                <div
                    hidden={!hardwareSectionsVisible && !templateState.sections.includes("HARDWARE_DETAILS")}
                    className={style.indentedField}
                >
                    {HARDWARE_SECTIONS.map((each, hardwareSectionKey) => {
                        return createSectionCheck(
                            each,
                            hardwareSectionKey,
                            hardwareSectionTranslationKeys,
                            hardwareSectionTestIds
                        );
                    })}
                </div>
            </div>
        );
    };

    const options = [
        {
            label: t("ReportTemplate.form.standardReport"),
            value: ReportType.STANDARD,
        },
        {
            label: t("ReportTemplate.form.advancedReport"),
            value: ReportType.ADVANCED,
        },
    ];

    function handleHideModal() {
        props.onShowModal(false);
        props.hasDynamicSize(true);
    }
    function isTemplateNameAvailable(templateName: string | undefined | null): boolean {
        return (
            typeof props.templates.find(
                (item) =>
                    item.templateName.trim() === templateName?.trim() &&
                    item.uuid !== (props.template ? props.template?.uuid : "")
            ) === "undefined"
        );
    }
    const updatePreview = () => {
        setPreviewCounter((prevState) => prevState + 1);
        setDisclaimerAdded(false);
    };

    const hasDisclaimer = () => {
        return props.template ? props.template.disclaimer !== null && props.template.disclaimer !== "" : false;
    };

    return (
        <div className={style.templateContainer}>
            <Formik
                initialValues={{
                    templateName: props.template ? props.template.templateName : "",
                    reportType: props.template ? props.template.reportType : ReportType.STANDARD,
                    logoVisible: props.template ? props.template.logoVisible : true,
                    disclaimer:
                        props.template === undefined ? "" : props.template?.disclaimer ? props.template.disclaimer : "",
                    color: !props.template
                        ? DEFAULT_COLOR
                        : props.template?.disclaimerColor
                        ? props.template.disclaimerColor
                        : DEFAULT_COLOR,
                    disclaimerVisible: hasDisclaimer(),
                }}
                onSubmit={submitHandler}
                validationSchema={object().shape({
                    templateName: string()
                        .required(t("ReportTemplate.form.name.required"))
                        .max(
                            MAX_NAME_LENGTH,
                            t("ReportTemplate.form.name.lengthException", { length: MAX_NAME_LENGTH })
                        )
                        .test("Name already exists", t("ReportTemplate.form.name.alreadyExistsException"), (value) =>
                            isTemplateNameAvailable(value)
                        ),
                })}
                validateOnChange={true}
            >
                {({ values, errors, handleChange }: FormikProps<State>) => {
                    return (
                        <Form className={style.templateContainer}>
                            <div className={style.form}>
                                <div className={style.gridItem}>
                                    <div className={classNames(form.formFieldWithoutExtraSpacing)}>
                                        {templateState.templateName}
                                        {createLabel("templateName", t("ReportTemplate.form.name.label"), {
                                            [form.inputError]: errors.templateName,
                                        })}
                                        <input
                                            autoFocus
                                            id={"templateName"}
                                            type={"text"}
                                            className={classNames(form.input, form.fixedWidthInput, style.inputWidth, {
                                                [form.inputError]: errors.templateName,
                                            })}
                                            onChange={handleChange}
                                            value={values.templateName}
                                            data-testid={
                                                testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog
                                                    .nameInput.itself
                                            }
                                        />
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog
                                                    .nameInput.errorLabel
                                            }
                                        >
                                            <ErrorMessage name={"templateName"} />
                                        </div>
                                    </div>
                                    <div className={form.formFields}>
                                        {createLabel("reports", t("ReportTemplate.form.reportType"))}
                                        <select
                                            id={"reportType"}
                                            name={"reportType"}
                                            value={values.reportType}
                                            onChange={(event) => {
                                                handleChange(event);
                                                updatePreview();
                                            }}
                                            className={classNames(form.select, style.selectArrow, style.inputWidth)}
                                            data-testid={
                                                testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog
                                                    .reportTypeSelect
                                            }
                                        >
                                            {options
                                                .sort((first, second) => first.value.localeCompare(second.value))
                                                .map((option, index) => (
                                                    <option key={index} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                        </select>
                                        <Tooltip
                                            allowHTML={true}
                                            interactive={true}
                                            placement={"right"}
                                            content={t("ReportTemplate.form.reportInfo")}
                                        >
                                            <div className={form.infoIcon} tabIndex={0}>
                                                <Info
                                                    borderColor={props.theme.contentBackgroundColor}
                                                    color={props.theme.iconFillColor}
                                                />
                                            </div>
                                        </Tooltip>
                                        <div className={form.error}>
                                            <ErrorMessage name="reports" />
                                        </div>
                                    </div>

                                    <div className={form.formFields}>
                                        <label>{t("ReportTemplate.form.introduction")}</label>
                                        <div className={style.item}>
                                            <label className={form.container}>
                                                <input
                                                    className={form.input}
                                                    id={"logoVisible"}
                                                    type={"checkbox"}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        updatePreview();
                                                    }}
                                                    checked={values.logoVisible}
                                                    data-testid={
                                                        testIds.workArea.report.erasure.reportTemplate
                                                            .manageTemplateDialog.companyLogoCheckbox
                                                    }
                                                />
                                                <span className={form.checkmark} />
                                            </label>
                                            {createLabel("logoVisible", t("ReportTemplate.form.logo.label"))}
                                            <Tooltip
                                                allowHTML={true}
                                                interactive={true}
                                                placement={"right"}
                                                content={t("ReportTemplate.form.logo.info")}
                                            >
                                                <div className={form.infoIcon} tabIndex={0}>
                                                    <Info
                                                        borderColor={props.theme.contentBackgroundColor}
                                                        color={props.theme.iconFillColor}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className={classNames(style.item, style.disclaimerItem)}>
                                            <label className={form.container}>
                                                <input
                                                    className={form.input}
                                                    id={"disclaimerVisible"}
                                                    type={"checkbox"}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        updatePreview();
                                                    }}
                                                    checked={values.disclaimerVisible}
                                                    data-testid={
                                                        testIds.workArea.report.erasure.reportTemplate
                                                            .manageTemplateDialog.disclaimerCheckbox
                                                    }
                                                />
                                                <span className={form.checkmark} />
                                            </label>
                                            {createLabel(
                                                "disclaimerVisible",
                                                t("ReportTemplate.form.disclaimer.label")
                                            )}
                                            <Tooltip
                                                allowHTML={true}
                                                interactive={true}
                                                placement={"right"}
                                                content={t("ReportTemplate.form.disclaimer.tooltip")}
                                            >
                                                <div className={form.infoIcon} tabIndex={0}>
                                                    <Info
                                                        borderColor={props.theme.contentBackgroundColor}
                                                        color={props.theme.iconFillColor}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <div hidden={!values.disclaimerVisible} className={style.indentedField}>
                                                <div
                                                    key={"disclaimerText"}
                                                    className={classNames(
                                                        form.formFields,
                                                        form.formFieldsFlex,
                                                        style.item,
                                                        style.disclaimerItem
                                                    )}
                                                >
                                                    {createLabel(
                                                        "disclaimer",
                                                        t("ReportTemplate.form.disclaimer.text")
                                                    )}
                                                    <div className={form.notes}>
                                                        <textarea
                                                            id={"disclaimer"}
                                                            className={classNames(form.input, style.disclaimerTextArea)}
                                                            maxLength={MAX_DISCLAIMER_LENGTH}
                                                            onChange={(event) => {
                                                                handleChange(event);
                                                                setDisclaimerAdded(true);
                                                            }}
                                                            onMouseLeave={() => {
                                                                if (disclaimerAdded) {
                                                                    updatePreview();
                                                                }
                                                            }}
                                                            value={values.disclaimer}
                                                            data-testid={
                                                                testIds.workArea.report.erasure.reportTemplate
                                                                    .manageTemplateDialog.disclaimerTextArea
                                                            }
                                                        />
                                                        <span className={form.optional}>
                                                            {t("ReportTemplate.form.disclaimer.charactersCount", {
                                                                charactersLeft:
                                                                    MAX_DISCLAIMER_LENGTH - values.disclaimer.length,
                                                                total: MAX_DISCLAIMER_LENGTH,
                                                            })}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    key={"disclaimerColor"}
                                                    className={classNames(
                                                        form.formFields,
                                                        form.formFieldsFlex,
                                                        style.item,
                                                        style.disclaimerItem
                                                    )}
                                                >
                                                    {createLabel(
                                                        "color",
                                                        t("ReportTemplate.form.disclaimer.colorPicker")
                                                    )}
                                                    <div
                                                        id={"color"}
                                                        ref={ref}
                                                        className={classNames(style.border, style.disclaimerColorBlock)}
                                                    >
                                                        <div onClick={() => setColorPickerVisible(!colorPickerVisible)}>
                                                            <div
                                                                className={style.colorBox}
                                                                style={{ backgroundColor: values.color }}
                                                            ></div>
                                                            <div
                                                                className={classNames(
                                                                    style.selectArrow,
                                                                    style.arrowForColorPicker
                                                                )}
                                                            />
                                                        </div>
                                                        <div hidden={!colorPickerVisible} className={style.colorPicker}>
                                                            <CompactPicker
                                                                onChange={(color) => {
                                                                    values.color = color.hex;
                                                                    updatePreview();
                                                                }}
                                                                color={values.color}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.item}>
                                            {reportSections.map((each, key) => {
                                                if (each !== "HARDWARE_DETAILS" && !HARDWARE_SECTIONS.includes(each)) {
                                                    return createSectionCheck(
                                                        each,
                                                        key,
                                                        sectionsKeys,
                                                        sectionKeysTestIds
                                                    );
                                                } else if (each === "HARDWARE_DETAILS") {
                                                    if (!HARDWARE_SECTIONS.includes(each)) {
                                                        return createHardwareSectionCheck(each, key);
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={style.previewContainer}
                                    data-testid={
                                        testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog
                                            .previewIframe
                                    }
                                >
                                    <ReportTemplatePreviewModal
                                        previewCounter={previewCounter}
                                        templateDetails={{
                                            uuid: props.template ? props.template.uuid : "",
                                            sections: templateState.sections,
                                            templateName: values.templateName.trim(),
                                            reportType: values.reportType,
                                            disclaimer: values.disclaimerVisible ? values.disclaimer : "",
                                            logoVisible: values.logoVisible,
                                            disclaimerColor: values.color,
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={classNames(form.buttonContainer, style.buttonContainer)}>
                                <button
                                    type={"button"}
                                    className={buttons.secondaryButtonWithoutIcon}
                                    data-testid={testIds.common.dialog.closeButton}
                                    onClick={handleHideModal}
                                >
                                    {t("Common.cancel")}
                                </button>
                                <button
                                    type={"submit"}
                                    className={buttons.primaryButtonWithoutIcon}
                                    data-testid={
                                        testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.addButton
                                    }
                                >
                                    {props.template ? t("Common.save") : t("ReportTemplate.form.submitButton")}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default connector(ReportTemplateForm);
