import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CapacityField } from "./CapacityField";
import { FormValues } from "./CustomReportView";
import { InputField } from "./InputFields";

interface PcTemplateProps {
    formikProps: FormikProps<FormValues>;
}

const PcTemplate: React.FC<PcTemplateProps> = React.memo(({ formikProps }) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = formikProps;

    const inputFields1 = useMemo(
        () => [
            {
                id: "aspectRatio",
                label: t("ErasureReport.customReport.templateSpecifications.aspectRatio"),
                dataPath: "user_data.fields.usercreatedreport.aspect_ratio",
            },
            {
                id: "chassisType",
                label: t("ErasureReport.customReport.templateSpecifications.chassisType"),
                dataPath: "user_data.fields.usercreatedreport.chassis_type",
            },
            {
                id: "comments",
                label: t("ErasureReport.customReport.templateSpecifications.comments"),
                dataPath: "user_data.fields.usercreatedreport.comments",
            },
        ],
        [values, t]
    );

    const inputFields2 = useMemo(
        () => [
            {
                id: "make",
                label: t("ErasureReport.customReport.templateSpecifications.make"),
                dataPath: "user_data.fields.usercreatedreport.make",
            },
        ],
        [values, t]
    );

    const inputFields3 = useMemo(
        () => [
            {
                id: "model",
                label: t("ErasureReport.customReport.templateSpecifications.model"),
                dataPath: "blancco_hardware_report.processors.processor.model",
            },
            {
                id: "processor",
                label: t("ErasureReport.customReport.templateSpecifications.processor"),
                dataPath: "user_data.fields.usercreatedreport.processor",
            },
            {
                id: "serialNumber",
                label: t("ErasureReport.customReport.templateSpecifications.serialNumber"),
                dataPath: "blancco_hardware_report.system.serial",
            },
            {
                id: "type",
                label: t("ErasureReport.customReport.templateSpecifications.type"),
                dataPath: "user_data.fields.usercreatedreport.type",
            },
        ],
        [values, t]
    );

    return (
        <>
            {inputFields1.map((field) => (
                <InputField
                    key={field.id}
                    id={field.id}
                    setFieldValue={setFieldValue}
                    label={field.label}
                    dataPath={field.dataPath}
                />
            ))}
            <CapacityField
                key="hddSize"
                id="hddSize"
                dataPath="blancco_hardware_report.disks.disk.capacity"
                setFieldValue={setFieldValue}
                label={t("ErasureReport.customReport.templateSpecifications.hddSize")}
            />
            {inputFields2.map((field) => (
                <InputField
                    key={field.id}
                    id={field.id}
                    setFieldValue={setFieldValue}
                    label={field.label}
                    dataPath={field.dataPath}
                />
            ))}
            <CapacityField
                key="memory"
                id="memory"
                dataPath="blancco_hardware_report.memory.total_memory"
                setFieldValue={setFieldValue}
                label={t("ErasureReport.customReport.templateSpecifications.memory")}
            />
            {inputFields3.map((field) => (
                <InputField
                    key={field.id}
                    id={field.id}
                    setFieldValue={setFieldValue}
                    label={field.label}
                    dataPath={field.dataPath}
                />
            ))}
        </>
    );
});

export default PcTemplate;
