import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./users.scss";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import Resend from "components/icons/Resend";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import loginStyle from "components/login/login.scss";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import AddUserForm, { FormValues } from "components/users/add-user/AddUserForm";
import { AUTH_USER_DELETE, AUTH_USER_EDIT } from "domain/authority";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { UserLoginMethod } from "domain/user";
import { Role } from "domain/users";
import { LicensePoolList } from "services/licenses/LicensePoolService";
import { authenticationService } from "services/security/AuthenticationService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { deriveTenantUuid, getTenantLoginMethod } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { userService } from "services/user/users/UserService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { isStringBlank } from "utils/commonFunctions";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

interface RolesFetch {
    failed: boolean;
    errorModalVisible: boolean;
}

interface Confirmation {
    visible: boolean;
    done: boolean;
    success: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const UserKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        email: string;
        expirationDate: string;
        userName: string;
        userRoleUuid: string;
        onUserDelete: () => void;
        onUserEdit: () => void;
        enabled: boolean;
        emailVerified: boolean;
        loginMethod: UserLoginMethod;
        userLicensePoolUuid: string;
        userType: string;
        licensePoolsList: LicensePoolList[];
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteUserModalVisible, setDeleteUserModalVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", resultVisible: false });
    const [okClicked, setOkClicked] = React.useState(false);
    // const [isLoading, setIsLoading] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [roles, setRoles] = React.useState<Role[]>();
    const hideResultAndRedirectToRoot = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });
        props.onUserDelete();
    };
    const [editUserFormVisible, setEditUserFormVisible] = React.useState(false);
    const [rolesFetch, setRolesFetch] = React.useState<RolesFetch>({ failed: false, errorModalVisible: false });
    const initialConfirmation = { visible: false, done: false, success: false };
    const [confirmation, setConfirmation] = React.useState<Confirmation>(initialConfirmation);
    const [ssoEnabled, setSsoEnabled] = React.useState(false);
    const [itemFound, setItemFound] = React.useState(false);
    const hideConfirmation = () => setConfirmation(initialConfirmation);
    const resetPasswordTitle = t(
        confirmation.done
            ? confirmation.success
                ? "ResetPassword.title"
                : "ResetPassword.failureTitle"
            : "EditCustomerView.resetPassword"
    );

    const editUserSubmitEventHandler = async ({
        fullName,
        roleUuid,
        licensePool,
        expirationDate,
        enabled,
        loginMethod,
        userType,
    }: FormValues): Promise<void> => {
        setOkClicked(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const item = props.licensePoolsList.find((item) => item.poolName === licensePool);
        if (item !== undefined) {
            licensePool = item.poolUuid;
        }
        userService
            .editUser(
                props.uuid,
                fullName,
                roleUuid,
                licensePool,
                expirationDate,
                enabled,
                loginMethod,
                userType,
                abortController
            )
            .then(() => {
                setEditUserFormVisible(false);
                setResult({
                    title: t("EditUser.userEdited.userEditedTitle"),
                    message: t("EditUser.userEdited.successMessage", { userName: props.userName }),
                    resultVisible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setEditUserFormVisible(false);
                    setResult({
                        title: t("EditUser.userEdited.userNotEditedTitle"),
                        message: t("EditUser.userEdited.failureMessage"),
                        resultVisible: true,
                    });
                    setOkClicked(false);
                }
            });
    };

    const deleteUser = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        userService
            .deleteUser(props.uuid, abortController)
            .then(() => {
                setDeleteUserModalVisible(false);
                setResult({
                    title: t("DeleteUser.userDeleted.userDeletedTitle"),
                    message: t("DeleteUser.userDeleted.successMessage", { userName: props.userName }),
                    resultVisible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setOkClicked(false);
                    setDeleteUserModalVisible(false);
                    setResult({
                        title: t("DeleteUser.userDeleted.userNotDeletedTitle"),
                        message: t("DeleteUser.userDeleted.failureMessage"),
                        resultVisible: true,
                    });
                }
            });
    };

    const onSendResetClick = () => {
        function configure(done: boolean, success: boolean) {
            setConfirmation({
                visible: true,
                done,
                success,
            });
        }
        configure(false, false);
        authenticationService
            .requestPasswordReset(props.email)
            .then(() => {
                configure(true, true);
            })
            .catch(() => {
                configure(true, false);
            });
    };

    async function fetchTenant() {
        const tenantUuid = deriveTenantUuid();
        await Promise.allSettled([
            tenantService.fetchTenant(tenantUuid),
            userSessionService.hasFeatureLicense("FEATURE_SSO")
                ? userService.fetchSsoSettings(tenantUuid)
                : Promise.reject(),
        ]).then(([tenantServiceResult, userServiceResult]) => {
            if (tenantServiceResult.status === "fulfilled") {
                setRoles(tenantServiceResult.value.roles);
            } else {
                setRolesFetch({ failed: true, errorModalVisible: true });
            }
            setSsoEnabled(userServiceResult.status === "fulfilled");
        });
    }

    const item = props.licensePoolsList.find((item) => item.poolName === props.userLicensePoolUuid);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    React.useEffect(() => {
        item != undefined ? setItemFound(true) : setItemFound(false);
    }, [item]);

    const hideEditUserForm = () => {
        setEditUserFormVisible(false);
    };

    const handleUserDelete = () => {
        setOkClicked(true);
        deleteUser();
    };

    const handleResetPassword = () => {
        onSendResetClick();
    };

    const hideRoleFetchErrorModal = () => {
        setRolesFetch((previous) => ({
            failed: previous.failed,
            errorModalVisible: false,
        }));
    };

    const userEmail = props.userDetail ? props.userDetail.uuid : "";

    const editUserModal = roles != null && !rolesFetch.failed && (
        <Modal
            isOpen={editUserFormVisible}
            hideModal={hideEditUserForm}
            modalTitle={t("EditUserForm.title")}
            key="modalMe"
        >
            {okClicked ? (
                <LoadingIndicator />
            ) : (
                <div className={form.fixedWidthModal}>
                    <AddUserForm
                        fullName={props.userName}
                        email={props.email}
                        expirationDate={props.expirationDate}
                        submitEventHandler={editUserSubmitEventHandler}
                        roles={roles}
                        disableEmail={true}
                        userRoleUuid={props.userRoleUuid}
                        enabled={props.enabled}
                        disableStatus={true}
                        loginMethod={props.loginMethod}
                        ssoEnabled={ssoEnabled}
                        userLicensePoolUuid={itemFound ? item?.poolUuid : ""}
                        licensePools={props.licensePoolsList}
                        userType={props.userType}
                    />
                </div>
            )}
        </Modal>
    );

    const hideResetPasswordMenuItem = () => {
        if (isStringBlank(props.loginMethod)) {
            return getTenantLoginMethod() === "COMPANY_ID";
        }
        return props.loginMethod === "COMPANY_ID";
    };

    return (
        <>
            <div className={style.deleteIcon}>
                <Menu className={style.kebabMenu}>
                    <ul>
                        {userSessionService.userHasAllAuthorities([AUTH_USER_EDIT]) && (
                            <li>
                                <TextWithTooltip text={t("EditCustomerView.edit")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            if (userEmail === props.uuid) {
                                                return;
                                            }
                                            fetchTenant();
                                            setEditUserFormVisible(true);
                                            usageStatisticsService.sendEvent({
                                                category: Category.USER,
                                                action: Action.EDIT_USER,
                                            });
                                        }}
                                    >
                                        <Edit color={props.theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                        {!props.emailVerified && props.enabled && !hideResetPasswordMenuItem() && (
                            <li>
                                <TextWithTooltip text={t("EditCustomerView.resetPassword")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            if (userEmail === props.uuid) {
                                                return;
                                            }
                                            handleResetPassword();
                                        }}
                                    >
                                        <Resend color={props.theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                        {userSessionService.userHasAllAuthorities([AUTH_USER_DELETE]) && (
                            <li>
                                <TextWithTooltip text={t("EditCustomerView.delete")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        onClick={() => {
                                            if (userEmail === props.uuid) {
                                                return;
                                            }
                                            setDeleteUserModalVisible(true);
                                            usageStatisticsService.sendEvent({
                                                category: Category.USER,
                                                action: Action.REMOVE_USER,
                                            });
                                        }}
                                    >
                                        <Delete color={props.theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                    </ul>
                </Menu>
                <DeleteConfirmationDialog
                    modalDisplayed={deleteUserModalVisible}
                    introductionMessage={t("DeleteUser.introductionMessage", { userName: props.userName })}
                    confirmationMessage={t("DeleteUser.confirmationMessage", { userName: props.userName })}
                    handleCommand={handleUserDelete}
                    setModalDisplayed={() => setDeleteUserModalVisible(false)}
                    title={t("DeleteUser.title")}
                    loading={okClicked}
                />
                {editUserModal}
                <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={buttons.primaryOkButton}
                            onClick={hideResultAndRedirectToRoot}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
                <Modal
                    isOpen={rolesFetch.errorModalVisible}
                    hideModal={hideRoleFetchErrorModal}
                    modalTitle={t("EditUserForm.failedRoleFetch.title")}
                >
                    <div className={style.resultContainer}>
                        {t("EditUserForm.failedRoleFetch.message", { email: SUPPORT_EMAIL })}
                    </div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={buttons.primaryOkButton}
                            onClick={hideRoleFetchErrorModal}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
                <Modal isOpen={confirmation.visible} hideModal={hideConfirmation} modalTitle={resetPasswordTitle}>
                    {!confirmation.done ? (
                        <LoadingIndicator />
                    ) : (
                        <div className={style.resetPasswordModalContainer}>
                            {confirmation.success ? (
                                <div data-testid={testIds.common.successMessage.messageLabel}>
                                    {t("ResetPassword.successMessage", {
                                        userName: props.userName,
                                    })}
                                </div>
                            ) : (
                                <div data-testid={testIds.common.errorMessage.itself}>
                                    {t("ResetPassword.failureMessage", {
                                        userName: props.userName,
                                    })}
                                    <a
                                        href={`mailto:${SUPPORT_EMAIL}`}
                                        data-testid={testIds.common.errorMessage.supportLink}
                                    >
                                        {SUPPORT_EMAIL}
                                    </a>
                                    {"."}
                                </div>
                            )}
                            <div className={loginStyle.loginModalSubmitButton}>
                                <button
                                    onClick={hideConfirmation}
                                    className={buttons.primaryOkButton}
                                    data-testid={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.ok")}
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        </>
    );
};

export default connector(UserKebabMenu);
