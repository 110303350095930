import React from "react";
import { isIOS, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import style from "./profile.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { AssetData, URLParameters } from "domain/iosProfile";
import { iosProfilePageService } from "services/ios-profile-page/ProfilePageService";
import buttons from "styles/buttons.scss";

import alertIcon from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

const DownloadPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { journeyId } = useParams<URLParameters>();
    const [asset, setAsset] = React.useState<AssetData>();
    const [loading, setLoading] = React.useState(true);
    const query = new URLSearchParams(useLocation().search);
    const region = query.get("region");

    const fetchAssetData = () => {
        const abortController = new AbortController();
        iosProfilePageService
            .fetchAssetData(abortController, journeyId, region || "eu-west-1")
            .then((data) => {
                setAsset(data);
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    console.error("Asset details not found");
                    setLoading(false);
                }
            });
    };

    const handleClick = () => {
        asset?.url ? (window.location.href = asset.url + "?journey_id=" + journeyId) : null;
    };

    const primaryColor = asset?.primary_color ? asset?.primary_color : "";

    const assetProperties = asset?.assetProperties
        ? JSON.parse(asset?.assetProperties)
        : { imei: null, serialNumber: null };

    const imeiRequiredAndAvailable = asset?.imeiMandatory ? asset.imeiMandatory && assetProperties.imei : true;
    const serialRequiredAndAvailable = asset?.serialMandatory
        ? asset.serialMandatory && assetProperties.serialNumber
        : true;

    React.useEffect(() => {
        fetchAssetData();
    }, []);

    if (loading) {
        return <LoadingIndicator />;
    }

    return !journeyId ? (
        <div className={style.error} data-testid={testIds.workArea.iosProfilePage.deviceErrorLabel}>
            <img src={alertIcon} />
            <div>{t("IosProfilePage.errorMessages.journeyIdMissing")}</div>
        </div>
    ) : !isIOS || isTablet ? (
        <div className={style.error} data-testid={testIds.workArea.iosProfilePage.deviceErrorLabel}>
            <img src={alertIcon} />
            <div>{t("IosProfilePage.errorMessages.iosDevicesOnly")}</div>
        </div>
    ) : (!asset?.imeiMandatory && !asset?.serialMandatory) ||
      (imeiRequiredAndAvailable && serialRequiredAndAvailable) ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileDownloadTitle")}</div>
                <div className={style.message}>
                    <strong>{t("IosProfilePage.iosDeviceIdentified")}</strong>
                </div>
                <div className={style.message}>
                    <strong>{t("IosProfilePage.iosProfileDownloadMessage")}</strong>
                </div>
                <div className={style.buttonContainer}>
                    <button
                        id="okButton"
                        onClick={handleClick}
                        className={buttons.primaryButtonWithoutIcon}
                        style={{
                            backgroundColor: primaryColor,
                            borderColor: primaryColor,
                        }}
                        data-testid={testIds.workArea.iosProfilePage.continueButton}
                    >
                        {t("IosProfilePage.continueButton")}
                    </button>
                </div>
            </div>
        </div>
    ) : !imeiRequiredAndAvailable && serialRequiredAndAvailable ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <text>{t("IosProfilePage.simSlotEmptyMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div className={style.validation}>
                        <strong>{t("IosProfilePage.noSimSlotTitle")}</strong>
                    </div>
                    <text>{t("IosProfilePage.noSimSlotMessage")}</text>
                </div>
            </div>
        </div>
    ) : imeiRequiredAndAvailable && !serialRequiredAndAvailable ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <text>{t("IosProfilePage.simSlotEmptyMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div className={style.validation}>
                        <strong>{t("IosProfilePage.noSerialNumberTitle")}</strong>
                    </div>
                    <text>{t("IosProfilePage.noSerialNumberMesage")}</text>
                </div>
            </div>
        </div>
    ) : (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <text>{t("IosProfilePage.simSlotEmptyMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div>
                        <text>
                            <strong>{t("IosProfilePage.noSimSlotTitle")}</strong>
                        </text>
                    </div>
                    <text>{t("IosProfilePage.noSimSlotMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div>
                        <text>
                            <strong>{t("IosProfilePage.noSerialNumberTitle")}</strong>
                        </text>
                    </div>
                    <text>{t("IosProfilePage.noSerialNumberMesage")}</text>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
