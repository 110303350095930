export const EMAIL_MAX_LENGTH = 64;
export const NOTES_MAX_LENGTH = 1000;
export const NAME_MAX_LENGTH = 50;
export const TOOLTIP_DELAY = 500;
export const ESG_DATA_VERSION = "v1";
export const CDW_TOTAL_WIDGETS = 10;
export const CDW_NUMBER_OF_COLUMNS = 2;
export const SUPPORT_EMAIL = "support@blancco.com";
export const TABLE_PAGE_LIMIT = 100;
export const MANAGEMENT_PORTAL_PRODUCT_ID = "63";
export const DRAGGABLE_CARD_HANDLE_SELECTOR = ".drag-handle";
export const INTERNAL_TENANT_TYPE = "INTERNAL";
export const ICON_SIZE_SMALL = "30px";
export const CUSTOM_REPORT_FIELD_MAX_LENGTH = 128;
export const BDE_WORKFLOW_EDITOR_VERSION_7_12_0 = "7.12.0";
export const BDE_WORKFLOW_EDITOR_VERSION_7_13_0 = "7.13.0";
export const BMDE_WORKFLOW_EDITOR_VERSION_5_4_0 = "5.4.0";
export const BMDE_WORKFLOW_EDITOR_VERSION_5_5_0 = "5.5.0";
export const DOT = " • ";
