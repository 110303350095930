import * as React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import style from "./diagnostic-report-modal.scss";
import MyDeviceHealthView from "./my-device-health/MyDeviceHealthView";
import Modal from "components/modal/Modal";
import BuybackTradeinView from "components/reports/buyback-tradein/BuybackTradeinView";
import DeleteReportDialog from "components/reports/DeleteReportDialog";
import DeviceDetailView from "components/reports/device-detail/DeviceDetailView";
import { DiagnosticSummaryView } from "components/reports/diagnostics-summary/DiagnosticSummaryView";
import ExportDiagnosticReport from "components/reports/export/ExportDiagnosticReport";
import InsuranceView from "components/reports/insurance/InsuranceView";
import { ScreenAnalysisView } from "components/reports/screen-analysis/ScreenAnalysisView";
import { Configuration } from "domain/licenses";
import { DiagnosticData, DiagnosticReportResponse } from "domain/reports";
import { licenseService } from "services/licenses/LicenseService";
import { reportService } from "services/report/erasure/ReportService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export interface Props {
    diagnosticData: DiagnosticData;
    hide: () => void;
    onReportDelete: () => void;
}

const DiagnosticReportModal = ({ diagnosticData, hide, onReportDelete }: Props): JSX.Element => {
    const { t } = useTranslation();

    const features = {
        bbti: "Blancco Mobile Solutions - Buy-Back / Trade In",
        insurance: "Blancco Mobile Solutions - Insurance",
        myDeviceHealth: "Blancco Mobile Solutions - My Device Health",
    };

    const { data: diagnosticReportResponse } = useQuery(
        ["diagnosticReport", diagnosticData.uuid],
        () => reportService.diagnosticReports(diagnosticData.uuid, t),
        {
            onError: (err) => {
                logger.error(err);
                hide();
                onReportDelete();
            },
        }
    );

    const { data: clientConfigurationData } = useQuery(
        "clientConfiguration",
        async ({ signal }) => {
            if (!signal) {
                throw new Error("AbortSignal is required but was undefined.");
            }
            const configurationData = await licenseService.fetchConfigurationData(signal);
            return licenseService.fetchClientConfigurationData(configurationData.configuration[0].uuid, signal);
        },
        {
            onError: (err) => {
                logger.error("Failed to fetch client configuration data", err);
            },
        }
    );

    let buyBackDisplayTab,
        buyBackDisplayTabPanel,
        screenAnalysisTab,
        screenAnalysisTabPanel,
        insuranceTab,
        insuranceTabPanel,
        myDeviceHealthTab;

    if (diagnosticData.feature === features.bbti) {
        buyBackDisplayTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_DIAGNOSTIC_BUYBACK_TRADEIN,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.buyBackTradeIn")}
            </Tab>
        );
        buyBackDisplayTabPanel = (
            <TabPanel>
                <BuybackTradeinView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    if (
        diagnosticReportResponse?.screenAnalysis.fraudRisk !== "NOT_RUN" &&
        diagnosticReportResponse?.screenAnalysis.fraudRisk !== "" &&
        diagnosticReportResponse?.screenAnalysis.mirrorTest !== undefined
    ) {
        screenAnalysisTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_SCREEN_ANALYSIS,
                        label: Label.DIAGNOSTIC,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.screenAnalysis.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.screenAnalysis")}
            </Tab>
        );
        screenAnalysisTabPanel = (
            <TabPanel>
                <ScreenAnalysisView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    if (diagnosticData.feature === features.insurance) {
        insuranceTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_INSURANCE,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.insurance.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.insurance")}
            </Tab>
        );
        insuranceTabPanel = (
            <TabPanel>
                <InsuranceView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    if (
        diagnosticData.feature === features.myDeviceHealth &&
        diagnosticReportResponse?.ntf?.results !== undefined &&
        diagnosticReportResponse?.ntf.results.length > 0
    ) {
        myDeviceHealthTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_NTF,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.myDeviceHealth")}
            </Tab>
        );
    }

    const myDeviceHealthTabPanel = (
        <TabPanel>
            <MyDeviceHealthView
                diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse}
                clientConfiguration={clientConfigurationData as Configuration}
            />
        </TabPanel>
    );

    return (
        <Modal
            key={1}
            isOpen={true}
            hideModal={hide}
            modalTitle={t("DiagnosticReportsTable.title", {
                id: diagnosticData.uuid,
                make: diagnosticData.make,
                model: diagnosticData.model,
                modelName: diagnosticData.modelName,
            })}
        >
            <Tabs>
                <TabList>
                    <Tab
                        onClick={() =>
                            usageStatisticsService.sendEvent({
                                category: Category.REPORTS,
                                action: Action.VIEW_DEVICE_DETAILS,
                                label: Label.DIAGNOSTIC,
                            })
                        }
                        data-testid={testIds.workArea.report.diagnostic.reportDialog.deviceDetail.itself}
                    >
                        {t("DiagnosticReportsTable.reportTabs.deviceDetails")}
                    </Tab>
                    <Tab
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.REPORTS,
                                action: Action.VIEW_DIAGNOSTIC_SUMMARY,
                                label: Label.DIAGNOSTIC,
                            });
                        }}
                        data-testid={testIds.workArea.report.diagnostic.reportDialog.diagnosticsSummary.itself}
                    >
                        {t("DiagnosticReportsTable.reportTabs.diagnosticsSummary")}
                    </Tab>
                    {buyBackDisplayTab}
                    {screenAnalysisTab}
                    {insuranceTab}
                    {myDeviceHealthTab}
                    <div className={style.buttonContainer}>
                        <ExportDiagnosticReport reportUuid={diagnosticData.uuid} />
                        <DeleteReportDialog
                            reportUuid={diagnosticData.uuid}
                            onDelete={hide}
                            deletionInvoked={false}
                            onDeletionInvoked={() => true}
                        />
                    </div>
                </TabList>

                <TabPanel>
                    <DeviceDetailView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
                </TabPanel>
                <TabPanel>
                    <DiagnosticSummaryView
                        diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse}
                        clientConfiguration={clientConfigurationData as Configuration}
                        feature={diagnosticData.journeyType}
                    />
                </TabPanel>
                {buyBackDisplayTabPanel}
                {screenAnalysisTabPanel}
                {insuranceTabPanel}
                {myDeviceHealthTabPanel}
            </Tabs>
        </Modal>
    );
};

export default DiagnosticReportModal;
