import * as React from "react";

export const DeleteIcon = (props: {
    color: string;
    linecolor: string;
    width?: number;
    height?: number;
}): JSX.Element => {
    return (
        <svg
            id="minus_icon"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : "24"}
            height={props.height ? props.height : "24"}
            viewBox="0 0 24 24"
        >
            <rect id="Rectangle_3101" data-name="Rectangle 3101" width="24" height="24" fill="#9a4242" opacity="0" />
            <g id="Group_3627" data-name="Group 3627" transform="translate(-640 -579)" style={{ isolation: "isolate" }}>
                <g id="Group_3547" data-name="Group 3547">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(641 580)">
                        <path
                            id="Path_1173"
                            data-name="Path 1173"
                            d="M162.951,212.13a10.091,10.091,0,1,1-10.091,10.091A10.091,10.091,0,0,1,162.951,212.13Z"
                            transform="translate(-152.86 -212.13)"
                            fill={props.color}
                            stroke={props.linecolor}
                        />
                    </g>
                    <g id="trash-2" transform="translate(648 590) rotate(-90)">
                        <line
                            id="Line_750"
                            data-name="Line 750"
                            y2="7"
                            transform="translate(-0.5 -0.5)"
                            fill="none"
                            stroke={props.linecolor}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
