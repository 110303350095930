import classNames from "classnames";
import { ErrorMessage, Field, Formik, Form as FormikForm, FormikProps } from "formik";
import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { date, DateSchema, number, NumberSchema, object, string } from "yup";

import localStyle from "./form.scss";
import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import { LicenseKeyAssignment, licenseService } from "services/licenses/LicenseService";
import { Action, Category, Event, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import {
    createDateLabel,
    daysAfterToday,
    formatIsoDate,
    formatUtcDateString,
    HOUR,
    MINUTES_SECONDS_MILLISECONDS,
} from "utils/format";
import { logger } from "utils/logging";

import testIds from "testIds.json";

enum Phase {
    FAILED,
    FETCH_AVAILABLE_LICENSES_FAILED,
    LOADING_AVAILABLE_LICENSES,
    SENDING,
    USER,
}

interface State {
    phase: Phase;
    formValues: LicenseKeyAssignment;
}

export interface SummaryLabelParameter {
    initialRender: boolean;
    availableLicenses: number;
    expiration: string;
    licenseType: string;
}

export interface Props {
    onSuccess: () => void;
    onFetchLicensesFailure: () => void;
}

type InputType = "text" | "date" | "number";

interface FormSection {
    name: InputName;
    localizationId: string;
    optional: boolean;

    createError(): JSX.Element | null;

    createField(formikProps: FormikProps<LicenseKeyAssignment>, t: TFunction): JSX.Element;
}

type InputName = keyof LicenseKeyAssignment;

class FormSectionInput implements FormSection {
    name: InputName;
    localizationId: string;
    inputTestId: string;
    errorTestId: string;
    inputType: InputType;
    autoFocus: boolean;
    optional: boolean;
    tooltipText?: string;

    constructor(
        name: InputName,
        localizationId: string,
        inputTestId: string,
        errorTestId: string,
        inputType: InputType,
        {
            autoFocus = false,
            optional = false,
        }: {
            autoFocus?: boolean;
            optional?: boolean;
        } = {},
        tooltipText?: string
    ) {
        this.name = name;
        this.localizationId = localizationId;
        this.inputTestId = inputTestId;
        this.errorTestId = errorTestId;
        this.inputType = inputType;
        this.autoFocus = autoFocus;
        this.optional = optional;
        this.tooltipText = tooltipText;
    }

    createField(formikProps: FormikProps<LicenseKeyAssignment>, t: TFunction): JSX.Element {
        let tooltip = null;
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);
        if (this.tooltipText != null) {
            tooltip = (
                <Tooltip content={t(this.tooltipText)}>
                    <div className={localStyle.info} tabIndex={0}>
                        <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                    </div>
                </Tooltip>
            );
        }
        return (
            <>
                <Field
                    id={this.name}
                    name={this.name}
                    autoFocus={this.autoFocus}
                    data-testid={this.inputTestId}
                    onBlur={(e: React.FocusEvent) => {
                        formikProps.handleBlur(e);
                        sendUsageStatisticsHit(e);
                    }}
                    type={this.inputType}
                    className={classNames(formStyle.input, formStyle.fixedWidthInput, {
                        [formStyle.inputError]: formikProps.errors[this.name],
                    })}
                />
                {tooltip}
            </>
        );
    }

    createError(): JSX.Element {
        return (
            <div className={formStyle.error} data-testid={this.errorTestId}>
                <ErrorMessage name={this.name} />
            </div>
        );
    }
}

class FormSectionSelect implements FormSection {
    name: InputName;
    localizationId: string;
    inputTestId: string;
    errorTestId: string;
    optional: boolean;
    enabledLicenseTypes: string[];
    selected: string;
    changeHandler: (selectedLicenseType: string) => void;

    constructor(
        name: InputName,
        localizationId: string,
        inputTestId: string,
        errorTestId: string,
        enabledLicenseTypes: string[],
        selected: string,
        changeHandler: (selectedLicenseType: string) => void
    ) {
        this.name = name;
        this.localizationId = localizationId;
        this.inputTestId = inputTestId;
        this.errorTestId = errorTestId;
        this.enabledLicenseTypes = enabledLicenseTypes;
        this.selected = selected;
        this.changeHandler = changeHandler;
        this.optional = false;
    }

    createField(formikProps: FormikProps<LicenseKeyAssignment>, t: TFunction): JSX.Element {
        const LICENSE_TYPES = [
            { productName: t("Licenses.bms.assignDialog.licenseTypeValidation"), productId: "bms-validation" },
            { productName: t("Licenses.bms.assignDialog.licenseTypeBbti"), productId: "bms-bbti" },
            { productName: t("Licenses.bms.assignDialog.licenseTypeInsurance"), productId: "bms-insurance" },
            { productName: t("Licenses.bms.assignDialog.licenseTypeNtf"), productId: "bms-ntf" },
            { productName: t("Licenses.bms.assignDialog.licenseTypeLease"), productId: "bms-lease" },
            { productName: t("Licenses.bms.assignDialog.licenseTypeAll"), productId: "bms-all" },
            { productName: t("Licenses.bms.assignDialog.usdk"), productId: "usdk" },
        ];
        return (
            <select
                id={this.name}
                name={this.name}
                className={formStyle.select}
                data-testid={this.inputTestId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formikProps.handleChange(e);
                    this.changeHandler(e.target.value);
                }}
                value={this.selected}
            >
                {LICENSE_TYPES.filter((value) => this.enabledLicenseTypes.includes(value.productId)).map((value) => (
                    <option key={value.productId} value={value.productId}>
                        {value.productName}
                    </option>
                ))}
            </select>
        );
    }

    createError(): JSX.Element {
        return (
            <div className={formStyle.error} data-testid={this.errorTestId}>
                <ErrorMessage name={this.name} />
            </div>
        );
    }
}

class FormSectionLabel implements FormSection {
    name: InputName;
    localizationId: string;
    optional: boolean;
    testId: string;

    constructor(name: InputName, localizationId: string, testId: string) {
        this.name = name;
        this.localizationId = localizationId;
        this.optional = false;
        this.testId = testId;
    }

    createError(): JSX.Element | null {
        return null;
    }

    createField(formikProps: FormikProps<LicenseKeyAssignment>): JSX.Element {
        return (
            <span className={formStyle.fixedWidthInput} data-testid={this.testId}>
                {formikProps.values[this.name]}
            </span>
        );
    }
}

function createFormSection(
    t: TFunction,
    formSection: FormSection,
    key: string,
    formikProps: FormikProps<LicenseKeyAssignment>
) {
    let optionalElement = null;
    if (formSection.optional) {
        optionalElement = <span className={formStyle.optional}>{t("Common.optional")}</span>;
    }
    return (
        <div key={key} className={formStyle.formFields}>
            <div>
                {optionalElement}
                <label
                    htmlFor={formSection.name}
                    className={classNames(formStyle.label, {
                        [formStyle.inputError]: formikProps.errors[formSection.name],
                    })}
                >
                    {t(formSection.localizationId)}
                </label>
                {formSection.createField(formikProps, t)}
            </div>
            {formSection.createError()}
        </div>
    );
}

const sendUsageStatisticsHit = (e: React.FocusEvent) => {
    const event: Event = {
        category: Category.LICENSE,
        action: "",
    };
    switch (e.target.id) {
        case "keyCount":
            event.action = Action.CHANGE_NUMBER_OF_LICENSE_KEYS;
            break;
        case "identifier":
            event.action = Action.CHANGE_LICENSE_KEY_IDENTIFIER;
            break;
        case "hours":
            event.action = Action.CHANGE_LICENSE_KEY_USAGE_HOURS;
            break;
        case "startDate":
            event.action = Action.CHANGE_LICENSE_KEY_START_DATE;
            break;
        case "expirationDate":
            event.action = Action.CHANGE_LICENSE_KEY_END_DATE;
            break;
        case "assignCount":
            event.action = Action.CHANGE_LICENSE_KEY_ASSIGNED_COUNT;
            break;
        default:
            return;
    }
    usageStatisticsService.sendEvent(event);
};

const FORM_SECTIONS = [
    new FormSectionInput(
        "keyCount",
        "Licenses.bms.assignDialog.numberOfKeys",
        testIds.workArea.license.bms.assignKeysDialog.numberOfKeysInput.itself,
        testIds.workArea.license.bms.assignKeysDialog.numberOfKeysInput.errorLabel,
        "number",
        { autoFocus: true }
    ),
    new FormSectionLabel(
        "association",
        "Licenses.bms.assignDialog.association",
        testIds.workArea.license.bms.assignKeysDialog.associationLabel
    ),
    new FormSectionInput(
        "identifier",
        "Licenses.bms.assignDialog.identifier",
        testIds.workArea.license.bms.assignKeysDialog.identifierInput.itself,
        testIds.workArea.license.bms.assignKeysDialog.identifierInput.errorLabel,
        "text",
        { optional: true },
        "Licenses.bms.assignDialog.identifierTooltip"
    ),
    new FormSectionInput(
        "hours",
        "Licenses.bms.assignDialog.usageHours",
        testIds.workArea.license.bms.assignKeysDialog.hoursInput.itself,
        testIds.workArea.license.bms.assignKeysDialog.hoursInput.errorLabel,
        "number"
    ),
    new FormSectionLabel(
        "available",
        "Licenses.bms.assignDialog.available",
        testIds.workArea.license.bms.assignKeysDialog.availableLabel
    ),
    new FormSectionInput(
        "assignCount",
        "Licenses.bms.assignDialog.assign",
        testIds.workArea.license.bms.assignKeysDialog.assignInput.itself,
        testIds.workArea.license.bms.assignKeysDialog.assignInput.errorLabel,
        "number"
    ),
    new FormSectionInput(
        "startDate",
        "Licenses.bms.assignDialog.startDate",
        testIds.workArea.license.bms.assignKeysDialog.startDateInput.itself,
        testIds.workArea.license.bms.assignKeysDialog.startDateInput.errorLabel,
        "date"
    ),
    new FormSectionInput(
        "expirationDate",
        "Licenses.bms.assignDialog.expirationDate",
        testIds.workArea.license.bms.assignKeysDialog.expirationDateInput.itself,
        testIds.workArea.license.bms.assignKeysDialog.expirationDateInput.errorLabel,
        "date"
    ),
];

const connector = connect((state: StoreState) => ({
    hasBmsValidationLicenses: state.licensesReducer.hasBmsValidationLicenses,
    hasBmsBbtiLicenses: state.licensesReducer.hasBmsBbtiLicenses,
    hasBmsInsuranceLicenses: state.licensesReducer.hasBmsInsuranceLicenses,
    hasBmsNtfLicenses: state.licensesReducer.hasBmsNtfLicenses,
    hasBmsLeaseLicenses: state.licensesReducer.hasBmsLeaseLicenses,
    hasBmsAllLicenses: state.licensesReducer.hasBmsAllLicenses,
    hasUsdkLicenses: state.licensesReducer.hasUsdkLicenses,
}));

function Form(props: Props & ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();

    const BMS_VALIDATION_KEY = "bms-validation";
    const BMS_BBTI_KEY = "bms-bbti";
    const BMS_INSURANCE_KEY = "bms-insurance";
    const BMS_NTF_KEY = "bms-ntf";
    const BMS_LEASE_KEY = "bms-lease";
    const BMS_ALL_KEY = "bms-all";
    const USDK_KEY = "usdk";
    const DEFAULT_BMS_KEY = props.hasBmsValidationLicenses
        ? BMS_VALIDATION_KEY
        : props.hasBmsBbtiLicenses
        ? BMS_BBTI_KEY
        : props.hasUsdkLicenses
        ? USDK_KEY
        : props.hasBmsLeaseLicenses
        ? BMS_LEASE_KEY
        : props.hasBmsAllLicenses
        ? BMS_ALL_KEY
        : props.hasBmsNtfLicenses
        ? BMS_NTF_KEY
        : BMS_INSURANCE_KEY;

    const [summaryLabelParameter, setSummaryLabelParameter] = React.useState<SummaryLabelParameter>({
        initialRender: true,
        availableLicenses: 0,
        expiration: "1970-01-01T00:00:00Z",
        licenseType: DEFAULT_BMS_KEY,
    });

    const DEFAULT_FORM_VALUES = {
        keyCount: 1,
        association: t("Licenses.bms.assignDialog.associationMultiple"),
        // This needs to be empty because otherwise we'll get a warning in browser console: "A component is changing
        // an uncontrolled input of type text to be controlled. Input elements should not switch from uncontrolled
        // to controlled (or vice versa). Decide between using a controlled or uncontrolled input element for the
        // lifetime of the component. More info: https://fb.me/react-controlled-components".
        identifier: "",
        hours: 1,
        licenseType: DEFAULT_BMS_KEY,
        available: summaryLabelParameter.availableLicenses,
        assignCount: 1,
        startDate: daysAfterToday(0),
        expirationDate: daysAfterToday(1),
    };

    const [state, setState] = React.useState<State>({
        phase: Phase.LOADING_AVAILABLE_LICENSES,
        formValues: DEFAULT_FORM_VALUES,
    });

    const [licenseType, setLicenseType] = React.useState<string>(DEFAULT_BMS_KEY);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const fetchAvailableLicenses = (licenseType: string) => {
        const abortController = new AbortController();

        abortControllers.push(abortController);
        licenseService
            .fetchAvailableLicenses(licenseType, abortController)
            .then((data) => {
                setSummaryLabelParameter({
                    initialRender: false,
                    availableLicenses: data.availableLicenses,
                    expiration: createDateLabel(data.expiration.substring(0, 10)),
                    licenseType: licenseType,
                });
            })
            .catch((err) => {
                logger.error("Exception occurred while fetching available licenses: ", err);
                setState({
                    ...state,
                    phase: Phase.FETCH_AVAILABLE_LICENSES_FAILED,
                });
            });
    };

    React.useEffect(() => {
        fetchAvailableLicenses(DEFAULT_BMS_KEY);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    React.useEffect(() => {
        if (!summaryLabelParameter.initialRender) {
            setState({
                phase: Phase.USER,
                formValues: {
                    ...state.formValues,
                    licenseType: summaryLabelParameter.licenseType,
                    available: summaryLabelParameter.availableLicenses,
                },
            });
        }
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [summaryLabelParameter]);

    const handleLicenseType = (selectedLicenseType: string) => {
        setLicenseType(selectedLicenseType);
        DEFAULT_FORM_VALUES["licenseType"] = selectedLicenseType;
        setState({
            phase: Phase.LOADING_AVAILABLE_LICENSES,
            formValues: DEFAULT_FORM_VALUES,
        });

        fetchAvailableLicenses(selectedLicenseType);
    };

    const enabledLicenseTypes = [];
    props.hasBmsValidationLicenses && enabledLicenseTypes.push(BMS_VALIDATION_KEY);
    props.hasBmsBbtiLicenses && enabledLicenseTypes.push(BMS_BBTI_KEY);
    props.hasBmsInsuranceLicenses && enabledLicenseTypes.push(BMS_INSURANCE_KEY);
    props.hasBmsNtfLicenses && enabledLicenseTypes.push(BMS_NTF_KEY);
    props.hasBmsLeaseLicenses && enabledLicenseTypes.push(BMS_LEASE_KEY);
    props.hasBmsAllLicenses && enabledLicenseTypes.push(BMS_ALL_KEY);
    props.hasUsdkLicenses && enabledLicenseTypes.push(USDK_KEY);

    const select = new FormSectionSelect(
        "licenseType",
        "Licenses.bms.assignDialog.licenseType",
        testIds.workArea.license.bms.assignKeysDialog.licenseTypeLabel.itself,
        testIds.workArea.license.bms.assignKeysDialog.licenseTypeLabel.errorLabel,
        enabledLicenseTypes,
        licenseType,
        handleLicenseType
    );

    function submit(values: LicenseKeyAssignment) {
        values.startDate = new Date(values.startDate).toISOString().replace(/.000/, "");
        values.expirationDate = formatUtcDateString(
            values.expirationDate,
            HOUR,
            MINUTES_SECONDS_MILLISECONDS,
            MINUTES_SECONDS_MILLISECONDS
        );

        setState({ phase: Phase.SENDING, formValues: values });

        licenseService
            .assignLicenseKey(values)
            .then(() => props.onSuccess())
            .catch(() => {
                // Use _previous_ to restore the values in the form as they were.
                setState((previous) => ({ phase: Phase.FAILED, formValues: previous.formValues }));
            });
    }

    if (state.phase === Phase.SENDING || state.phase === Phase.LOADING_AVAILABLE_LICENSES) {
        return <LoadingIndicator />;
    }
    if (state.phase === Phase.FETCH_AVAILABLE_LICENSES_FAILED) {
        return (
            <div>
                <div className={formStyle.submitError}>
                    {t("Licenses.bms.assignDialog.fetchLicensesFailureMessage")}
                </div>
                <div className={formStyle.buttonContainer}>
                    <button
                        className={buttonStyle.primaryButtonWithoutIcon}
                        type="submit"
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={props.onFetchLicensesFailure}
                    >
                        {t("Common.close")}
                    </button>
                </div>
            </div>
        );
    }
    if (state.phase === Phase.FAILED) {
        const onClick = () => setState((previous) => ({ phase: Phase.USER, formValues: previous.formValues }));
        return (
            <div>
                <div className={formStyle.submitError}>{t("Licenses.bms.assignDialog.failureMessage")}</div>
                <div className={formStyle.buttonContainer}>
                    <button
                        className={buttonStyle.primaryButtonWithoutIcon}
                        type="submit"
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={onClick}
                    >
                        {t("Common.close")}
                    </button>
                </div>
            </div>
        );
    }

    const schema = object().shape({
        keyCount: number()
            .min(1, (params) => t("Licenses.bms.assignDialog.numberOfKeysMinError", { min: params.min }))
            .max(300, (params) => t("Licenses.bms.assignDialog.numberOfKeysMaxError", { max: params.max }))
            .required(t("Licenses.bms.assignDialog.numberOfKeysRequiredError")),
        identifier: string().max(36, (params) =>
            t("Licenses.bms.assignDialog.identifierMaxError", { max: params.max })
        ),
        hours: number()
            .min(1, (params) => t("Licenses.bms.assignDialog.usageHoursMinError", { min: params.min }))
            .max(5 * 365 * 24, (params) => t("Licenses.bms.assignDialog.usageHoursMaxError", { max: params.max }))
            .required(t("Licenses.bms.assignDialog.usageHoursRequiredError")),
        assignCount: number()
            .min(1, (params) => t("Licenses.bms.assignDialog.assignMinError", { min: params.min }))
            .when("keyCount", (keyCount: number, schema: NumberSchema<number>) =>
                schema.max(Math.trunc(summaryLabelParameter.availableLicenses / keyCount), (params) =>
                    t("Licenses.bms.assignDialog.assignMaxError", { max: params.max })
                )
            )
            .required(t("Licenses.bms.assignDialog.assignRequiredError")),
        startDate: date()
            .min(daysAfterToday(0), (params) =>
                t("Licenses.bms.assignDialog.startDateMinError", { min: createDateLabel(String(params.min)) })
            )
            .required(t("Licenses.bms.assignDialog.startDateRequiredError")),
        expirationDate: date()
            .required(t("Licenses.bms.assignDialog.expirationDateRequiredError"))
            .max(summaryLabelParameter.expiration, (params) =>
                t("Licenses.bms.assignDialog.expirationDateMaxError", { max: params.max })
            )
            .when("startDate", (startDate: string, schema: DateSchema<Date | null | undefined>) => {
                const minDate = new Date(startDate);
                minDate.setDate(minDate.getDate());
                return schema.min(formatIsoDate(minDate), (params) =>
                    t("Licenses.bms.assignDialog.expirationDateMinError", { min: createDateLabel(String(params.min)) })
                );
            }),
    });
    return (
        <div>
            <div>
                {summaryLabelParameter.availableLicenses > 0
                    ? t("Licenses.bms.assignDialog.summaryText", summaryLabelParameter)
                    : t("Licenses.bms.assignDialog.noLicensesAvailable")}
            </div>
            <Formik initialValues={state.formValues} onSubmit={submit} validationSchema={schema}>
                {(formikProps: FormikProps<LicenseKeyAssignment>) => (
                    <FormikForm>
                        {[select, ...FORM_SECTIONS].map((section, index) =>
                            createFormSection(t, section, "" + index, formikProps)
                        )}

                        <div className={formStyle.buttonContainer}>
                            <button
                                className={buttonStyle.primaryButtonWithoutIcon}
                                type="submit"
                                data-testid={testIds.workArea.license.bms.assignKeysDialog.submitButton}
                            >
                                {t("Licenses.bms.assignDialog.assignButton")}
                            </button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}

export default connector(Form);
