import classNames from "classnames";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { exportFile } from "components/export/common";
import { subpageContext } from "components/layout/ApplicationLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import NotificationModal from "components/modal/NotificationModal";
import ManageReportViewModal from "components/reports/ManageReportViewModal";
import style from "components/reports/views/report-view-kebab-menu.scss";
import {
    AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN,
    AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED,
    AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN,
    AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED,
} from "domain/authority";
import { CustomReportView, toCustomReportViewFilterGroupDto } from "domain/reports";
import { ReportPath, reportViewService } from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const ReportViewKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        name: string;
        viewDetails: CustomReportView;
        onViewDelete: () => void;
        shared: boolean;
        paths?: ReportPath[];
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteViewModalVisible, setDeleteViewModalVisible] = React.useState(false);
    const [actionIsEdit, setActionIsEdit] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", resultVisible: false });
    const [okClicked, setOkClicked] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [confirmationState, setConfirmationState] = React.useState(true);
    const [failed, setFailed] = React.useState(false);
    const paths = reportViewService.getPaths();
    const [visibleErrorDialog, setVisibleErrorDialog] = React.useState(false);
    function handleConfirmation() {
        setConfirmationState(
            (document.getElementById("confirmInput") as HTMLInputElement).value !== t("Common.delete").toUpperCase()
        );
    }
    const hideResultAndRedirectToRoot = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });
        props.onViewDelete();
    };

    const hideResultAndRefreshPage = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });
        setActionIsEdit(false);
        window.location.reload();
    };

    const deleteView = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportViewService
            .deleteView(props.viewDetails.uuid, abortController)
            .then(() => {
                setResult({
                    title: t("DeleteReportView.viewDeleted.success.title"),
                    message: t("DeleteReportView.viewDeleted.success.message"),
                    resultVisible: true,
                });
                setFailed(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("DeleteReportView.viewDeleted.failed.title"),
                        message: t("DeleteReportView.viewDeleted.failed.message"),
                        resultVisible: true,
                    });
                    setFailed(true);
                }
            })
            .finally(() => {
                setOkClicked(false);
                setDeleteViewModalVisible(false);
                setConfirmationState(true);
            });
    };

    const exportView = () => {
        const exportData = JSON.stringify(getExportData(), null, 4);
        const blob = new Blob([exportData], {
            type: "application/json",
        });
        exportFile(URL.createObjectURL(blob), props.viewDetails.name + ".json");
    };
    const getExportData = () => {
        const details = props.viewDetails;
        return {
            view: {
                name: details.name,
                columns: details.columns,
                filters: toCustomReportViewFilterGroupDto(details.filters),
            },
        };
    };

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    const handleViewDelete = () => {
        setOkClicked(true);
        deleteView();
    };

    const context = React.useContext(subpageContext);

    return (
        <>
            <Menu data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                <ul className={style.kebabMenu}>
                    {userSessionService.userHasAnyAuthority([
                        props.shared ? AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED : AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN,
                    ]) && (
                        <li key={"edit"}>
                            <MenuItemButton
                                className={style.editText}
                                onClick={() => {
                                    context?.setSubpage(
                                        <ManageReportViewModal
                                            visible={true}
                                            handleVisibility={() => true}
                                            paths={paths}
                                            create={false}
                                            name={props.name}
                                            viewDetails={props.viewDetails}
                                        />
                                    );
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_VIEW,
                                        action: Action.EDIT_REPORT_VIEW,
                                    });
                                }}
                            >
                                {t("Common.edit")}
                            </MenuItemButton>
                        </li>
                    )}
                    {
                        <li key={"export"}>
                            <MenuItemButton
                                className={style.kebabMenuItem}
                                onClick={() => {
                                    exportView();
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_VIEW,
                                        action: Action.EXPORT_REPORT_VIEW,
                                    });
                                }}
                                data-testid={testIds.workArea.report.primaryView.kebabMenu.exportButton}
                            >
                                {t("Common.export")}
                            </MenuItemButton>
                        </li>
                    }
                    {
                        <li key={"clone"}>
                            <MenuItemButton
                                className={style.kebabMenuItem}
                                onClick={() => {
                                    context?.setSubpage(
                                        <ManageReportViewModal
                                            visible={true}
                                            handleVisibility={() => true}
                                            paths={paths}
                                            create={true}
                                            name={props.name + "_Clone"}
                                            viewDetails={props.viewDetails}
                                        />
                                    );
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_VIEW,
                                        action: Action.CLONE_REPORT_VIEW,
                                    });
                                }}
                                data-testid={testIds.workArea.report.primaryView.kebabMenu.cloneButton}
                            >
                                {t("Common.clone")}
                            </MenuItemButton>
                        </li>
                    }
                    {userSessionService.userHasAnyAuthority([
                        props.shared ? AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED : AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN,
                    ]) && (
                        <li key={"delete"}>
                            <MenuItemButton
                                className={style.kebabMenuItem}
                                onClick={() => {
                                    setDeleteViewModalVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_VIEW,
                                        action: Action.REMOVE_REPORT_VIEW,
                                    });
                                }}
                                data-testid={testIds.workArea.report.primaryView.kebabMenu.deleteButton}
                            >
                                {t("Common.delete")}
                            </MenuItemButton>
                        </li>
                    )}
                </ul>
            </Menu>
            <>
                <Modal
                    isOpen={deleteViewModalVisible}
                    hideModal={() => setDeleteViewModalVisible(false)}
                    modalTitle={t("DeleteReportView.title", { name: props.name })}
                >
                    {okClicked ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            {deleteViewModalVisible ? (
                                <>
                                    <div className={style.resultContainer}>
                                        {t("DeleteReportView.confirmationMessage", { name: props.name })}
                                    </div>
                                    <div className={style.resultContainer}>
                                        <input
                                            className={classNames(form.input, style.input)}
                                            id="confirmInput"
                                            type="text"
                                            data-testid={testIds.common.confirmationDialog.confirmInput}
                                            placeholder={t("Common.delete").toUpperCase()}
                                            onChange={handleConfirmation}
                                        />
                                    </div>

                                    <div className={form.buttonContainer}>
                                        <button
                                            className={buttons.secondaryButtonWithoutIcon}
                                            onClick={() => setDeleteViewModalVisible(false)}
                                            data-testid={testIds.common.dialog.closeButton}
                                        >
                                            {t("Common.cancel")}
                                        </button>

                                        <button
                                            className={classNames(
                                                buttons.primaryButtonWithoutIcon,
                                                buttons.deleteButton
                                            )}
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                            onClick={handleViewDelete}
                                            disabled={confirmationState}
                                        >
                                            {t("Common.delete")}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </Modal>
                <>
                    <NotificationModal
                        open={visibleErrorDialog}
                        hide={() => setVisibleErrorDialog(false)}
                        title={t("CreateReportView.loadFailedDialog.title")}
                        message={t("CreateReportView.loadFailedDialog.message")}
                    />
                </>
                <Modal
                    isOpen={result.resultVisible}
                    hideModal={actionIsEdit ? hideResultAndRefreshPage : hideResultAndRedirectToRoot}
                    modalTitle={result.title}
                >
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={form.buttonContainer}>
                        <button
                            className={buttons.primaryOkButton}
                            onClick={() => {
                                if (!failed) {
                                    if (actionIsEdit) {
                                        hideResultAndRefreshPage();
                                    } else {
                                        hideResultAndRedirectToRoot();
                                    }
                                } else {
                                    setResult((prevState) => ({
                                        ...prevState,
                                        resultVisible: false,
                                    }));
                                }
                            }}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
            </>
        </>
    );
};

export default connector(ReportViewKebabMenu);
