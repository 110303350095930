import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import style from "./custom-report.scss";
import { convertToStandardUnit } from "components/reports/AllReportsTable";
import { capacityUnits } from "domain/customReports";
import { CUSTOM_REPORT_FIELD_MAX_LENGTH } from "domain/globalConstants";
import form from "styles/form.scss";

import testIds from "testIds.json";

export const CapacityField = React.memo(
    ({
        id,
        setFieldValue,
        label,
        dataPath,
    }: {
        id: string;
        setFieldValue: (field: string, value: string | number, shouldValidate?: boolean) => void;
        label: string;
        dataPath: string;
    }) => {
        const { t } = useTranslation();
        const [error, setError] = React.useState<string>("");
        const [capacityUnit, setCapacityUnit] = React.useState<string>("B");
        const [capacityValue, setCapacityValue] = React.useState<number | null>(null);

        const handleCapacityChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value.trim();
                const numericValue = Number(value);
                if (numericValue < 0) {
                    setError(t("ErasureReport.customReport.customReportTemplate.validationMessage"));
                    setCapacityValue(null);
                } else {
                    setCapacityValue(numericValue);
                    setError("");
                }
            },
            [setFieldValue, t, capacityUnit]
        );

        const handleUnitChange = useCallback(
            (event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedUnit = event.target.value;
                setCapacityUnit(selectedUnit);
            },
            [setFieldValue]
        );

        React.useEffect(() => {
            if (capacityValue !== null) {
                const convertedValue = convertToStandardUnit(dataPath, capacityValue, capacityUnit);
                setFieldValue(dataPath, convertedValue);
            } else {
                setFieldValue(dataPath, "");
            }
        }, [capacityValue, capacityUnit]);

        return (
            <div className={form.formFields}>
                <label htmlFor={id} className={form.label}>
                    {label}
                </label>
                <input
                    id={id}
                    type="number"
                    className={form.input}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    onChange={handleCapacityChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path={dataPath}
                />
                <select
                    id={id + "Unit"}
                    onChange={handleUnitChange}
                    className={classNames(form.select, style.unitSelect)}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldSelect}
                >
                    {capacityUnits.map((unit) => (
                        <option key={unit} value={unit}>
                            {unit}
                        </option>
                    ))}
                </select>
                {error && <div className={form.error}>{error}</div>}
            </div>
        );
    }
);
