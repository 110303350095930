export interface MonitorFormValues {
    aspectRatio?: string;
    comments?: string;
    make?: string;
    model?: string;
    resolution?: string;
    serialNumber?: string;
    size?: string;
    type?: string;
}

export interface CustomFieldFormValues {
    fieldName?: string;
    fieldValue?: string;
}
export interface HddFormValues {
    capacity?: number;
    capacityUnit?: string;
    vendor?: string;
    model?: string;
    serialNumber?: string;
}

export enum TemplateTypeEnum {
    HDD = "HDD",
    Laptop = "Laptop",
    Mobiledevice = "Mobiledevice",
    Monitor = "Monitor",
    PC = "PC",
}

export interface MobileDeviceValues {
    assetTag?: string;
    comments?: string;
    handlingProcess?: string;
    imei?: string;
    make?: string;
    model?: string;
    identifier?: string;
    manufacturer?: string;
    type?: string;
}

export interface LaptopValues {
    assetTag?: string;
    comments?: string;
    hddSize?: number;
    make?: string;
    memory?: number;
    model?: string;
    processor?: string;
    serialNumber?: string;
    size?: string;
    type?: string;
}

export interface PcValues {
    aspectRatio?: string;
    chassisType?: string;
    comments?: string;
    hddSize?: number;
    make?: string;
    memory?: number;
    model?: string;
    processor?: string;
    serialNumber?: string;
    type?: string;
}

export const templateTypes = ["HDD", "Laptop", "Mobiledevice", "Monitor", "PC"] as const;
export type TemplateType = typeof templateTypes[number];

export const capacityUnits = ["B", "KB", "MB", "GB", "TB"] as const;
export type CapacityUnit = typeof capacityUnits[number];
