import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Label } from "reactstrap";

import RecommendationForm from "./RecommedationsForm";
import WarningYellowNotificationIcon from "components/icons/WarningYellowNotificationIcon";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { FaultCode, NtfConfiguration, Test } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import DiagnosticsTestsForm from "components/licenses/license-configuration/bms/diagnostics/DiagnosticsTestsForm";
import InputComponent from "components/licenses/license-configuration/bms/form-components/BmsTextInputComponent";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { getFaultCodeTranslation } from "utils/commonFunctions";

import testIds from "testIds.json";

interface Props {
    minWidth?: number;
    faultCodeHandler: (faultCode: FaultCode) => void;
    editMode: boolean;
    faultCode: FaultCode;
    platform: string;
    ntfConfiguration: NtfConfiguration;
    faultCodeKeys: string[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const FaultCodeForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [faultCode, setFaultCode] = useState<FaultCode>(props.faultCode);
    const [faultCodeKeyError, setFaultCodeKeyError] = useState<string>();

    const [recommendationTabError, setRecommendationTabError] = useState<boolean>(!props.editMode);
    const [testsTabError, setTestsTabError] = useState<boolean>(!props.editMode);

    function updateMasterTestList(tests: Test[]) {
        for (const updatedTest of tests) {
            const testIndex = props.ntfConfiguration.tests.findIndex((test) => test.name === updatedTest.name);

            if (testIndex !== -1) {
                props.ntfConfiguration.tests[testIndex] = updatedTest;
            } else {
                props.ntfConfiguration.tests.push(updatedTest);
            }
        }
    }

    const handleTestsChange = (tests: Test[]) => {
        setTestsTabError(tests.length == 0);
        setFaultCode((prevState) => ({
            ...prevState,
            tests: tests.map((item) => ({ name: item.name })),
        }));

        updateMasterTestList(tests);
    };

    const getFaultCodeTests = () => {
        const transformedTests: Test[] = [];

        if (faultCode && faultCode.tests) {
            for (const selectFaultCodeTest of faultCode.tests) {
                const matchingTest = props.ntfConfiguration.tests.find(
                    (test) => test.name === selectFaultCodeTest.name
                );

                if (matchingTest) {
                    transformedTests.push(matchingTest);
                }
            }
        }
        return transformedTests;
    };

    const handleRecommendationChange = (faultCode: FaultCode) => {
        setRecommendationTabError(faultCode.recommendations.length == 0);
        setFaultCode(faultCode);
    };

    function handleCustomFaultCodeInputChange(field: keyof FaultCode, newValue: string) {
        if (field === "text" && props.editMode) {
            setFaultCodeKeyError(
                props.faultCodeKeys.includes(newValue)
                    ? t("Configuration.ntfConfigurationForm.duplicateFaultcodeKey")
                    : undefined
            );
        }

        setFaultCode((prevState) => ({
            ...prevState,
            [field]: newValue,
        }));
    }

    function handleFaultCodeSave() {
        props.faultCodeHandler(faultCode);
    }

    const isDisabled =
        !!faultCodeKeyError ||
        !faultCode.text ||
        faultCode.recommendations.length === 0 ||
        faultCode.tests.length === 0;

    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>
                        {!!faultCodeKeyError && <WarningYellowNotificationIcon />}
                        {t("Configuration.viewConfigureDialog.faultCodeTab")}
                    </Tab>
                    <Tab>
                        {recommendationTabError && <WarningYellowNotificationIcon />}
                        {t("Configuration.viewConfigureDialog.faultCodeRecommendationsTab")}
                    </Tab>
                    <Tab>
                        {testsTabError && <WarningYellowNotificationIcon />}
                        {t("Configuration.viewConfigureDialog.faultCodeTestsTab")}
                    </Tab>
                </TabList>

                <div className={style.tabPanel}>
                    <TabPanel>
                        <div className={classNames(style.boxed, style.standardizedForm)}>
                            <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                {t("Configuration.ntfConfigurationForm.faultCode")}
                            </Label>
                            <InputComponent
                                labelKey={t("Configuration.ntfConfigurationForm.faultCodeKey")}
                                toolTipContent={t("Configuration.tooltips.ntfConfigurationForm.faultCodeKey")}
                                inputId="text"
                                value={
                                    faultCode.text
                                        ? faultCode.text
                                        : t(getFaultCodeTranslation(faultCode.key)) != faultCode.key
                                        ? t(getFaultCodeTranslation(faultCode.key))
                                        : ""
                                }
                                onChange={(newValue) => handleCustomFaultCodeInputChange("text", newValue)}
                                inputError={faultCodeKeyError}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={classNames(style.standardizedForm)}>
                            <RecommendationForm faultCode={faultCode} onFaultCodeChange={handleRecommendationChange} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={classNames(style.standardizedForm)}>
                            <DiagnosticsTestsForm
                                currentEnabledTests={getFaultCodeTests()}
                                onTestChange={handleTestsChange}
                                platform={props.platform}
                            />
                        </div>
                    </TabPanel>
                </div>
            </Tabs>
            <div className={form.buttonContainer}>
                <button
                    className={buttons.primaryButtonWithoutIcon}
                    data-testid={testIds.workArea.license.configure.manageConfigurationDialog.saveButton}
                    onClick={handleFaultCodeSave}
                    disabled={isDisabled}
                >
                    {t("Configuration.editConfigureDialog.saveButton")}
                </button>
            </div>
        </>
    );
};

export default connector(FaultCodeForm);
