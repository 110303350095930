import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import ManageRoleForm, { FormValues } from "./manage-role/ManageRoleForm";
import style from "./user-roles.scss";
import UserRolesKebabMenu from "./UserRolesKebabMenu";
import Star, { Color as StarColor } from "components/icons/Star";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import AuthorityView from "components/users/user-roles/manage-role/AuthorityView";
import { AUTH_ROLE_CREATE, AUTH_ROLE_DEFAULT, AUTH_ROLE_DELETE, AUTH_ROLE_EDIT } from "domain/authority";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import { Role, UserRolesTableData } from "domain/roles";
import { rolesService } from "services/roles/RolesService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import buttons from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
}
interface TableState {
    userRolesData: UserRolesTableData[];
    cursor: string;
    scrollPosition?: number;
}

export interface Props {
    count: number;
    initialRoleData: Role;
    onRoleEdit: () => void;
    search?: string;
    onRoleDelete: () => void;
}

const UserRolesTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        userRolesData: [],
        cursor: "",
        scrollPosition: 0,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState("");
    const [editRoleFormVisible, setEditRoleFormVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [activeRoleDetails, setActiveRoleDetails] = React.useState<Role>(props.initialRoleData);
    const [roleDetailsVisibility, setRoleDetailsVisibility] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);
    const showRoleDetails = (rolesData: Role) => {
        setRoleDetailsVisibility(true);
        setActiveRoleDetails(rolesData);
    };

    const hideRoleDetails = () => {
        setRoleDetailsVisibility(false);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };
    const hideResult = () => {
        setResultVisible(false);
        props.onRoleEdit();
    };

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        rolesService
            .fetchRoles(abortController, "", initialLoading ? "" : tableState.cursor, search)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.userRolesData.length - 1,
                    userRolesData: prevState.userRolesData.concat(data.roles),
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController?.signal.aborted) {
                    setRequestFailureMessage(t("UserRoles.table.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController?.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const editRoleSubmitEventHandler = async ({
        name,
        description,
        authorities,
        targets,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await rolesService.editRole(
                activeRoleDetails.roleUuid,
                name,
                description,
                authorities,
                targets,
                abortController
            );
        } catch (e) {
            if (!signal.aborted) {
                setEditRoleFormVisible(false);
                showResult({
                    title: t("ManageRoleForm.edit.failure.title"),
                    message: t("ManageRoleForm.edit.failure.message"),
                });
                logger.error("Failed to edit a role:", e);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setRoleDetailsVisibility(false);
        setEditRoleFormVisible(false);
        showResult({
            title: t("ManageRoleForm.edit.success.title"),
            message: t("ManageRoleForm.edit.success.message"),
        });
    };

    const modal =
        activeRoleDetails != null ? (
            <Modal
                key={1}
                isOpen={roleDetailsVisibility}
                hideModal={hideRoleDetails}
                modalTitle={t("RoleDetails.title", { name: activeRoleDetails.name })}
            >
                <div className={style.fixedWidthModal}>
                    <AuthorityView
                        authorities={activeRoleDetails.authority}
                        targets={activeRoleDetails.targets}
                        onView={true}
                        isManager={activeRoleDetails.defaultMigrationRole}
                    />
                </div>
                <div className={style.buttonContainer}>
                    {userSessionService.userHasAllAuthorities([AUTH_ROLE_EDIT]) && (
                        <button
                            className={buttons.primaryButtonWithoutIcon}
                            data-testid={testIds.workArea.roles.manageRoleDialog.editButton}
                            onClick={() => setEditRoleFormVisible(true)}
                        >
                            {t("Common.edit")}
                        </button>
                    )}
                    <button
                        className={buttons.secondaryButtonWithoutIcon}
                        onClick={() => setRoleDetailsVisibility(false)}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.close")}
                    </button>
                </div>
            </Modal>
        ) : null;

    const columns: Array<Column<UserRolesTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("UserRoles.table.roleName")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    {userSessionService.userHasAnyAuthority([
                        AUTH_ROLE_EDIT,
                        AUTH_ROLE_DELETE,
                        AUTH_ROLE_DEFAULT,
                        AUTH_ROLE_CREATE,
                    ]) && (
                        <KebabMenu>
                            <UserRolesKebabMenu
                                roleUuid={cellInfo.cell.row.original.roleUuid}
                                name={cellInfo.cell.row.original.name}
                                description={cellInfo.cell.row.original.description}
                                authorities={cellInfo.cell.row.original.authorities}
                                targets={cellInfo.cell.row.original.targets}
                                isManager={cellInfo.cell.row.original.defaultMigrationRole}
                                isDefault={cellInfo.cell.row.original.defaultRole}
                                onRoleEdit={props.onRoleEdit}
                                onRoleDelete={props.onRoleDelete}
                            />
                        </KebabMenu>
                    )}
                    <button
                        className={style.nameCell}
                        onClick={() => {
                            showRoleDetails({
                                roleUuid: cellInfo.cell.row.original.roleUuid,
                                name: cellInfo.cell.row.original.name,
                                description: cellInfo.cell.row.original.description,
                                authority: cellInfo.cell.row.original.authorities,
                                defaultMigrationRole: cellInfo.cell.row.original.defaultMigrationRole,
                                targets: cellInfo.cell.row.original.targets,
                            });
                            usageStatisticsService.sendEvent({
                                category: Category.ROLE,
                                action: Action.VIEW_ROLE_DETAILS,
                            });
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </button>
                </>
            ),
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserRoles.table.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserRoles.table.creationDate")} key="3" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserRoles.table.lastEdited")} key="3" />,
            accessor: "editedDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserRoles.table.creator")} key="4" />,
            accessor: "creator",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserRoles.table.defaultRole")} key="5" />,
            accessor: "defaultRole",
            Cell: ({ cell: { value } }) =>
                value === true && <Star color={StarColor.GREEN} titleText={t("AltText.yourDefaultRole")} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
    ];

    React.useEffect(() => {
        setTableState({ userRolesData: [], cursor: "", scrollPosition: 0 });
        fetchData(true);
    }, [props.count, search]);

    let dataCount = null;
    if (tableState.userRolesData.length > 0) {
        dataCount = <div>{t("Common.defaultSearchResultHint", { dataCount: tableState.userRolesData.length })}</div>;
    }

    const editRoleModal = (
        <Modal
            isOpen={editRoleFormVisible}
            hideModal={() => setEditRoleFormVisible(false)}
            modalTitle={t("ManageRoleForm.edit.editRoleTitle")}
        >
            <div className={style.fixedWidthModal}>
                <TextBlock>{t("ManageRoleForm.edit.editRoleSummaryText")}</TextBlock>
                <ManageRoleForm
                    name={activeRoleDetails.name}
                    description={activeRoleDetails.description}
                    authorities={activeRoleDetails.authority}
                    targets={activeRoleDetails.targets}
                    isManager={activeRoleDetails.defaultMigrationRole}
                    isEditing={true}
                    submitEventHandler={editRoleSubmitEventHandler}
                />
            </div>
        </Modal>
    );

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearch} searchInProgress={false} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.USER_ROLES_TABLE}
                    data={tableState.userRolesData}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    emptyMessage={""}
                    testId={testIds.workArea.roles.table.itself}
                />
            </div>
            {tableState.cursor != null &&
                tableState.userRolesData.length >= TABLE_PAGE_LIMIT &&
                tableState.userRolesData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={buttons.loadMoreButtonWithoutIcon}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                action: Action.LOAD_MORE,
                                category: Category.ROLE,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            {modal}
            {editRoleModal}
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={buttons.primaryOkButton}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default UserRolesTable;
