import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import CustomReportView from "./CustomReportView";
import PlusIcon from "components/icons/PlusIcon";
import { subpageContext } from "components/layout/ApplicationLayout";
import TextWithTooltip from "components/table/TextWithTooltip";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const CustomReport = (): JSX.Element => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);

    return (
        <>
            <TextWithTooltip text={t("ErasureReport.customReport.buttonTitle")}>
                <button
                    className={classNames(buttons.primaryButton)}
                    onClick={() => {
                        context?.setSubpage(<CustomReportView setVisible={() => true} visible={true} />);
                    }}
                    data-testid={testIds.workArea.report.primaryView.table.createReportButton}
                >
                    <PlusIcon /> {t("ErasureReport.customReport.buttonTitle")}
                </button>
            </TextWithTooltip>
        </>
    );
};

export default CustomReport;
