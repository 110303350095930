import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./alert-dialog.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import Warning from "components/icons/Warning";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { StoreState } from "store";

import testIds from "testIds.json";

export interface AlertButton {
    text: string;
    action: () => void;
}

export type AlertType = "WARNING" | "ERROR";

export interface Props {
    isOpen: boolean;
    hideModal: () => void;
    headingText: string;
    primaryButton: AlertButton;
    type: AlertType;
    body?: string | JSX.Element;
    secondaryButton?: AlertButton;
    loading?: boolean;
}

const AlertDialog = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const mailToLink = `mailto:${SUPPORT_EMAIL}`;

    const createBody = () => {
        if (!props.body) {
            return (
                <TextBlock alignCenter={true}>
                    {t("Common.genericErrorMessage")} <a href={mailToLink}>{t("Common.genericContactSupport")}</a>
                </TextBlock>
            );
        }
        if (typeof props.body === "string") {
            return <TextBlock>{props.body}</TextBlock>;
        } else {
            return props.body;
        }
    };

    return (
        <Modal isOpen={props.isOpen} hideModal={props.hideModal} closeButton={true}>
            {props.isOpen && props.loading ? (
                <LoadingIndicator />
            ) : (
                <div
                    className={style.contentContainer}
                    data-testid={testIds.common.dialog.itself}
                    data-type={props.type}
                >
                    <div>
                        <Warning
                            color={
                                props.type === "WARNING"
                                    ? theme.secondaryWarningBackgroundColor
                                    : theme.errorBackgroundColor
                            }
                            strokeColor={props.type === "ERROR" ? theme.contentBackgroundColor : undefined}
                        />
                    </div>
                    <div>
                        <TextBlock alignCenter={true}>
                            <Heading tag={"h2"}>{props.headingText}</Heading>
                        </TextBlock>
                        {createBody()}
                    </div>
                    <ButtonContainer>
                        {props.primaryButton && (
                            <Button
                                variant={"PRIMARY"}
                                onClick={props.primaryButton.action}
                                testId={testIds.common.dialog.primaryButton}
                            >
                                {props.primaryButton.text}
                            </Button>
                        )}
                        {props.secondaryButton && (
                            <Button
                                variant={"SECONDARY"}
                                onClick={props.secondaryButton.action}
                                testId={testIds.common.dialog.closeButton}
                            >
                                {props.secondaryButton.text}
                            </Button>
                        )}
                    </ButtonContainer>
                </div>
            )}
        </Modal>
    );
};
export default AlertDialog;
