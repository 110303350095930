// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1dFOkjz-zy3cSiVGDSTHfC{text-align:center;margin-bottom:8px}.LPITiBHt7bl_JodJXTSjq{display:flex;column-gap:15px;margin:8px 0;width:405px}._2m7BzEgj6xviu2zUi6aVrC{border-bottom:none;margin-bottom:0;padding-bottom:0}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"banner": "_1dFOkjz-zy3cSiVGDSTHfC",
	"improvements": "LPITiBHt7bl_JodJXTSjq",
	"heading": "_2m7BzEgj6xviu2zUi6aVrC"
};
module.exports = exports;
