import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Column } from "react-table";

import LicenseAlertsKebabMenu from "./LicenseAlertsKebabMenu";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LicenseAlertData } from "components/licenses/license-alerts/manage-license-alerts/ManageLicenseAlertView";
import { ViewLicenseAlert } from "components/licenses/license-alerts/ViewLicenseAlert";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import tableStyle from "components/reports/erasure-reports-table.scss";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import style from "components/users/users.scss";
import { AUTH_LICENSE_VIEW } from "domain/authority";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import {
    Alert,
    LicenseAlert,
    LicenseAlertsResponse,
    licenseAlertsService,
} from "services/licenses/LicenseAlertService";
import { licensePoolService } from "services/licenses/LicensePoolService";
import { LicenseResponse, licenseService } from "services/licenses/LicenseService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

interface TableState {
    licenseAlerts: LicenseAlert[];
    cursor: string;
    scrollPosition?: number;
    alertsCount: number;
}

const connector = connect(mapState, { applyMode });

export interface AlertDetails {
    alertUuid: string;
    alertName: string;
    tenantName: string;
    tenantUuid: string;
    availableLicenses: LicenseAlertData[];
    expirationDate: string;
    poolName: string;
    poolUuid: string;
}

interface Props {
    count: number;
    setEditView: (value: boolean) => void;
    onLicenseAlertDelete: () => void;
    onLicenseAlertEdit: () => void;
    showForm: (value: boolean) => void;
    alertDetails: (value: AlertDetails) => void;
}

const LicenseAlertsTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [quickSearchValue, setQuickSearchValue] = React.useState("");
    const [licenseAlertDetailsVisibility, setLicenseAlertDetailsVisibility] = React.useState<boolean>(false);
    const [licenseAlertDetails, setLicenseAlertDetails] = React.useState<Alert>({
        alertName: "",
        alertUuid: "",
        tenantUuid: "",
        tenantName: "",
        licenses: [],
        poolName: "",
        poolUuid: "",
    });
    const [failureMessage, setFailureMessage] = React.useState<string>("");
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [tableState, setTableState] = React.useState<TableState>({
        licenseAlerts: [],
        cursor: "",
        scrollPosition: 0,
        alertsCount: 0,
    });
    const createColumns = (): Column<LicenseAlert>[] => {
        const columns: Array<Column<LicenseAlert>> = [];
        columns.push({
            Header: () => <TextWithTooltip text={t("LicenseAlerts.table.alertName")} key={columns.length} />,
            accessor: "alertName",
            id: "alertUuid",
            Cell: (cellInfo) => (
                <div className={classNames(tableStyle.alignment)}>
                    {
                        <div className={classNames(tableStyle.flexDisplay, tableStyle.marginRight)}>
                            <KebabMenu>
                                <LicenseAlertsKebabMenu
                                    licenseAlertUuid={cellInfo.cell.row.original.alertUuid}
                                    licenseAlertName={cellInfo.cell.row.original.alertName}
                                    onLicenseAlertDelete={props.onLicenseAlertDelete}
                                    onLicenseAlertEdit={props.onLicenseAlertEdit}
                                    showForm={props.showForm}
                                    edit={props.setEditView}
                                    tenantName={cellInfo.cell.row.original.tenantName}
                                    alertDetails={props.alertDetails}
                                    poolName={cellInfo.cell.row.original.poolName}
                                    poolUuid={cellInfo.cell.row.original.poolUuid}
                                />
                            </KebabMenu>
                        </div>
                    }
                    <div>
                        <button
                            className={style.userNameCell}
                            onClick={() => {
                                setFailureMessage("");
                                setLicenseAlertDetails({
                                    alertName: "",
                                    alertUuid: "",
                                    tenantUuid: "",
                                    tenantName: "",
                                    licenses: [],
                                    poolName: "",
                                    poolUuid: "",
                                });
                                setLoading(true);
                                setLicenseAlertDetailsVisibility(true);
                                try {
                                    processLicenseAlert(
                                        cellInfo.cell.row.original.alertUuid,
                                        cellInfo.cell.row.original.poolName
                                    ).catch(() => {
                                        setFailureMessage(t("LicenseAlerts.requestFailed"));
                                        setLoading(false);
                                    });
                                } catch (error) {
                                    setFailureMessage(t("LicenseAlerts.requestFailed"));
                                }
                                usageStatisticsService.sendEvent({
                                    label: Label.LICENSE_ALERT,
                                    action: Action.VIEW_LICENSE_ALERTS,
                                    category: Category.LICENSE_ALERTS,
                                });
                            }}
                        >
                            <TextWithTooltip text={cellInfo.cell.row.original.alertName} />
                        </button>
                    </div>
                </div>
            ),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("Common.customerName")} key={columns.length} />,
            accessor: "tenantName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("LicenseAlerts.table.creationDate")} key={columns.length} />,
            accessor: "creationDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
        });
        return columns;
    };

    const processLicenseAlert = async (alertUuid: string, poolName: string) => {
        const abortController = new AbortController();
        const data = await licenseAlertsService.fetchLicenseAlertByUuid(alertUuid, abortController);
        await fetchAndProcessData(data.alert, poolName);
    };

    const fetchAndProcessData = (alert: Alert, poolName: string) => {
        const abortController = new AbortController();
        const own = !userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW]);
        if (alert.poolUuid !== null) {
            licensePoolService
                .fetchPoolSpecificLicenses(abortController, alert.tenantUuid, alert.poolUuid)
                .then((data) => {
                    const tempTenantsLicenses = data.allLicenses;
                    const processedLicenses: LicenseAlertData[] = alert.licenses.map((license) => {
                        const matchingLicenseData = tempTenantsLicenses.find(
                            (licenseData) => licenseData.licenseId === license.type
                        );
                        return {
                            amountThreshold: license.amountThreshold,
                            expirationThreshold: license.expirationThreshold,
                            triggered: license.triggered,
                            expirationDate: matchingLicenseData ? matchingLicenseData.expirationDate : "",
                            remainingLicenses: matchingLicenseData?.amount,
                            type: license.type,
                            daysInputVisibility: true,
                            amountInputVisibility: true,
                        };
                    });
                    setLicenseAlertDetails({
                        alertName: alert.alertName,
                        alertUuid: alert.alertUuid,
                        tenantUuid: alert.tenantUuid,
                        tenantName: alert.tenantName,
                        licenses: processedLicenses,
                        poolName: poolName,
                        poolUuid: alert.poolUuid,
                    });
                })
                .catch(() => {
                    setFailureMessage(t("LicenseAlerts.requestFailed"));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            licenseService
                .fetchLicenses(
                    abortController,
                    undefined,
                    own,
                    alert.tenantUuid == userSessionService.currentUserDetails()?.tenantUuid ? "" : alert.tenantUuid
                )
                .then((data: LicenseResponse) => {
                    const tempTenantsLicenses = data.licenses;
                    const processedLicenses: LicenseAlertData[] = alert.licenses.map((license) => {
                        const matchingLicenseData = tempTenantsLicenses.find(
                            (licenseData) => licenseData.type === license.type
                        );
                        return {
                            amountThreshold: license.amountThreshold,
                            expirationThreshold: license.expirationThreshold,
                            triggered: license.triggered,
                            expirationDate: matchingLicenseData ? matchingLicenseData.expirationDate : "",
                            remainingLicenses: matchingLicenseData
                                ? own
                                    ? matchingLicenseData.available
                                    : matchingLicenseData.overallRemaining
                                : 0,
                            type: license.type,
                            daysInputVisibility: true,
                            amountInputVisibility: true,
                        };
                    });
                    setLicenseAlertDetails({
                        alertName: alert.alertName,
                        alertUuid: alert.alertUuid,
                        tenantUuid: alert.tenantUuid,
                        tenantName: alert.tenantName,
                        licenses: processedLicenses,
                        poolName: poolName,
                        poolUuid: alert.poolUuid,
                    });
                })
                .catch(() => {
                    setFailureMessage(t("LicenseAlerts.requestFailed"));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseAlertsService
            .fetchAllLicenseAlerts(abortController, tableState.cursor, quickSearchValue)
            .then((data: LicenseAlertsResponse) => {
                setTableState((prevState) => ({
                    ...prevState,
                    licenseAlerts: prevState.licenseAlerts.concat(data.alerts),
                    scrollPosition: prevState.licenseAlerts ? prevState.licenseAlerts.length - 1 : 0,
                    cursor: data.cursor,
                    alertsCount: data.count,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("LicenseAlerts.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setInitialLoading(true);
        setTableState({
            licenseAlerts: [],
            cursor: "",
            scrollPosition: 0,
            alertsCount: 0,
        });
        setRefreshCount((prev) => ++prev);
    }, [quickSearchValue, props.count]);

    React.useEffect(() => {
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [refreshCount]);

    const alertModal = (
        <Modal
            key={1}
            isOpen={licenseAlertDetailsVisibility}
            hideModal={() => setLicenseAlertDetailsVisibility(false)}
            modalTitle={
                licenseAlertDetails.alertName
                    ? t("LicenseAlerts.viewAlert", { alertName: licenseAlertDetails.alertName })
                    : ""
            }
        >
            {failureMessage != "" ? (
                failureMessage
            ) : loading ? (
                <div>
                    <LoadingIndicator />
                </div>
            ) : (
                <ViewLicenseAlert
                    licenseAlertDetails={licenseAlertDetails}
                    handleOkClick={setLicenseAlertDetailsVisibility}
                    showForm={props.showForm}
                    setEditView={props.setEditView}
                    alertDetails={props.alertDetails}
                />
            )}
        </Modal>
    );

    const rowCount =
        tableState.alertsCount <= 0
            ? null
            : t("LicenseAlerts.table.alertCountLabel", {
                  partialCount: tableState.licenseAlerts.length,
                  totalCount: tableState.alertsCount,
              });

    const columns: Column<LicenseAlert>[] = createColumns();
    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{rowCount}</div>
                <div className={classNames(formStyle.search, tableStyle.padding)}>
                    <SearchView setSearch={setQuickSearchValue} searchInProgress={false} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.LICENSE_ALERTS_TABLE}
                    data={tableState.licenseAlerts}
                    columns={columns}
                    loaded={!initialLoading}
                    tooltips={true}
                    scrollTo={0}
                    inDialogBox={false}
                    emptyMessage={t("LicenseAlerts.table.emptyStateMessage")}
                    testId={testIds.workArea.license.licenseAlerts.table.itself}
                />
            </div>
            {tableState.cursor != null &&
                tableState.licenseAlerts.length >= TABLE_PAGE_LIMIT &&
                tableState.licenseAlerts.length != 0 &&
                !requestFailureMessage &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={buttonStyle.loadMoreButtonWithoutIcon}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                label: Label.LICENSE_ALERT,
                                action: Action.LOAD_MORE,
                                category: Category.LICENSE_ALERTS,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            {alertModal}
        </>
    );
};

export default connector(LicenseAlertsTable);
