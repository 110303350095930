import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormValues } from "./CustomReportView";
import { InputField } from "./InputFields";

interface MobiledeviceTemplateProps {
    formikProps: FormikProps<FormValues>;
}

const MobiledeviceTemplate: React.FC<MobiledeviceTemplateProps> = React.memo(({ formikProps }) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = formikProps;

    const inputFields = useMemo(
        () => [
            {
                id: "assetTag",
                label: t("ErasureReport.customReport.templateSpecifications.assetTag"),
                dataPath: "user_data.fields.usercreatedreport.asset_tag",
            },
            {
                id: "comments",
                label: t("ErasureReport.customReport.templateSpecifications.comments"),
                dataPath: "user_data.fields.usercreatedreport.comments",
            },
            {
                id: "handlingProcess",
                label: t("ErasureReport.customReport.templateSpecifications.handlingProcess"),
                dataPath: "user_data.fields.usercreatedreport.handling_process",
            },
            {
                id: "imei",
                label: t("ErasureReport.customReport.templateSpecifications.imei"),
                dataPath: "blancco_hardware_report.system.imei",
            },
            {
                id: "make",
                label: t("ErasureReport.customReport.templateSpecifications.make"),
                dataPath: "user_data.fields.usercreatedreport.make",
            },
            {
                id: "identifier",
                label: t("ErasureReport.customReport.templateSpecifications.identifier"),
                dataPath: "blancco_hardware_report.system.identifier",
            },
            {
                id: "manufacturer",
                label: t("ErasureReport.customReport.templateSpecifications.manufacturer"),
                dataPath: "blancco_hardware_report.system.manufacturer",
            },
            {
                id: "model",
                label: t("ErasureReport.customReport.templateSpecifications.model"),
                dataPath: "blancco_hardware_report.system.model",
            },
            {
                id: "type",
                label: t("ErasureReport.customReport.templateSpecifications.type"),
                dataPath: "user_data.fields.usercreatedreport.type",
            },
        ],
        [values, t]
    );

    return (
        <>
            {inputFields.map((field) => (
                <InputField
                    key={field.id}
                    id={field.id}
                    setFieldValue={setFieldValue}
                    label={field.label}
                    dataPath={field.dataPath}
                />
            ))}
        </>
    );
});

export default MobiledeviceTemplate;
