import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { object, string } from "yup";

import style from "./delivery-history.scss";
import Edit from "components/icons/Edit";
import PreviewIcon from "components/icons/PreviewIcon";
import kebabStyle from "components/kebab-menu/kebab-menu.scss";
import BundleTokenPricingView from "components/licenses/BundleTokenPricingView";
import tokenstyle from "components/licenses/delivery-history/add-license-delivery.scss";
import {
    DESCRIPTION_MAX_LENGTH,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
} from "components/licenses/delivery-history/DeliveryFormContent";
import {
    createProductsMap,
    fetchRateVersionNameFromUuid,
} from "components/licenses/delivery-history/ViewDeliveryHistory";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import StaticTable from "components/support/api-guide/StaticTable";
import DateCell from "components/table/DateCell";
import TextWithTooltip from "components/table/TextWithTooltip";
import { deduceTierLicenses } from "components/tenants/add-tenant/AddTenantForm";
import Tooltip from "components/tooltip/Tooltip";
import {
    extractLatestLicenseRateUuid,
    extractProductRatesList,
    fetchLicenseRateVersion,
    licenseService,
    ProductToRateList,
} from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getTenantName,
    hasSubTenantCookie,
    isLicensingModelBundleWithToken,
    isUserParentInternal,
} from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import { replaceProductIdWithNameInRatesTable } from "store/license";
import { switchTheme } from "store/theme";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyNumbers } from "utils/commonFunctions";

import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
});

interface Result {
    title: string;
    message: string;
}

export interface DeliveryLicenses {
    licenseId: string;
    amount: number;
    expirationDate: string;
}

export interface UserInfo {
    addedBy: string;
    addedDate: string;
}

export interface EditDeliveryForm {
    caseNumber: string;
    opportunityId: string;
    notes: string;
    licenseRateVersion?: string;
}

const connector = connect(mapState, { switchTheme });
const ALL_PRODUCTS = createProductsMap();

const DeliveryHistoryMenuItems = (
    props: {
        deliveryHistoryId: string;
        onDeliveryHistoryEdit: (isSync: boolean) => void;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();

    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [editDeliveryHistoryVisible, setEditDeliveryHistoryVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState(false);
    const [formInitialState, setFormInitialState] = React.useState<EditDeliveryForm>();
    const [userInformation, setUserInformation] = React.useState<UserInfo>();
    const [licenses, setLicenses] = React.useState<DeliveryLicenses[]>();
    const [loadingOnEdit, setLoadingOnEdit] = React.useState(false);
    const modalTitle = t("DeliveryHistory.editLicenseDelivery.title", { customer: getTenantName() });
    const showResult = (resultToShow: Result) => {
        setResultVisible(true);
        setResult(resultToShow);
    };
    const [bundleTokenPricingModalVisible, setBundleTokenPricingModalVisible] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const deducedTierLicenses = deduceTierLicenses(
        props.tenantDetails.tenantTier,
        true,
        props.tenantDetails.type,
        true
    );
    const [tokenRateVersions, setTokenRateVersions] = React.useState<ProductToRateList[]>([]);
    const [rateUuid, setTokenRateUUid] = React.useState<string>("");

    const submitHandler: FormikConfig<EditDeliveryForm>["onSubmit"] = async ({
        caseNumber,
        notes,
        opportunityId,
    }: EditDeliveryForm): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoadingOnEdit(true);
        await licenseService
            .editLicenseDelivery(
                props.deliveryHistoryId,
                {
                    notes: notes,
                    caseNumber: caseNumber,
                    opportunityId: opportunityId,
                },
                abortController,
                props.tenantDetails.region
            )
            .then(() => {
                showResult({
                    title: t("DeliveryHistory.editLicenseDelivery.successTitle"),
                    message: t("DeliveryHistory.editLicenseDelivery.successMessage"),
                });
                setEditDeliveryHistoryVisible(false);
                setLoadingOnEdit(false);
            })
            .catch(() => {
                setEditDeliveryHistoryVisible(false);
                setLoadingOnEdit(false);
                showResult({
                    title: t("DeliveryHistory.editLicenseDelivery.failureTitle"),
                    message: t("DeliveryHistory.editLicenseDelivery.failureMessage"),
                });
                setEditDeliveryHistoryVisible(true);
            })
            .finally(() => {
                setLoadingOnEdit(false);
                setResultVisible(true);
            });
    };

    async function fetchRatesFromApi() {
        const rates = isLicensingModelBundleWithToken() ? await fetchLicenseRateVersion() : [];
        setTokenRateVersions(rates);
        setTokenRateUUid(extractLatestLicenseRateUuid(rates));
    }

    React.useEffect(() => {
        if (isLicensingModelBundleWithToken()) {
            fetchRatesFromApi();
        }
    }, []);

    const fetchDeliveryHistoryDetails = (uuid: string) => {
        setInitialLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const fetchLicenseDeliveries = licenseService.fetchLicenseDeliveryHistory(uuid, abortController);
        const fetchRateVersions = licenseService.fetchTokenRates(abortController);
        Promise.all([fetchLicenseDeliveries, fetchRateVersions])
            .then(([fetchedLicenseDeliveries, fetchedRateVersions]) => {
                const deliveryVersionName = fetchRateVersionNameFromUuid(
                    fetchedLicenseDeliveries.licenseRateVersion,
                    fetchedRateVersions
                );
                const deliveryData: EditDeliveryForm = {
                    caseNumber: fetchedLicenseDeliveries.caseNumber,
                    opportunityId: fetchedLicenseDeliveries.opportunityId,
                    notes: fetchedLicenseDeliveries.notes,
                    licenseRateVersion: deliveryVersionName,
                };
                setTokenRateVersions(fetchedRateVersions);
                setFormInitialState(deliveryData);
                setInitialLoading(fetchedLicenseDeliveries.type == "");
                setLicenses(fetchedLicenseDeliveries.licenses);
                setUserInformation({
                    addedBy: fetchedLicenseDeliveries.creatorUuid,
                    addedDate: fetchedLicenseDeliveries.creationDate,
                });
            })
            .catch(() => {
                setInitialLoading(false);
                showResult({
                    title: modalTitle,
                    message: t("DeliveryHistory.deliveryHistoryDialog.failedToLoadLicenseDetails"),
                });
            });
    };

    const hideResultDialog = () => {
        setResultVisible(false);
        props.onDeliveryHistoryEdit(false);
    };
    React.useEffect(() => {
        return () => abortControllers.filter((a) => !a.signal.aborted).forEach((a) => a.abort());
    }, []);
    return (
        <div className={style.deliveryHistoryEditIcon}>
            <Menu className={kebabStyle.kebabMenu}>
                <ul>
                    <li>
                        <TextWithTooltip text={modalTitle}>
                            <MenuItemButton
                                onClick={() => {
                                    fetchDeliveryHistoryDetails(props.deliveryHistoryId);
                                    setEditDeliveryHistoryVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE,
                                        action: Action.EDIT_LICENSE_DELIVERY,
                                    });
                                }}
                                data-testid={testIds.workArea.license.deliveryHistory.table.kebabMenu.editButton}
                            >
                                <Edit color={props.theme.iconFillColor} />
                            </MenuItemButton>
                        </TextWithTooltip>
                    </li>
                </ul>
            </Menu>

            <Modal
                isOpen={editDeliveryHistoryVisible}
                hideModal={() => setEditDeliveryHistoryVisible(false)}
                modalTitle={modalTitle}
            >
                <div>
                    {formInitialState !== undefined && !initialLoading && !loadingOnEdit ? (
                        <Formik
                            initialValues={{
                                caseNumber: isUserParentInternal() ? formInitialState.caseNumber : "",
                                opportunityId: isUserParentInternal() ? formInitialState.opportunityId : "",
                                notes: formInitialState.notes,
                                licenseRateVersion: formInitialState.licenseRateVersion,
                            }}
                            onSubmit={submitHandler}
                            validationSchema={object().shape({
                                caseNumber: isUserParentInternal()
                                    ? string()
                                          .required(t("DeliveryHistory.addLicenseDelivery.validation.caseNumber"))
                                          .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                                          .max(
                                              MAX_CASE_NUMBER_LENGTH,
                                              t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                                  length: MAX_CASE_NUMBER_LENGTH,
                                              })
                                          )
                                          .test(
                                              "Has only numbers",
                                              t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"),
                                              (value) => hasOnlyNumbers(value)
                                          )
                                    : string(),
                                opportunityId: isUserParentInternal()
                                    ? string()
                                          .max(
                                              MAX_OPPORTUNITY_ID_LENGTH,
                                              t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                                                  length: MAX_OPPORTUNITY_ID_LENGTH,
                                              })
                                          )
                                          .required(t("DeliveryHistory.addLicenseDelivery.validation.opportunityId"))
                                    : string(),
                            })}
                            validateOnChange={true}
                        >
                            {({ values, errors, handleChange }: FormikProps<EditDeliveryForm>) => {
                                return (
                                    <Form>
                                        {isUserParentInternal() ? (
                                            <>
                                                <div className={form.formFields}>
                                                    {hasSubTenantCookie() ? (
                                                        <span className={form.optional}>{t("Common.optional")}</span>
                                                    ) : null}
                                                    <label
                                                        htmlFor="opportunityId"
                                                        className={classNames(form.label, style.wideWidth, {
                                                            [form.inputError]: errors.opportunityId,
                                                        })}
                                                    >
                                                        {t("DeliveryHistory.editLicenseDelivery.opportunityId")}
                                                    </label>
                                                    <input
                                                        id="opportunityId"
                                                        className={classNames(form.input, form.fixedWidthInput, {
                                                            [form.inputError]: errors.opportunityId,
                                                        })}
                                                        onChange={handleChange}
                                                        value={values.opportunityId}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory.editDeliveryDialog
                                                                .opportunityIdInput.itself
                                                        }
                                                    />

                                                    <div
                                                        className={form.error}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory.editDeliveryDialog
                                                                .opportunityIdInput.errorLabel
                                                        }
                                                    >
                                                        <ErrorMessage name="opportunityId" />
                                                    </div>
                                                </div>

                                                <div className={form.formFields}>
                                                    {hasSubTenantCookie() ? (
                                                        <span className={form.optional}>{t("Common.optional")}</span>
                                                    ) : null}
                                                    <label
                                                        htmlFor="caseNumber"
                                                        className={classNames(form.label, style.wideWidth, {
                                                            [form.inputError]: errors.caseNumber,
                                                        })}
                                                    >
                                                        {t("DeliveryHistory.editLicenseDelivery.caseNumber")}
                                                    </label>

                                                    <input
                                                        id="caseNumber"
                                                        className={classNames(form.input, form.fixedWidthInput, {
                                                            [form.inputError]: errors.caseNumber,
                                                        })}
                                                        onChange={handleChange}
                                                        value={values.caseNumber}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory.editDeliveryDialog
                                                                .caseNumberInput.itself
                                                        }
                                                    />
                                                    <div
                                                        className={form.error}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory.editDeliveryDialog
                                                                .caseNumberInput.errorLabel
                                                        }
                                                    >
                                                        <ErrorMessage name="caseNumber" />
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}

                                        <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                            <div className={form.formFieldsAlignItemsTop}>
                                                <span className={form.optional}>{t("Common.optional")}</span>
                                                <label
                                                    htmlFor="notes"
                                                    className={classNames(form.label, style.wideWidth)}
                                                >
                                                    {t("DeliveryHistory.editLicenseDelivery.notes")}
                                                </label>
                                            </div>
                                            <div className={classNames(style.gridRows, form.optional)}>
                                                <textarea
                                                    id="notes"
                                                    className={classNames(
                                                        form.input,
                                                        form.fixedWidthInput,
                                                        form.textAreaHeight
                                                    )}
                                                    onChange={handleChange}
                                                    data-testid={
                                                        testIds.workArea.license.deliveryHistory.editDeliveryDialog
                                                            .notesTextArea.itself
                                                    }
                                                    maxLength={DESCRIPTION_MAX_LENGTH}
                                                    value={values.notes}
                                                />
                                                <span className={form.notes}>
                                                    {t("DeliveryHistory.addLicenseDelivery.validation.notes", {
                                                        remainingCharacters: (
                                                            DESCRIPTION_MAX_LENGTH -
                                                            (values.notes ? values.notes.length : 0)
                                                        ).toString(),
                                                        maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                                                    })}
                                                </span>
                                            </div>
                                            <Tooltip content={t("DeliveryHistory.addLicenseDelivery.warning")}>
                                                <div className={style.warningIcon} tabIndex={0}>
                                                    <img src={alert} alt={t("AltText.alertIcon")} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        {isUserParentInternal() && isLicensingModelBundleWithToken() && (
                                            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                                <div className={form.formFieldsAlignItemsTop}>
                                                    <label
                                                        htmlFor="licenseRateVersion"
                                                        className={classNames(form.label, style.wideWidth)}
                                                    >
                                                        {t("DeliveryHistory.editLicenseDelivery.licenseRateVersion")}
                                                    </label>
                                                </div>
                                                <div className={classNames(style.gridRows, form.optional)}>
                                                    <div className={classNames(style.gridRows, form.optional)}>
                                                        {values.licenseRateVersion}
                                                    </div>
                                                </div>
                                                <div>
                                                    <button
                                                        className={classNames(
                                                            tokenstyle.tooltipPosition,
                                                            buttons.textButton
                                                        )}
                                                        type="button"
                                                        onClick={() => {
                                                            setBundleTokenPricingModalVisible(true);
                                                        }}
                                                    >
                                                        <div>
                                                            <PreviewIcon color={theme.linkTextColor} />
                                                            <span className={tokenstyle.addMoreButton}>
                                                                {t(
                                                                    "DeliveryHistory.addLicenseDelivery.previewRateTable"
                                                                )}
                                                            </span>
                                                        </div>
                                                    </button>
                                                </div>
                                                <BundleTokenPricingView
                                                    visibility={bundleTokenPricingModalVisible}
                                                    setVisibility={setBundleTokenPricingModalVisible}
                                                    productToRate={replaceProductIdWithNameInRatesTable(
                                                        extractProductRatesList(tokenRateVersions, rateUuid),
                                                        deducedTierLicenses
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {userInformation && (
                                            <>
                                                <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                                    <div className={form.formFieldsAlignItemsTop}>
                                                        <label
                                                            htmlFor="addedBy"
                                                            className={classNames(form.label, style.wideWidth)}
                                                        >
                                                            {t("DeliveryHistory.editLicenseDelivery.creatorUuid")}
                                                        </label>
                                                    </div>
                                                    <div className={classNames(style.gridRows, form.optional)}>
                                                        {userInformation.addedBy}
                                                    </div>
                                                </div>
                                                <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                                    <div className={form.formFieldsAlignItemsTop}>
                                                        <label
                                                            htmlFor="addedDate"
                                                            className={classNames(form.label, style.wideWidth)}
                                                        >
                                                            {t("DeliveryHistory.editLicenseDelivery.creationDate")}
                                                        </label>
                                                    </div>
                                                    <div className={classNames(style.gridRows, form.optional)}>
                                                        <DateCell
                                                            tooltip={false}
                                                            value={userInformation.addedDate}
                                                            withoutTime={true}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {licenses !== undefined && (
                                            <StaticTable
                                                headers={[
                                                    {
                                                        value: t(
                                                            "DeliveryHistory.editLicenseDelivery.table.licenseType"
                                                        ),
                                                    },
                                                    {
                                                        value: t("DeliveryHistory.editLicenseDelivery.table.amount"),
                                                    },
                                                    {
                                                        value: t(
                                                            "DeliveryHistory.editLicenseDelivery.table.expirationDate"
                                                        ),
                                                    },
                                                ]}
                                                cells={licenses.map((license, key) => {
                                                    return [
                                                        <div
                                                            data-testid={
                                                                testIds.workArea.license.deliveryHistory
                                                                    .editDeliveryDialog.table.licenseTypeLabel
                                                            }
                                                            key={license.licenseId}
                                                        >
                                                            {ALL_PRODUCTS.get(license.licenseId)}
                                                        </div>,
                                                        <div
                                                            data-testid={
                                                                testIds.workArea.license.deliveryHistory
                                                                    .editDeliveryDialog.table.totalOfLicensesLabel
                                                            }
                                                            key={"amount" + key}
                                                        >
                                                            {license.amount}
                                                        </div>,
                                                        <div
                                                            data-testid={
                                                                testIds.workArea.license.deliveryHistory
                                                                    .editDeliveryDialog.table.expirationDateLabel
                                                            }
                                                            key={"date" + key}
                                                        >
                                                            <DateCell tooltip={false} value={license.expirationDate} />
                                                        </div>,
                                                    ];
                                                })}
                                                testId={
                                                    testIds.workArea.license.deliveryHistory.editDeliveryDialog.table
                                                        .itself
                                                }
                                            />
                                        )}

                                        <div className={form.buttonContainer}>
                                            <button
                                                type="submit"
                                                className={classNames(
                                                    buttons.primaryButtonWithoutIcon,
                                                    form.submitButton
                                                )}
                                            >
                                                {t("Common.save")}
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    ) : (
                        <div>
                            <LoadingIndicator />
                        </div>
                    )}
                </div>
            </Modal>

            <Modal isOpen={resultVisible} hideModal={hideResultDialog} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={form.okButtonContainer}>
                    <button className={buttons.primaryOkButton} onClick={hideResultDialog}>
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default connector(DeliveryHistoryMenuItems);
