import * as React from "react";
import { useTranslation } from "react-i18next";

import loginStyle from "./login.scss";
import Modal from "components/modal/Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import buttonsStyle from "styles/buttons.scss";

/**
 * Create initial context passed to ErrorModal.
 */

interface Props {
    hide: () => void;
    visible: boolean;
    title: string;
    description: string;
}

export function ErrorModal(props: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <Modal isOpen={props.visible} hideModal={props.hide} modalTitle={t(props.title)}>
                <div className={loginStyle.loginModalContainer}>
                    <TextBlock>{t(props.description)}</TextBlock>
                    <div className={loginStyle.loginModalSubmitButton}>
                        <button
                            type="button"
                            className={buttonsStyle.primaryOkButton}
                            onClick={() => {
                                props.hide();
                            }}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
