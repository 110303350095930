import { useFeature } from "flagged";
import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import SubWorkflowList from "./SubWorkflowList";
import style from "./workflow-kebab-menu.scss";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import Delete from "components/icons/Delete";
import Download from "components/icons/Download";
import SetDefault from "components/icons/SetDefault";
import { TitleIcon } from "components/icons/TitleIcon";
import Update from "components/icons/Update";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import ManageWorkflowWrapper from "components/workflows/manage-workflow-dialog/ManageWorkflowWrapper";
import { AUTH_WORKFLOW_DEFAULT, AUTH_WORKFLOW_DELETE } from "domain/authority";
import { ConnectedWorkflows } from "domain/workflows";
import { FLAG_CONNECTED_WORKFLOWS } from "services/feature/FeatureFlagService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import {
    EditorGeneration,
    Profile,
    profileToNameMap,
    workflowService,
    WorkflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    visible: boolean;
}

interface SetDefaultResult extends Result {
    loading: boolean;
    previousDefault?: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const WorkflowKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        version?: string;
        uuid: string;
        product: string;
        name: string;
        own: boolean;
        defaultWorkflow: boolean;
        editorGeneration: EditorGeneration;
        connectedWorkflows: ConnectedWorkflows;
        onUpdate: () => void;
        workflowService: WorkflowService;
        updateVisible: boolean;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const showConnectedWorkflows = useFeature(FLAG_CONNECTED_WORKFLOWS);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", visible: false });
    const hideResult = () => {
        setResult({ title: result.title, message: result.message, visible: false });
        props.onUpdate();
    };
    const [setDefaultResult, setSetDefaultResult] = React.useState<SetDefaultResult>({
        title: "",
        message: "",
        visible: false,
        loading: true,
        previousDefault: undefined,
    });
    const hideSetDefaultResult = () => {
        setSetDefaultResult({
            title: setDefaultResult.title,
            message: setDefaultResult.message,
            visible: false,
            loading: false,
            previousDefault: setDefaultResult.previousDefault,
        });
        props.onUpdate();
    };
    const [deleteWorkflowModalVisible, setDeleteWorkflowModalVisible] = React.useState(false);
    const [okClicked, setOkClicked] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [editWorkflowVisible, setEditWorkflowVisible] = React.useState(false);
    const [editWorkflowEditorVisible, setEditWorkflowEditorVisible] = React.useState(false);
    const [deleteWarningDialog, setDeleteWarningDialog] = React.useState(false);
    const [updateWorkflowVisible, setUpdateWorkflowVisible] = React.useState(false);

    const exportWorkflow = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.workflowService.export(props.uuid, abortController);
    };

    const deleteWorkflow = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.workflowService
            .deleteWorkflow(props.uuid, abortController)
            .then(() => {
                setDeleteWorkflowModalVisible(false);
                setResult({
                    title: t("DeleteWorkflow.workflowDeleted.workflowDeletedTitle"),
                    message: t("DeleteWorkflow.workflowDeleted.successMessage", { name: props.name }),
                    visible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setOkClicked(false);
                    setDeleteWorkflowModalVisible(false);
                    setResult({
                        title: t("DeleteWorkflow.workflowDeleted.workflowNotDeletedTitle"),
                        message: t("DeleteWorkflow.workflowDeleted.failureMessage"),
                        visible: true,
                    });
                }
            });
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const setDefault = (workflowUuid: string, showConfirmation: boolean) => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW,
            action: Action.SET_DEFAULT_WORKFLOW,
        });

        const abortController = new AbortController();
        abortControllers.push(abortController);
        setSetDefaultResult({
            title: t("Common.pleaseWait"),
            message: setDefaultResult.message,
            visible: true,
            loading: true,
            previousDefault: undefined,
        });
        props.workflowService
            .setDefault(workflowUuid, abortController)
            .then((response) => {
                if (showConfirmation) {
                    let message = t("SetDefaultWorkflow.successMessage", {
                        newDefault: response.newDefaultName,
                        product: profileToNameMap.get(response.profile.toLowerCase() as Profile),
                    });
                    if (response.oldDefaultUuid != null) {
                        message +=
                            " " +
                            t("SetDefaultWorkflow.successMessageWithOldDefault", {
                                oldDefault: response.oldDefaultName,
                            });
                    }
                    setSetDefaultResult({
                        title: t("SetDefaultWorkflow.successTitle"),
                        message: message,
                        visible: true,
                        loading: false,
                        previousDefault: response.oldDefaultUuid,
                    });
                } else {
                    setSetDefaultResult({
                        title: t("Common.pleaseWait"),
                        message: setDefaultResult.message,
                        visible: false,
                        loading: true,
                        previousDefault: undefined,
                    });
                    props.onUpdate();
                }
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("SetDefaultWorkflow.failureTitle"),
                        message: t("SetDefaultWorkflow.failureMessage"),
                        visible: true,
                    });
                }
            });
    };

    const undoSetDefault = () => {
        const previousDefaultUuid = setDefaultResult.previousDefault;
        if (previousDefaultUuid != undefined) {
            setDefault(previousDefaultUuid, false);
        }
    };

    const handleWorkflowDelete = () => {
        setOkClicked(true);
        deleteWorkflow();
    };

    const handleWorkflowUpdate = () => {
        setUpdateWorkflowVisible(true);
    };

    return (
        <div className={style.workflowsIcon}>
            <Menu className={style.kebabMenu} data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                <ul>
                    {props.updateVisible && (
                        <li>
                            <TextWithTooltip text={t("Common.update")}>
                                <MenuItem
                                    tag="button"
                                    onKeyDown={undefined}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.WORKFLOW,
                                            action: Action.UPDATE_WORKFLOW_INITIATED,
                                        });
                                        handleWorkflowUpdate();
                                    }}
                                    data-testid={testIds.workArea.workflows.table.kebabMenu.updateButton}
                                >
                                    <Update color={props.theme.iconFillColor} />
                                </MenuItem>
                            </TextWithTooltip>
                        </li>
                    )}
                    <li>
                        <TextWithTooltip text={t("WorkflowsTable.tooltip.export")}>
                            <MenuItem
                                tag="button"
                                onKeyDown={undefined}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.WORKFLOW,
                                        action: Action.EXPORT_WORKFLOW,
                                    });
                                    exportWorkflow();
                                }}
                                data-testid={testIds.workArea.workflows.table.kebabMenu.exportButton}
                            >
                                <Download color={props.theme.iconFillColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                    {!hasTenantCookie() &&
                        !props.defaultWorkflow &&
                        userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_DEFAULT]) && (
                            <li>
                                <TextWithTooltip text={t("WorkflowsTable.tooltip.setDefault")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            setDefault(props.uuid, true);
                                        }}
                                        data-testid={testIds.workArea.workflows.table.kebabMenu.setDefaultButton}
                                    >
                                        <SetDefault color={props.theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_DELETE]) && (
                        <li>
                            <TextWithTooltip
                                text={t("DeleteWorkflow.tooltipDelete", {
                                    customerName: props.userDetail?.tenantName,
                                })}
                            >
                                <MenuItem
                                    tag="button"
                                    onKeyDown={undefined}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.WORKFLOW,
                                            action: Action.REMOVE_WORKFLOW,
                                        });
                                        showConnectedWorkflows && props.connectedWorkflows.parentWorkflows.length
                                            ? setDeleteWarningDialog(true)
                                            : setDeleteWorkflowModalVisible(true);
                                    }}
                                    data-testid={testIds.workArea.workflows.table.kebabMenu.deleteButton}
                                >
                                    <Delete color={props.theme.iconFillColor} />
                                </MenuItem>
                            </TextWithTooltip>
                        </li>
                    )}
                </ul>
            </Menu>
            <DeleteConfirmationDialog
                modalDisplayed={deleteWorkflowModalVisible}
                introductionMessage={t("DeleteWorkflow.introductionMessage", {
                    customerName: props.userDetail?.tenantName,
                    name: props.name,
                })}
                confirmationMessage={t("DeleteWorkflow.confirmationMessage")}
                handleCommand={handleWorkflowDelete}
                setModalDisplayed={() => setDeleteWorkflowModalVisible(false)}
                title={t("DeleteWorkflow.title")}
                loading={okClicked}
            />
            <Modal
                isOpen={result.visible}
                hideModal={hideResult}
                modalTitle={result.title}
                titleIcon={<TitleIcon iconType={"SUCCESS"} />}
            >
                <div className={style.resultContainer}>
                    <strong>{props.name}</strong> {result.message}
                </div>
                <div className={style.okButtonContainer}>
                    <button
                        className={buttons.primaryOkButton}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <ManageWorkflowWrapper
                modalTitle={"ManageWorkflowDialog.title.manage"}
                modalVisibility={editWorkflowEditorVisible}
                onUpdate={props.onUpdate}
                workflowService={props.workflowService}
                workflowUuid={props.uuid}
                onShowModal={setEditWorkflowEditorVisible}
                editorGeneration={props.editorGeneration}
                isEdit={true}
                product={props.product}
                version={props.version}
                name={props.name}
                parentWorkflowsCount={props.connectedWorkflows?.parentWorkflows.length}
                entityType={"WORKFLOW"}
            />
            <Modal
                isOpen={setDefaultResult.visible}
                hideModal={() => hideSetDefaultResult()}
                modalTitle={setDefaultResult.title}
                titleIcon={<TitleIcon iconType={"SUCCESS"} />}
            >
                {setDefaultResult.loading && <LoadingIndicator />}
                {!setDefaultResult.loading && (
                    <>
                        <div className={style.resultContainer}>{setDefaultResult.message}</div>
                        <div className={style.buttonContainer}>
                            {setDefaultResult.previousDefault != null && (
                                <button
                                    className={buttons.secondaryButtonWithoutIcon}
                                    onClick={undoSetDefault}
                                    data-testid={testIds.common.confirmationDialog.undoButton}
                                >
                                    {t("Common.undo")}
                                </button>
                            )}
                            <button
                                className={buttons.okOptionalButtonPrimary}
                                onClick={hideSetDefaultResult}
                                data-testid={testIds.common.confirmationDialog.confirmButton}
                            >
                                {t("Common.ok")}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
            <SubWorkflowList
                visible={editWorkflowVisible}
                hide={setEditWorkflowVisible}
                openManageDialogVisible={setEditWorkflowEditorVisible}
                connectedWorkflows={props.connectedWorkflows}
            />
            <Modal
                isOpen={deleteWarningDialog}
                hideModal={() => {
                    setDeleteWarningDialog(false);
                }}
                modalTitle={t("ManageWorkflowDialog.deleteWorkflowWarningDialog.title")}
                titleIcon={<TitleIcon iconType={"WARNING"} />}
            >
                <>
                    <div className={style.editMessage}>
                        {t("ManageWorkflowDialog.deleteWorkflowWarningDialog.message")}:
                    </div>
                    <div className={style.listBox}>
                        <ul>
                            {props.connectedWorkflows.parentWorkflows.map((each) => (
                                <li key={each}>{each}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={buttons.primaryOkButton}
                            onClick={() => {
                                setDeleteWarningDialog(false);
                            }}
                            data-testid={testIds.common.confirmationDialog.confirmButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </>
            </Modal>
            <ManageWorkflowWrapper
                modalTitle={"ManageWorkflowDialog.updateWorkflowDialog.title"}
                modalVisibility={updateWorkflowVisible}
                onUpdate={() => {
                    props.onUpdate();
                }}
                workflowService={workflowService}
                onShowModal={() => {
                    setUpdateWorkflowVisible(false);
                }}
                product={props.product}
                name={props.name}
                updateWorkflowUuid={props.uuid}
                version={props.version}
                entityType={"WORKFLOW"}
            />
        </div>
    );
};

export default connector(WorkflowKebabMenu);
