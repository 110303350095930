import React from "react";
import { isIOS, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import style from "./profile.scss";
import Device from "components/icons/Device";
import IosSettingsIcon from "components/icons/IosSettingsIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { AssetData, URLParameters } from "domain/iosProfile";
import { ApiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { iosProfilePageService } from "services/ios-profile-page/ProfilePageService";
import buttons from "styles/buttons.scss";

import alertIcon from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

const ProfilePage = (): JSX.Element => {
    const { t } = useTranslation();
    const { journeyId } = useParams<URLParameters>();
    const [profileDetails, setProfileDetails] = React.useState(false);
    const [asset, setAsset] = React.useState<AssetData>();
    const [loading, setLoading] = React.useState(true);
    const query = new URLSearchParams(useLocation().search);
    const region = query.get("region");

    const downloadProfileUrl = ApiGatewayService.getApiUrl(
        `/api/asset/journey/${journeyId}/download-profile`,
        ApiType.MAINSTREAM
    ).replace("eu-west-1", region || "eu-west-1");

    const fetchAssetData = () => {
        const abortController = new AbortController();
        iosProfilePageService
            .fetchAssetData(abortController, journeyId, region || "eu-west-1")
            .then((data) => {
                setAsset(data);
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    console.error("Asset details not found");
                    setLoading(false);
                }
            });
    };

    const handleClick = () => {
        setProfileDetails(true);
    };

    const primaryColor = asset?.primary_color ? asset?.primary_color : "";

    React.useEffect(() => {
        fetchAssetData();
    }, []);

    if (loading) {
        return <LoadingIndicator />;
    }
    return !journeyId ? (
        <div className={style.error} data-testid={testIds.workArea.iosProfilePage.deviceErrorLabel}>
            <img src={alertIcon} />
            <div>{t("IosProfilePage.errorMessages.journeyIdMissing")}</div>
        </div>
    ) : !isIOS || isTablet ? (
        <div className={style.error} data-testid={testIds.workArea.iosProfilePage.deviceErrorLabel}>
            <img src={alertIcon} />
            <div>{t("IosProfilePage.errorMessages.iosDevicesOnly")}</div>
        </div>
    ) : profileDetails ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileDetailsTitle")}</div>
                <div className={style.message}>
                    {t("IosProfilePage.iosProfileDetailsStatus")}
                    <strong>{"'Profile Downloaded'. "}</strong>
                    {t("IosProfilePage.iosProfileDetailsMessage")}
                </div>
                <div className={style.installMessage} style={{ color: primaryColor }}>
                    {t("IosProfilePage.iosProfileStepsTitle")}
                </div>
                <div className={style.steps}>
                    <ol>
                        <li>
                            {<IosSettingsIcon />}
                            {t("IosProfilePage.step1")}
                            <strong>{t("IosProfilePage.settingsText")}</strong>
                            {t("IosProfilePage.goToText")}
                            <strong>{t("IosProfilePage.profileDownloadedText")}</strong>
                        </li>
                        <li>
                            {t("IosProfilePage.step2")}
                            <strong>{t("IosProfilePage.installText")}</strong>
                            <em>{t("IosProfilePage.installButtonPositionText")}</em>
                        </li>
                        <li>{t("IosProfilePage.step3")}</li>
                    </ol>
                </div>
                <div className={style.note}>{t("IosProfilePage.profileNote")}</div>
            </div>
        </div>
    ) : (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <strong>{t("IosProfilePage.iosProfileWelcomeMessage")}</strong>
                </div>
                <div className={style.message}>
                    <Device />
                </div>
                <div className={style.buttonContainer}>
                    <a href={downloadProfileUrl}>
                        <button
                            className={buttons.primaryButtonWithoutIcon}
                            style={{
                                backgroundColor: primaryColor,
                                borderColor: primaryColor,
                            }}
                            onClick={handleClick}
                            data-testid={testIds.workArea.iosProfilePage.continueButton}
                        >
                            {t("IosProfilePage.continueButton")}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
