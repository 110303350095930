import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CapacityField } from "./CapacityField";
import { FormValues } from "./CustomReportView";
import { InputField } from "./InputFields";

interface HddTemplateProps {
    formikProps: FormikProps<FormValues>;
}

const HddTemplate = ({ formikProps }: HddTemplateProps): JSX.Element => {
    const { t } = useTranslation();
    const { values, setFieldValue } = formikProps;

    const inputFields3 = useMemo(
        () => [
            {
                id: "vendor",
                label: t("ErasureReport.customReport.templateSpecifications.vendor"),
                dataPath: "blancco_hardware_report.disks.disk.vendor",
            },
            {
                id: "model",
                label: t("ErasureReport.customReport.templateSpecifications.model"),
                dataPath: "blancco_hardware_report.disks.disk.model",
            },
            {
                id: "serialNumber",
                label: t("ErasureReport.customReport.templateSpecifications.serialNumber"),
                dataPath: "blancco_hardware_report.disks.disk.serial",
            },
        ],
        [values, t]
    );

    return (
        <>
            <CapacityField
                key="capacity"
                id="capacity"
                dataPath="blancco_hardware_report.disks.disk.capacity"
                setFieldValue={setFieldValue}
                label={t("ErasureReport.customReport.templateSpecifications.capacity")}
            />
            {inputFields3.map((field) => (
                <InputField
                    key={field.id}
                    id={field.id}
                    setFieldValue={setFieldValue}
                    label={field.label}
                    dataPath={field.dataPath}
                />
            ))}
        </>
    );
};

export default HddTemplate;
