import React from "react";
import { useTranslation } from "react-i18next";

import style from "./workflow-kebab-menu.scss";
import Modal from "components/modal/Modal";
import { ConnectedWorkflows } from "domain/workflows";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    visible: boolean;
    hide: (visible: boolean) => void;
    openManageDialogVisible: (visible: boolean) => void;
    connectedWorkflows: ConnectedWorkflows | undefined;
}

export default function SubWorkflowList(props: Props) {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                isOpen={props.visible}
                hideModal={() => {
                    props.hide(false);
                }}
                modalTitle={t("ManageWorkflowDialog.title.edit")}
            >
                <div className={style.editMessage}>{t("ManageWorkflowDialog.editWorkflowMessage")}</div>
                <div className={style.listBox}>
                    <ul>
                        {props.connectedWorkflows?.parentWorkflows.map((each) => (
                            <li key={each}>{each}</li>
                        ))}
                    </ul>
                </div>
                <div className={style.buttonContainer}>
                    <button
                        className={buttons.primaryButtonWithoutIcon}
                        onClick={() => {
                            props.hide(false);
                            props.openManageDialogVisible(true);
                        }}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.edit")}
                    </button>
                    <button
                        className={buttons.okOptionalButtonSecondary}
                        onClick={() => {
                            props.hide(false);
                        }}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
}
