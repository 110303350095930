import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { string } from "yup";

import loginStyle from "./login.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { EMAIL_MAX_LENGTH } from "domain/globalConstants";
import { authenticationService } from "services/security/AuthenticationService";
import buttonsStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";

import testIds from "testIds.json";

const EMAIL_VALIDATOR = string().required().email().max(EMAIL_MAX_LENGTH);

export interface EmailContext {
    // Email address that the user has typed.
    address: string;
    // Whether address is a valid email address.
    valid: boolean;
    // Whether error should be shown to the user.
    error: boolean;
}

interface Confirmation {
    visible: boolean;
    done: boolean;
    success: boolean;
}

/**
 * Create initial context passed to ForgotPasswordModal.
 */
function createDefaultEmailContext(): EmailContext {
    return { address: "", valid: false, error: false };
}

interface Props {
    hide: () => void;
    visible: boolean;
}

export function ForgotPasswordModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [emailContext, setEmailContext] = React.useState<EmailContext>(createDefaultEmailContext());
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const trimmed = email.trim();
        const shortEnough = trimmed.length <= 255;
        let valid = shortEnough;
        try {
            EMAIL_VALIDATOR.validateSync(trimmed);
        } catch (error) {
            valid = false;
        }
        if (shortEnough) {
            setEmailContext({
                address: email,
                valid,
                error: valid || email.length === 0 ? false : emailContext.error,
            });
        }
    };
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (emailContext.address !== "" && !emailContext.valid) {
                setEmailContext({
                    address: emailContext.address,
                    valid: emailContext.valid,
                    error: true,
                });
            }
        }, 700);
        return () => clearTimeout(timer);
    }, [emailContext.address]);

    const initialConfirmation = { visible: false, done: false, success: false };
    const [confirmation, setConfirmation] = React.useState<Confirmation>(initialConfirmation);
    const hideConfirmation = () => setConfirmation(initialConfirmation);

    const onSendResetClick = () => {
        props.hide();
        function configure(done: boolean, success: boolean) {
            setConfirmation({
                visible: true,
                done,
                success,
            });
        }
        configure(false, false);
        authenticationService
            .requestPasswordReset(emailContext.address)
            .then(() => {
                configure(true, true);
                setEmailContext(createDefaultEmailContext());
            })
            .catch(() => {
                configure(true, false);
            });
    };

    const validationError = !emailContext.error ? (
        <div className={formStyle.blankError} data-testid={testIds.login.forgotPassword.emailInput.errorLabel}></div>
    ) : (
        <div className={formStyle.error} data-testid={testIds.login.forgotPassword.emailInput.errorLabel}>
            {t("Common.invalidEmail")}
        </div>
    );
    const confirmationText = t(
        confirmation.success
            ? "Login.forgotPasswordConfirmationModal.successMessage"
            : "Login.forgotPasswordConfirmationModal.failureMessage"
    );
    const confirmationContent = !confirmation.done ? (
        <LoadingIndicator />
    ) : (
        <div className={loginStyle.loginModalContainer}>
            <div className={classNames({ [loginStyle.errorText]: !confirmation.success })}>{confirmationText}</div>
            <div className={loginStyle.loginModalSubmitButton}>
                <button
                    onClick={hideConfirmation}
                    className={buttonsStyle.primaryOkButton}
                    data-testid={testIds.common.dialog.closeButton}
                >
                    {t("Common.ok")}
                </button>
            </div>
        </div>
    );
    return (
        <>
            <Modal isOpen={props.visible} hideModal={props.hide} modalTitle={t("Login.forgotPasswordModal.title")}>
                <div className={loginStyle.loginModalContainer}>
                    <TextBlock>{t("Login.forgotPasswordModal.description")}</TextBlock>
                    <div className={classNames({ [loginStyle.inputError]: emailContext.error })}>
                        <input
                            type="email"
                            className={loginStyle.enterEmail}
                            onChange={onChange}
                            value={emailContext.address}
                            max={EMAIL_MAX_LENGTH}
                            autoFocus
                            data-testid={testIds.login.forgotPassword.emailInput.itself}
                        />
                        {validationError}
                    </div>
                    <div className={loginStyle.loginModalSubmitButton}>
                        <button
                            type="submit"
                            className={buttonsStyle.primaryButtonWithoutIcon}
                            onClick={onSendResetClick}
                            disabled={!emailContext.valid}
                            data-testid={testIds.login.forgotPassword.sendResetButton}
                        >
                            {t("Login.forgotPasswordModal.submitButton")}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={confirmation.visible}
                hideModal={hideConfirmation}
                modalTitle={t("Login.forgotPasswordModal.title")}
            >
                {confirmationContent}
            </Modal>
        </>
    );
}
