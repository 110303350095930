import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Info from "components/icons/Info";
import dialogStyle from "components/reports/export/export-report-dialog.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";
import form from "styles/form.scss";

interface Props {
    id: string;
    separatorLabel: string;
    separator: string;
    csvSeparatorValue?: string;
    dataTestId: string;
    errorLabelDataTestId: string;
    updateSeparator: (value: string) => void;
    separatorData: string;
    errorMessage?: string;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const SeparatorField = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const separatorList = props.separatorData;

    function handleSeparatorChange(event: { target: { value: string } }, separatorSetter: (value: string) => void) {
        const value = event.target.value;
        if (value != "") {
            if (value.split("").every((char) => separatorList.includes(char))) {
                separatorSetter(value);
            }
        } else {
            separatorSetter("");
            {
                props.errorMessage = t("ErasureReport.fileName.errorMessage");
            }
        }
    }

    return (
        <>
            {
                <div className={dialogStyle.separatorDiv}>
                    <label className={dialogStyle.exportFilenameLabal}>{props.separatorLabel}</label>
                    <input
                        className={classNames(
                            dialogStyle.separatorInput,
                            props.separator === "" || props.separator === props.csvSeparatorValue
                                ? dialogStyle.error
                                : ""
                        )}
                        id={props.id}
                        type="input"
                        value={props.separator}
                        name={props.id}
                        onChange={(event) => {
                            handleSeparatorChange(event, props.updateSeparator);
                        }}
                        maxLength={1}
                        data-testid={props.dataTestId}
                    />
                    <Tooltip
                        content={<>{t("ErasureReport.fileName.typeOfSeparator", { separatorList })}</>}
                        placement={"top"}
                    >
                        <div className={form.infoIcon} tabIndex={0}>
                            <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                        </div>
                    </Tooltip>
                    {props.errorMessage && (
                        <div data-testid={props.errorLabelDataTestId}>
                            <label className={dialogStyle.errorMessage}>{props.errorMessage}</label>
                        </div>
                    )}
                </div>
            }
        </>
    );
};
export default connector(SeparatorField);
