import { EditorGeneration, Profile, SaveSuccessResponse, SaveSuccessResponseDto } from "./WorkflowService";
import { ConnectedWorkflowsDto, Workflow } from "domain/workflows";
import { TemplateTableData } from "domain/workflowTemplates";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export enum PublishStatus {
    PUBLISHED = "Published",
    UNPUBLISHED = "Unpublished",
}

export enum TemplateType {
    READY_TO_USE = "Ready to use",
    MODULAR = "Modular",
}

const PATH_API_TEMPLATES_V1 = "/api/v1/workflow-templates";

export interface WorkflowTemplatesDto {
    name: string;
    uuid: string;
    profile: string;
    created_date: string;
    user_uuid: string;
    user_name: string;
    templates: string;
    description: string;
    own: boolean;
    modified_date: string;
    modified_by_user_uuid: string;
    modified_by_username: string;
    editor_version: string;
    editor_generation: EditorGeneration;
    published: boolean;
    usage_count: number;
    connected_workflows: ConnectedWorkflowsDto;
    use_cases: string[];
    processors: string[];
    core_activities: string[];
    template_type: string;
    saved: boolean;
    update: boolean;
}

export interface SavedTemplatesDto {
    template_uuid: string;
    modified_date: string;
}
export interface UpdateTemplateData {
    modified_date: string;
}
export interface workflowTemplatesResponseDto {
    workflows: WorkflowTemplatesDto[];
    saved_templates: SavedTemplatesDto[];
    count: number;
    cursor: string;
}

interface WorkflowTemplateSaveResponseDto {
    workflow_uuid: string;
}

export interface WorkflowTemplateSaveResponse {
    workflowUuid: string;
}

const PATH_API_WORKFLOW_TEMPLATES = "/api/v1/workflow-templates";

export function toWorkflowTemplate(dto: WorkflowTemplatesDto): TemplateTableData {
    return {
        name: dto.name,
        uuid: dto.uuid,
        profile: dto.profile.toLowerCase() as Profile,
        created: dto.created_date,
        userUuid: dto.user_uuid,
        creator: dto.user_name,
        description: dto.description,
        own: dto.own,
        editorVersion: dto.editor_version,
        editorGeneration: dto.editor_generation,
        published: dto.published,
        usageCount: dto.usage_count,
        modified: dto.modified_date,
        userName: dto.user_name,
        connectedWorkflows: dto.connected_workflows
            ? {
                  parentWorkflows: dto.connected_workflows.parent_workflows,
                  subWorkflows: dto.connected_workflows.sub_workflows,
                  runDiagnoseWorkflows: dto.connected_workflows.run_diagnose_workflows,
                  runProcessFlowWorkflows: dto.connected_workflows.run_process_flow_workflows,
                  collectWorkflows: dto.connected_workflows.collect_workflows,
                  gradingPanelWorkflows: dto.connected_workflows.grading_panel_workflows,
                  remoteWorkflows: dto.connected_workflows.remote_workflow_count,
                  totalConnectedWorkflows:
                      dto.connected_workflows.parent_workflows.length +
                      dto.connected_workflows.sub_workflows.length +
                      dto.connected_workflows.run_diagnose_workflows.length +
                      dto.connected_workflows.run_process_flow_workflows.length +
                      dto.connected_workflows.collect_workflows.length +
                      dto.connected_workflows.grading_panel_workflows.length +
                      dto.connected_workflows.remote_workflow_count,
              }
            : {
                  parentWorkflows: [],
                  subWorkflows: [],
                  runDiagnoseWorkflows: [],
                  runProcessFlowWorkflows: [],
                  collectWorkflows: [],
                  gradingPanelWorkflows: [],
                  remoteWorkflows: 0,
                  totalConnectedWorkflows: 0,
              },
        useCases: dto.use_cases,
        processors: dto.processors,
        coreActivities: dto.core_activities,
        templateType: dto.template_type,
        saved: dto.saved,
        update: dto.update,
    };
}

export function toSaveSuccessResponse(dto: SaveSuccessResponseDto): SaveSuccessResponse {
    return {
        message: dto.message,
        uuid: dto.uuid,
        modified: dto.modified,
        modifiedBy: dto.modified_by,
        missingSubWorkflows: dto.missing_sub_workflows ?? [],
    };
}

export class TemplateService {
    public async fetchWorkflowTemplates(
        search?: string,
        cursor?: string,
        product?: string,
        templateType?: string,
        publishStatus?: boolean,
        version?: string,
        from?: string,
        to?: string,
        subWorkflows?: boolean,
        creator?: string,
        abortController?: AbortController
    ) {
        return apiGatewayService
            .invokeApi(
                PATH_API_TEMPLATES_V1 + "/fetch",
                "POST",
                {
                    search: search,
                    cursor: cursor,
                    product: product,
                    type: templateType,
                    published: publishStatus,
                    version: version,
                    from: from,
                    to: to,
                    sub_workflows: subWorkflows,
                    creator: creator,
                },
                {
                    abortController: abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: workflowTemplatesResponseDto) => {
                const savedTemplatesList = dto.saved_templates
                    ? dto.saved_templates.map((eachSavedTemplate) => eachSavedTemplate.template_uuid)
                    : [];
                const workflowTemplates: TemplateTableData[] = dto.workflows.map(toWorkflowTemplate).map((each) => {
                    each.saved = savedTemplatesList.includes(each.uuid);

                    const templateModifiedDate: SavedTemplatesDto[] = dto.saved_templates?.filter((eachTemplate) => {
                        return eachTemplate.template_uuid == each.uuid ? eachTemplate.modified_date : [];
                    });

                    each.update = Date.parse(templateModifiedDate[0].modified_date) < Date.parse(each.modified) && true;

                    return each;
                });
                return {
                    count: dto.count,
                    cursor: dto.cursor,
                    workflowTemplatesTableData: workflowTemplates,
                };
            });
    }

    public publish(uuid: string, published: boolean, abortController?: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(
            `${PATH_API_TEMPLATES_V1}/${uuid}/publish`,
            "POST",
            { publish: published },
            {
                abortController,
                refreshSession: true,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
                emptyResponse: true,
            }
        );
    }

    public async deleteTemplate(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(`${PATH_API_TEMPLATES_V1}/${uuid}`, "DELETE", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async export(uuid: string, abortController?: AbortController): Promise<void> {
        apiGatewayService.invokeApi(`${PATH_API_TEMPLATES_V1}/${uuid}/export`, "GET", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
            setWindowLocation: true,
        });
    }

    public async create(
        workflow: Workflow,
        subWorkflow?: boolean,
        abortController?: AbortController
    ): Promise<SaveSuccessResponse> {
        return apiGatewayService
            .invokeApi(
                PATH_API_WORKFLOW_TEMPLATES,
                "POST",
                { workflowTemplate: workflow, subWorkflow },
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: SaveSuccessResponseDto) => toSaveSuccessResponse(dto));
    }

    public async update(
        uuid: string,
        workflow: Workflow,
        abortController?: AbortController
    ): Promise<SaveSuccessResponse> {
        return apiGatewayService
            .invokeApi(
                `${PATH_API_WORKFLOW_TEMPLATES}/${uuid}`,
                "PUT",
                { workflow },
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: SaveSuccessResponseDto) => toSaveSuccessResponse(dto));
    }

    public async save(uuid: string, abortController?: AbortController): Promise<WorkflowTemplateSaveResponse> {
        return apiGatewayService
            .invokeApi(
                `${PATH_API_WORKFLOW_TEMPLATES}/${uuid}/save`,
                "POST",
                {},
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((data: WorkflowTemplateSaveResponseDto) => {
                return {
                    workflowUuid: data.workflow_uuid,
                } as WorkflowTemplateSaveResponse;
            });
    }
}

export const templateService = new TemplateService();
